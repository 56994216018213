import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup
    .string("Division Category Name")
    .required("Division Category name is required"),
});

function DivisionCategoryModal(props) {
  const [initialValues] = useState({
    Name: "",
    ordering_mode: "",
  });
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.save(values);
    },
  });

  useEffect(() => {
    if (props.selectedCategory.id) {
      formik.resetForm({
        values: {
          Name: props.selectedCategory.Name,
          ordering_mode:
            props.selectedCategory.ordering_mode == null
              ? ""
              : props.selectedCategory.ordering_mode,
        },
      });
    } else {
      formik.resetForm({
        values: { Name: "", ordering_mode: "" },
      });
    }
  }, [props.open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        {props.selectedCategory.id
          ? "Edit Division Category"
          : "Add New Division Category"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="Name"
              margin="dense"
              label="Name"
              variant="outlined"
              value={formik.values.Name}
              onChange={formik.handleChange}
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={formik.touched.Name && formik.errors.Name}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Select Ordenig Mode
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                This will override the default ordering mode for this division
                category
              </FormLabel>
              <RadioGroup
                aria-label="ordering_mode"
                name="ordering_mode"
                id="ordering_mode"
                value={formik.values.ordering_mode}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="Default"
                  control={<Radio />}
                  label="Invoice"
                />
                <FormControlLabel
                  value="Approval"
                  control={<Radio />}
                  label="Approval"
                />
                <FormControlLabel
                  value="Points"
                  control={<Radio />}
                  label="Payment and Points"
                />
                <FormControlLabel
                  value={""}
                  control={<Radio />}
                  label="Use default ordering mode"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        {props.selectedCategory.id ? (
          <Button
            variant={"contained"}
            color={"secondary"}
            size={"small"}
            style={{ marginRight: "auto" }}
            onClick={() => props.toggleDeleteModal(props.selectedCategory)}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
        <Button
          variant={"contained"}
          color={"default"}
          size={"small"}
          onClick={props.handleClose}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={formik.handleSubmit}
          disabled={props.saving}
        >
          {props.selectedCategory.id ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DivisionCategoryModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  save: PropTypes.func,
  saving: PropTypes.bool,
  selectedCategory: PropTypes.object,
  toggleDeleteModal: PropTypes.func,
};

export default DivisionCategoryModal;
