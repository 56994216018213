import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableCell,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Box,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";

// Styles
const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: "342px",
  },
  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 220,
    textOverflow: "ellipsis",
  },
}));

const TableWithDeleteButton = ({
  title = "Title",
  toolbarIconClick = () => {},
  items = [],
  headers = [],
  noRecordsMessage = "",
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            id="grid_icons_container_aligned"
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toolbarIconClick}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      {items && items.length ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell colSpan={header.colSpan} key={index}>
                    <Box fontWeight="bold">{header.name}</Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, index) => (
                <TableRow key={index}>
                  {Object.keys(row).map((keyName, keyIndex) =>
                    row[keyName] && typeof row[keyName] == "object" ? (
                      <TableCell
                        key={keyIndex}
                        align={row[keyName].align}
                        className={classes.tableCell}
                      >
                        {row[keyName].content}
                      </TableCell>
                    ) : keyName != "id" ? (
                      <TableCell key={keyIndex} className={classes.tableCell}>
                        {row[keyName]}
                      </TableCell>
                    ) : (
                      ""
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Paper>
          <Typography variant="h6" gutterBottom>
            {noRecordsMessage}
          </Typography>
        </Paper>
      )}
    </React.Fragment>
  );
};

TableWithDeleteButton.propTypes = {
  title: PropTypes.string,
  toolbarIconClick: PropTypes.func,
  headers: PropTypes.array,
  items: PropTypes.array,
  noRecordsMessage: PropTypes.string,
};

export default TableWithDeleteButton;
