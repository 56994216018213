// Libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

// API
import {
  createReseller,
  deleteReseller,
  updateReseller,
} from "../api/Resellers";
import AlertDialog from "./AlertDialog";

function CreateResellerDialog({
  reseller = null,
  // Open button props
  openButtonText = <AddIcon />,
  openButtonColor = "inherit",
  // Dialog props
  setError = () => {},
  reloadResellers = () => {},
  // Close button props
  closeButtonVariant = "contained",
  closeButtonColor = "default",
  // Confirm button props
  // confirmButtonClick = () => {},
  confirmButtonVariant = "contained",
  confirmButtonColor = "primary",
}) {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = useState(false);
  // Initialize user_role
  const userRole = localStorage.getItem("role");

  // Open dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    formik.resetForm({
      values: initial_values,
    });
  };

  // Initial form data
  const initial_values = {
    id: reseller ? reseller.id : null,
    name: reseller && reseller.name ? reseller.name : "",
    website: reseller && reseller.website ? reseller.website : "",
    address: reseller && reseller.address ? reseller.address : "",
    contact_name:
      reseller && reseller.contact_name ? reseller.contact_name : "",
    contact_phone:
      reseller && reseller.contact_phone ? reseller.contact_phone : "",
    contact_email:
      reseller && reseller.contact_email ? reseller.contact_email : "",
    username:
      reseller && reseller.AcumaticaSetting && reseller.AcumaticaSetting.Name
        ? reseller.AcumaticaSetting.Name
        : "",
    password:
      reseller &&
      reseller.AcumaticaSetting &&
      reseller.AcumaticaSetting.Password
        ? reseller.AcumaticaSetting.Password
        : "",
    location:
      reseller &&
      reseller.AcumaticaSetting &&
      reseller.AcumaticaSetting.Location
        ? reseller.AcumaticaSetting.Location
        : "",
    api_version:
      reseller &&
      reseller.AcumaticaSetting &&
      reseller.AcumaticaSetting.EndpointVersion
        ? reseller.AcumaticaSetting.EndpointVersion
        : "",
  };

  // Formik validation schema
  const validationSchema = yup.object({
    name:
      userRole !== "reselleradmin"
        ? yup.string("Name").required("Name is required")
        : yup.string("Name"),
    website: yup.string("Website").url("Website must be a valid URL"),
    address: yup.string("Address"),
    contact_name: yup.string("Contact Name"),
    contact_phone: yup
      .string("Contact Phone")
      .min(10, "Phone must be at least 10 digits")
      .max(10, "Phone must be at most 10 digits"),
    contact_email: yup
      .string("Contact Email")
      .email("A valid email is required"),
    username: yup
      .string("Acumatica name")
      .required("Acumatica name is required"),
    password: yup.string("Password").required("Password is required"),
    location: yup.string("Location").required("Location is required"),
    api_version: yup.string("Api version").required("Api version is required"),
  });

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSaving(true);
      // alert(JSON.stringify(values, null, 2));
      (reseller ? updateReseller(values.id, values) : createReseller(values))
        .then((xhr) => {
          if (
            xhr.data &&
            (xhr.data.createReseller || xhr.data.updateReseller)
          ) {
            if (!values.id) {
              // Set new reseller to localStorage
              localStorage.setItem(
                "reseller_id",
                xhr.data.createReseller.reseller.id
              );
            }
            handleClose();
            setError({
              open: true,
              message:
                "Reseller successfully " + (reseller ? "updated" : "created"),
              severity: "success",
            });
            formik.resetForm();
            reloadResellers(reseller ? false : true);
          } else {
            setError({
              open: true,
              message: `Error creating reseller`,
              severity: "error",
            });
          }
          setSaving(false);
        })
        .catch((xhr) => {
          setSaving(false);
          console.log(xhr);
        });
    },
  });

  // Delete single reseller
  const removeReseller = (id) => {
    setSaving(true);
    deleteReseller(id)
      .then((result) => {
        if (result.data && result.data.deleteReseller) {
          // Set first reseller to localStorage
          localStorage.setItem("reseller_id", 1);
          reloadResellers(true);
          handleClose();
          setSaving(false);
          setError({
            open: true,
            message: "Reseller deleted Successfully",
            severity: "success",
          });
        } else {
          setError({
            open: true,
            message: `Error: ${result.errors[0].message}`,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setError({
          open: true,
          message: `Error deleting reseller`,
          severity: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <IconButton
        edge="end"
        aria-label="account"
        aria-haspopup="true"
        color={openButtonColor}
        onClick={handleClickOpen}
      >
        {openButtonText}
      </IconButton>
      <Dialog
        id="points_scheduler_dialog"
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit} id="points_scheduler_form">
          <DialogTitle id="points_scheduler_title">
            {reseller ? "Edit " : "Add "}Reseller
          </DialogTitle>
          <DialogContent id="points_scheduler_content">
            <Grid container spacing={2} id="points_scheduler_container">
              {/* Name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  autoFocus
                  id="name"
                  margin="none"
                  label="Name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Website */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  autoFocus
                  id="website"
                  margin="none"
                  label="Website"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.website}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  autoFocus
                  id="address"
                  margin="none"
                  label="Address"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Contact title */}
              <Grid item xs={12}>
                <Typography variant="h6">Contact</Typography>
              </Grid>
              {/* Contact name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  autoFocus
                  id="contact_name"
                  margin="none"
                  label="Name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.contact_name}
                  error={
                    formik.touched.contact_name &&
                    Boolean(formik.errors.contact_name)
                  }
                  helperText={
                    formik.touched.contact_name && formik.errors.contact_name
                  }
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Contact phone */}
              <Grid item xs={6}>
                <TextField
                  type="tel"
                  fullWidth
                  size="small"
                  autoFocus
                  id="contact_phone"
                  margin="none"
                  label="Phone Number"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.contact_phone}
                  error={
                    formik.touched.contact_phone &&
                    Boolean(formik.errors.contact_phone)
                  }
                  helperText={
                    formik.touched.contact_phone && formik.errors.contact_phone
                  }
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Contact email */}
              <Grid item xs={12}>
                <TextField
                  type="email"
                  fullWidth
                  size="small"
                  autoFocus
                  id="contact_email"
                  margin="none"
                  label="Email"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.contact_email}
                  error={
                    formik.touched.contact_email &&
                    Boolean(formik.errors.contact_email)
                  }
                  helperText={
                    formik.touched.contact_email && formik.errors.contact_email
                  }
                  disabled={userRole == "reselleradmin"}
                />
              </Grid>
              {/* Contact title */}
              <Grid item xs={12}>
                <Typography variant="h6">Acumatica</Typography>
              </Grid>
              {/* username */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="username"
                  size="small"
                  margin="dense"
                  label="Name (username)"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  fullWidth
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              {/* password */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="password"
                  type="password"
                  size="small"
                  margin="dense"
                  label="Password"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  fullWidth
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              {/* Location */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="location"
                  size="small"
                  margin="dense"
                  label="Location (url)"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  fullWidth
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                />
              </Grid>
              {/* API Version */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="api_version"
                  size="small"
                  margin="dense"
                  label="API Version"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.api_version}
                  fullWidth
                  error={
                    formik.touched.api_version &&
                    Boolean(formik.errors.api_version)
                  }
                  helperText={
                    formik.touched.api_version && formik.errors.api_version
                  }
                />
              </Grid>
              {/* Buttons */}
              <Grid item xs={3}>
                {/* Delete button */}
                {reseller && userRole !== "reselleradmin" ? (
                  <AlertDialog
                    dialogTitle="Delete Reseller"
                    dialogContentText="Reseller will be removed from the list. Do you want to continue?"
                    confirmButtonClick={() => removeReseller(reseller.id)}
                  />
                ) : null}
              </Grid>
              <Grid item xs={9}>
                {/* Aligned to the right */}
                <Grid container justify="flex-end">
                  {/* Cancel button */}
                  <Button
                    onClick={handleClose}
                    variant={closeButtonVariant}
                    color={closeButtonColor}
                  >
                    CANCEL
                  </Button>
                  {/* Save button */}
                  <Button
                    type="submit"
                    variant={confirmButtonVariant}
                    color={confirmButtonColor}
                    style={{ marginLeft: 10 }}
                    autoFocus
                    disabled={saving}
                  >
                    {reseller ? "SAVE" : "ADD"}
                  </Button>
                </Grid>
              </Grid>
              {/* .......... */}
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

CreateResellerDialog.propTypes = {
  reseller: PropTypes.object,
  openButtonText: PropTypes.string,
  openButtonColor: PropTypes.string,
  setError: PropTypes.func,
  reloadResellers: PropTypes.func,
  closeButtonVariant: PropTypes.string,
  closeButtonColor: PropTypes.string,
  confirmButtonClick: PropTypes.func,
  confirmButtonVariant: PropTypes.string,
  confirmButtonColor: PropTypes.string,
};

export default CreateResellerDialog;
