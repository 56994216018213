import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Divider,
  Hidden,
  makeStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

//Components
import SnackbarAlert from "../SnackbarAlert";
import { ModifyUsersByIds } from "../../api/Users";
import SnackbarMessage from "../SnackbarMessage";
//Styles
const useStyles = makeStyles((theme) => ({
  label: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
    },
  },
  closeButton: {
    [theme.breakpoints.up("md")]: {
      marginRight: "52px",
    },
    [theme.breakpoints.down(1025)]: {
      marginRight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "23px",
    },
  },
}));
export default function ModifyDivisionsUsersDialog(props) {
  const classes = useStyles();
  const { open, handleClose, usersToModify = [], divisionCategories, loading } =
    props;
  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    message: "",
    data: {},
  });
  const [error, setError] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  //close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  //Close snackbarAlert
  const handleCloseSnackbarAlert = () => {
    setSnackbarAlert({
      open: false,
      message: "",
      data: {},
    });
  };

  //Actions assing/unassing divisions
  const handleAction = (value) => {
    //Modify users with respect to what was requested
    if (value.users && value.action) {
      ModifyUsersByIds(value)
        .then((result) => {
          if (result.length > 0) {
            handleCloseSnackbarAlert();
            let count =
              value.users.length == 1
                ? "an user"
                : value.users.length + " users";
            let message = `Division Category ${
              value.action == "Assing" ? "assigned" : "unassigned"
            } to ${count}`;
            setError({
              open: true,
              message: message,
              severity: "success",
            });
          } else {
            setError({
              open: true,
              message: `An error occurred, please try again`,
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {usersToModify.length >= 0 && usersToModify.length <= 1
          ? "Change Division Category to an user"
          : `Change Division Categorie To ${usersToModify.length} users`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {usersToModify.length == 0 ? (
            "There are no users selected to modify, select some to continue."
          ) : loading ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton height={41.6} width="50%" />
              </Grid>
              <Grid item xs={8}>
                <Skeleton height={40} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton height={36} width="50%" />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {[1, 2, 3, 4].map((item, index) => {
                    return (
                      <Grid key={index} container style={{ padding: "15px" }}>
                        <Grid xs={11}>
                          <Skeleton height={24} />
                        </Grid>
                        <Grid xs={1}>
                          <Skeleton height={24} />
                        </Grid>
                        <Grid xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                container
                style={{
                  maxHeight: "295px",
                  //overflowX: "scroll",
                  overflowY: "scroll",
                  marginTop: "20px",
                }}
              >
                <Grid xs={4} ms={5}>
                  <Typography>Division Category</Typography>
                </Grid>
                <Grid xs={4} sm={3}>
                  <Typography>Ordering Mode</Typography>
                </Grid>
                <Grid xs={4} sm={4} />
                <Grid xs={12} style={{ marginTop: "5px" }}>
                  <Divider />
                </Grid>
                {divisionCategories.map((divison) => {
                  return (
                    <Grid
                      key={divison.id}
                      container
                      style={{ padding: "15px" }}
                    >
                      <Grid xs={4} sm={4}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          noWrap
                          className={classes.label}
                        >
                          {divison.Name}
                        </Typography>
                      </Grid>
                      <Grid xs={4} sm={4}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          noWrap
                          className={classes.label}
                        >
                          {divison.ordering_mode == "Default"
                            ? "Invoice"
                            : divison.ordering_mode
                            ? divison.ordering_mode
                            : "Client Default"}
                        </Typography>
                      </Grid>
                      <Grid xs={4} sm={4}>
                        <Fragment>
                          {/**Unassign Button */}
                          <Hidden smUp>
                            <Button
                              color="primary"
                              size="small"
                              fullWidth
                              onClick={() => {
                                //Open snackbar
                                let count =
                                  usersToModify.length == 1
                                    ? "an user"
                                    : usersToModify.length + " users?";
                                setSnackbarAlert({
                                  open: true,
                                  message: `Unassign Division Category to ${count}`,
                                  data: {
                                    divison_id: divison.id,
                                    users: usersToModify,
                                    action: "Unassing",
                                  },
                                });
                              }}
                              style={{ marginRight: "10px" }}
                            >
                              Unassign
                            </Button>
                          </Hidden>
                          <Hidden xsDown>
                            <Button
                              color="primary"
                              size="small"
                              onClick={() => {
                                //Open snackbar
                                let count =
                                  usersToModify.length == 1
                                    ? "an user"
                                    : usersToModify.length + " users?";
                                setSnackbarAlert({
                                  open: true,
                                  message: `Unassign Division Category to ${count}`,
                                  data: {
                                    divison_id: divison.id,
                                    users: usersToModify,
                                    action: "Unassing",
                                  },
                                });
                              }}
                              style={{ marginRight: "10px" }}
                            >
                              Unassign
                            </Button>
                          </Hidden>
                          {/**Assign Button */}
                          <Hidden smUp>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              fullWidth
                              onClick={() => {
                                let count =
                                  usersToModify.length == 1
                                    ? "an user"
                                    : usersToModify.length + " users?";
                                setSnackbarAlert({
                                  open: true,
                                  message: `Assign Division Category to ${count}`,
                                  data: {
                                    divison_id: divison.id,
                                    users: usersToModify,
                                    action: "Assing",
                                  },
                                });
                              }}
                            >
                              ASSIGN
                            </Button>
                          </Hidden>
                          <Hidden xsDown>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              onClick={() => {
                                let count =
                                  usersToModify.length == 1
                                    ? "an user"
                                    : usersToModify.length + " users?";
                                setSnackbarAlert({
                                  open: true,
                                  message: `Assign Division Category to ${count}`,
                                  data: {
                                    divison_id: divison.id,
                                    users: usersToModify,
                                    action: "Assing",
                                  },
                                });
                              }}
                            >
                              ASSIGN
                            </Button>
                          </Hidden>
                        </Fragment>
                      </Grid>
                      <Grid xs={12} style={{ marginTop: "5px" }}>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingBlockEnd: "20px" }}>
        <Hidden smUp>
          {usersToModify.length == 0 ? (
            <Button
              size="small"
              onClick={handleClose}
              variant="contained"
              color="primary"
              style={{ marginRight: "20px" }}
            >
              To accept
            </Button>
          ) : (
            <Button
              className={classes.closeButton}
              size="small"
              onClick={handleClose}
              variant="contained"
              color="default"
            >
              Close
            </Button>
          )}
        </Hidden>
        <Hidden xsDown>
          {usersToModify.length == 0 ? (
            <Button
              size="small"
              onClick={handleClose}
              variant="contained"
              color="primary"
              style={{ marginRight: "20px" }}
            >
              To accept
            </Button>
          ) : (
            <Button
              className={classes.closeButton}
              size="small"
              onClick={handleClose}
              variant="contained"
              color="default"
            >
              Close
            </Button>
          )}
        </Hidden>
      </DialogActions>
      <SnackbarAlert
        message={snackbarAlert.message}
        open={snackbarAlert.open}
        handleClose={handleCloseSnackbarAlert}
        data={snackbarAlert.data}
        actionFunction={handleAction}
      />
      <SnackbarMessage
        message={error.message}
        severity={error.severity}
        open={error.open}
        handleClose={handleCloseSnackbar}
      />
    </Dialog>
  );
}

ModifyDivisionsUsersDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  usersToModify: PropTypes.array,
  divisionCategories: PropTypes.array,
  loading: PropTypes.bool,
};
