import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

// API
import { createProductDivisionCategory } from "../../api/ProductDivisionCategories";

// Styles
const useStyles = makeStyles(() => ({
  ListItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
}));

// Main Component
function AddDivisionCategoryDialog({
  // Dialog props
  open,
  handleClose,
  division_categories,
  product_id,
  setError,
  refreshData,
  client_id,
  // Close button props
  closeButtonText = "Cancel",
  closeButtonVariant = "contained",
  closeButtonColor = "default",
  // Confirm button props
  confirmButtonText = "Save",
  // confirmButtonClick = () => {},
  confirmButtonVariant = "contained",
  confirmButtonColor = "primary",
}) {
  const classes = useStyles();
  const [saving, setSaving] = React.useState(false);

  // Initial form data
  const initial_values = {
    division_category: "",
    display_order: "",
  };

  // Formik validation schema
  const validationSchema = yup.object({
    division_category: yup
      .string("Division Category")
      .required("Division category is required"),
    display_order: yup
      .string("Display Order")
      .required("Display order is required"),
  });

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSaving(true);
      values.product = product_id;
      values.client = client_id;
      // alert(JSON.stringify(values, null, 2));
      createProductDivisionCategory(values)
        .then((xhr) => {
          if (xhr.data && xhr.data.createProductDivisionCategory) {
            setSaving(false);
            handleClose();
            setError({
              open: true,
              message: "Division category successfully assigned",
              severity: "success",
            });
            formik.resetForm();
            refreshData();
          } else {
            setSaving(false);
            setError({
              open: true,
              message: `Error relating division category`,
              severity: "error",
            });
          }
        })
        .catch((xhr) => {
          console.log(xhr);
        });
    },
  });

  return (
      <Dialog
        id="add_division_category_dialog"
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit} id="points_scheduler_form">
          <DialogTitle id="points_scheduler_title">Assign Division Category</DialogTitle>
          <DialogContent id="points_scheduler_content">
            <Grid
              container
              spacing={2}
              id="points_scheduler_container"
            >
              {/* Division category */}
              <Grid item xs={12}>
                <FormControl
                  id="division_category_form_control"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  error={
                    formik.touched.division_category &&
                    Boolean(formik.errors.division_category)
                  }
                >
                  <InputLabel id="division_category_label">
                    Division Category
                  </InputLabel>
                  <Select
                    labelId="division_category_label"
                    label="Division Category"
                    id="division_category"
                    name="division_category"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    }}
                    value={formik.values.division_category}
                    onChange={formik.handleChange}
                  >
                    {division_categories.length == 0 ? (
                      <MenuItem disabled value={0}>
                        No options
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {division_categories.map((division_category) => (
                      <MenuItem
                        key={division_category.id}
                        value={division_category.id}
                        className={classes.ListItem}
                      >
                        {division_category.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.division_category &&
                      formik.errors.division_category}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* Number of points */}
              <Grid item xs={12}>
                <TextField
                  id="display_order"
                  type="number"
                  inputProps={{ min: 1 }}
                  size="small"
                  margin="dense"
                  label="Display Order"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.display_order}
                  fullWidth
                  error={
                    formik.touched.display_order &&
                    Boolean(formik.errors.display_order)
                  }
                  helperText={
                    formik.touched.display_order &&
                    formik.errors.display_order
                  }
                />
              </Grid>
              {/* Buttons */}
              <Grid item xs={12}>
                {/* Aligned to the right */}
                <Grid
                  container
                  justify="flex-end"
                >
                  {/* Cancel button */}
                  <Button
                    onClick={() => {
                      handleClose();
                      formik.resetForm({
                        values: initial_values,
                      });
                    }}
                    variant={closeButtonVariant}
                    color={closeButtonColor}
                  >
                    {closeButtonText}
                  </Button>
                  {/* Save button */}
                  <Button
                  type="submit"
                  variant={confirmButtonVariant}
                  color={confirmButtonColor}
                  style={{ marginLeft: 10 }}
                  autoFocus
                  disabled={saving}
                  >
                    {confirmButtonText}
                  </Button>
                </Grid>
              </Grid>
              {/* .......... */}
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </form>
      </Dialog>
    
  );
}

AddDivisionCategoryDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  dialogTitle: PropTypes.string,
  division_categories: PropTypes.array,
  product_id: PropTypes.number,
  setError: PropTypes.func,
  refreshData: PropTypes.func,
  client_id: PropTypes.number,
  dialogContentText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  closeButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonClick: PropTypes.func,
  confirmButtonVariant: PropTypes.string,
  confirmButtonColor: PropTypes.string,
};

export default AddDivisionCategoryDialog;
