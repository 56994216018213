import React from "react";
import {
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  media: {
    height: "100%",
    maxHeight: "300px",
    maxWidth: "300px",
    alignSelf: "center",
    objectFit: "contain",
  },
  productTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
});
const ProductCard = (props) => {
  let history = useHistory();
  const classes = useStyles();
  let { client_id } = useParams();
  return (
    <Card
      style={{
        cursor: "pointer",
        height: "511px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={
        props.clickAction
          ? props.clickAction
          : client_id
          ? () =>
              history.push(
                `/app/client/${client_id}/products/assign/${props.product.id}`
              )
          : !props.preventNavigation
          ? () => history.push(`/app/product/${props.product.id}`)
          : null
      }
    >
      <CardMedia
        component="img"
        className={classes.media}
        image={
          props.product.Image.length
            ? props.product.Image[0].url
            : "/img/avid/avid-io_250x266.png"
        }
      />
      <CardContent>
        <Tooltip title={props.product.Name}>
          <Typography variant="h5" className={classes.productTitle}>
            {props.product.Name}
          </Typography>
        </Tooltip>
        <Typography color="textSecondary" gutterBottom>
          SKU: {props.product.Sku}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          PRICE: {props.product.Price ? "$" + props.product.Price : ""}
        </Typography>
      </CardContent>
      <CardActions style={{ marginTop: "auto" }}>
        {props.product.Published ? (
          <Chip
            color="primary"
            label="Published"
            style={{ marginLeft: "auto" }}
          />
        ) : (
          <Chip
            color="secondary"
            label="Not Published"
            style={{ marginLeft: "auto" }}
          />
        )}
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  preventNavigation: PropTypes.bool,
  clickAction: PropTypes.func,
};

export default ProductCard;
