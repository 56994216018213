// Import Libraries
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";

// Import API Services
import { deleteProduct } from "../../api/Products";

export default function DeleteProductModal(props) {
  const [loading, setLoading] = useState(false);
  /**
   * Purpose: Removes product and its relations
   */
  const deleteCurrentProduct = () => {
    setLoading(true);
    deleteProduct(props.productId)
      .then((result) => {
        if (result?.data?.deletedProduct?.status) {
          props.toggleDeleteModal({ sucess: "deleted_product" });
        } else {
          const message = result?.errors[0]?.message || "";
          props.error({
            open: true,
            message: `There was an error, please try again later ${message}`,
            severity: "error",
          });
        }
      })
      .catch((xhr) => {
        console.log(xhr);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={props.deleteModalOpen}
      onClose={props.toggleDeleteModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.toggleDeleteModal}
          variant="contained"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={deleteCurrentProduct}
          color="secondary"
          autoFocus
          variant="contained"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteProductModal.propTypes = {
  deleteModalOpen: PropTypes.bool,
  toggleDeleteModal: PropTypes.func,
  productId: PropTypes.number,
  error: PropTypes.func,
};
