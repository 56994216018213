import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Typography,
  Slide,
  Hidden,
  Popover,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Papa from "papaparse";
import ConfirmationImportUsers from "./ConfirmationImportUsers";
import { ImportUsers } from "../../api/Users";
import { useParams } from "react-router-dom";
import {
  getClientOverviewInfo,
  getDivisionCategories,
} from "../../api/Clients";

const useStyles = makeStyles((theme) => ({
  buttonCVSDownload: {
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
  },
  buttonUpload: {
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-30px",
    },
  },
  DivCat: {
    [theme.breakpoints.up("md")]: {
      marginTop: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  Roles: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-20px",
    },
  },
  Groups: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "-65px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-20px",
    },
  },
  buttonCancel: {
    [theme.breakpoints.up("md")]: {
      marginTop: "-55px",
      float: "right",
      marginRight: "-616px",
    },
    [theme.breakpoints.down("md")]: {
      float: "right",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImportUsersModal({ open, handleClose, setError }) {
  const classes = useStyles();
  //check if url contains client_id
  let { client_id } = useParams();
  const [csvUserInfo, setcsvUserData] = useState("");
  const [CIportUsers, setCImportUsers] = useState(false);
  const [importing_users, setImportingUsers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElRole, setAnchorElRole] = useState(null);
  const [division_categories, setDivisionCategories] = useState([]);
  const [anchorElGroupId, setAnchorElGroupId] = useState(null);
  const [group_ids, setGroupsIds] = useState([]);
  const [csv, setCsv] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (client_id && client_id != 0) {
      //Get division categories
      getDivisionCategories({ client_id: client_id }).then((res) => {
        setDivisionCategories(res);
      });
      //get Group_id's client
      getClientOverviewInfo(client_id).then((result) => {
        if (result && result.approval_groups) {
          setGroupsIds(result.approval_groups);
        }
      });
    }
  }, []);

  //Download csv file
  const GetCSV = () => {
    const downloadInstance = document.createElement("a");
    downloadInstance.href = `${process.env.REACT_APP_WEB}/assets/csv/avid-io-user-import.csv`;
    downloadInstance.target = "_blank";
    downloadInstance.download = "avid-io-user-import.csv";

    document.body.appendChild(downloadInstance);
    downloadInstance.click();
    document.body.removeChild(downloadInstance);
  };

  //Get users from csv file
  const csvParse = (csv) => {
    Papa.parse(csv, {
      complete: (result) => {
        const userInfo = result.data;
        const properties = [
          "First_Name",
          "Last_Name",
          "Password",
          "Employee_ID",
          "Email",
          "Billing_Department",
          "Site",
          "Division_Categories",
          "Role",
          "Group_id",
        ];
        let isValid = true;
        const userInfoKeys = Object.keys(userInfo[0]);
        for (const property of properties) {
          if (!userInfoKeys.includes(property)) {
            isValid = false;
          }
        }
        if (isValid) {
          setcsvUserData(userInfo);
          ShowCIportUsers(true);
        } else {
          setError({
            open: true,
            message: `Error: An error occurred, please check the csv params and try again`,
            severity: "error",
          });
        }
      },
      header: true,
    });
  };

  const ShowCIportUsers = (value) => {
    setCImportUsers(value);
  };

  const HideModal = () => {
    setCImportUsers(false);
    handleClose();
    setSuccess(false);
    setImportingUsers(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickRole = (event) => {
    setAnchorElRole(event.currentTarget);
  };

  const handleClosee = () => {
    setAnchorEl(null);
  };

  const handleCloseeRole = () => {
    setAnchorElRole(null);
  };

  const handleClickGroupID = (event) => {
    setAnchorElGroupId(event.currentTarget);
  };

  const handleCloseGroupID = () => {
    setAnchorElGroupId(null);
  };

  const openGroupID = Boolean(anchorElGroupId);
  const idGroup = openGroupID ? "simple-popover" : undefined;

  const openn = Boolean(anchorEl);
  const id = openn ? "simple-popover" : undefined;

  const opennRole = Boolean(anchorElRole);
  const idRole = opennRole ? "simple-popover" : undefined;

  /**
   * POST import users
   */
  const importUsers = () => {
    if (csv) {
      const values = {
        files: csv,
        clientId: client_id,
      };
      setImportingUsers(true);
      ImportUsers(values).then((results) => {
        if (results && results.data) {
          setSuccess(true);
        } else if (results.response.data) {
          const message =
            results.response.data && results.response.data.message
              ? results.response.data.message
              : "An error occurred, please check the csv and try again";
          setError({
            open: true,
            message: `Error: ${message}`,
            severity: "error",
          });
          setImportingUsers(false);
        }
      });
    }
  };

  return (
    <Dialog
      id="userimport_dialog"
      open={open}
      onClose={importing_users ? () => {} : handleClose}
      TransitionComponent={Transition}
      aria-labelledby="user_dialog_title"
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5">Import Users</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item md={3} />
            <Grid item md={5}>
              <Button
                startIcon={<ArrowDownwardIcon />}
                className={classes.buttonCVSDownload}
                variant="contained"
                color="primary"
                onClick={GetCSV}
              >
                DOWNLOAD TEMPLATE
              </Button>
            </Grid>
          </Hidden>
          {/** */}
          <Hidden mdUp>
            <Grid item xs={12}>
              <Button
                startIcon={<ArrowDownwardIcon />}
                fullWidth
                variant="contained"
                color="primary"
                onClick={GetCSV}
              >
                DOWNLOAD TEMPLATE
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </DialogTitle>
      <form>
        <DialogContent id="user_import_content" style={{ overflowY: "hidden" }}>
          <Hidden smDown>
            <center>
              <Button
                startIcon={<PublishIcon />}
                className={classes.buttonUpload}
                variant="contained"
                color="primary"
                component="label"
              >
                <input
                  type="file"
                  name="csv"
                  accept=".csv"
                  hidden
                  onChange={(event) => {
                    setCsv(event.target.files[0]);
                    csvParse(event.target.files[0]);
                  }}
                />
                UPLOAD FILE
              </Button>
            </center>
          </Hidden>
          <Hidden mdUp>
            <center>
              <Button
                fullWidth
                startIcon={<PublishIcon />}
                className={classes.buttonUpload}
                variant="contained"
                color="primary"
                component="label"
              >
                <input
                  type="file"
                  name="csv"
                  accept=".csv"
                  hidden
                  onChange={(event) => {
                    setCsv(event.target.files[0]);
                    csvParse(event.target.files[0]);
                  }}
                />
                UPLOAD FILE
              </Button>
            </center>
          </Hidden>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className={classes.DivCat}>
              <Button
                aria-describedby={id}
                onClick={handleClick}
                color={"primary"}
              >
                AVAILABLE DIVISION CATEGORIES
              </Button>
              <Popover
                id={id}
                open={openn}
                anchorEl={anchorEl}
                onClose={handleClosee}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Container spacing={1}>
                  <b>
                    <br />
                    {division_categories.length > 0
                      ? division_categories.map((divcat) => {
                          return (
                            <Typography key={divcat.id}>
                              {divcat.Name.length > 20
                                ? divcat.Name.slice(0, 20) +
                                  "... (ID: " +
                                  divcat.id +
                                  ") \n\n"
                                : divcat.Name + " (ID: " + divcat.id + ") \n\n"}
                            </Typography>
                          );
                        })
                      : ""}
                  </b>
                  <br />
                  <br />
                  <Typography sx={{ p: 2 }}>
                    <b>
                      {"* Use IDs Inside the CSV for division"}
                      <br />
                      {"categories. If you ware entering "}
                      <br />
                      {"multiple division"}
                      <br />
                      {"categories for a user enter it in the"}
                      <br />
                      {"form 123|123 separated by pipes."}
                    </b>
                  </Typography>
                </Container>
              </Popover>
            </Grid>
            <Grid item xs={12} md={3} className={classes.DivCat}>
              <Button
                className={classes.Roles}
                aria-describedby={idRole}
                onClick={handleClickRole}
                color={"primary"}
              >
                AVAILABLE ROLES
              </Button>
              <Popover
                id={id}
                open={opennRole}
                anchorEl={anchorElRole}
                onClose={handleCloseeRole}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Container spacing={1}>
                  <Typography sx={{ p: 2 }}>
                    <b>
                      {"ClientAdmin"}
                      <br />
                      {"ClientUserManager"}
                      <br />
                      {"ClientProductManager"}
                      <br />
                      {"ClientOrderApprover"}
                      <br />
                      {"Customer"}
                    </b>
                  </Typography>
                </Container>
              </Popover>
            </Grid>
            <Grid item xs={12} md={4} className={classes.DivCat}>
              <Button
                className={classes.Groups}
                aria-describedby={idGroup}
                onClick={handleClickGroupID}
                color={"primary"}
              >
                AVAILABLE APPROVAL GROUPS
              </Button>
              <Popover
                id={idGroup}
                open={openGroupID}
                anchorEl={anchorElGroupId}
                onClose={handleCloseGroupID}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Container spacing={1}>
                  <b>
                    <br />
                    {group_ids.length > 0
                      ? group_ids.map((group) => {
                          return (
                            <Typography key={group.id}>
                              {group.Name.length > 20
                                ? group.Name.slice(0, 20) +
                                  "... (ID: " +
                                  group.id +
                                  ") \n\n"
                                : group.Name + " (ID: " + group.id + ") \n\n"}
                            </Typography>
                          );
                        })
                      : ""}
                  </b>
                  <br />
                  <br />
                  <Typography sx={{ p: 2 }}>
                    <b>
                      {"* Use IDs Inside the CSV for Groups"}
                      <br />
                      {"approval. If you ware entering "}
                      <br />
                      {"multiple groups"}
                      <br />
                      {"approval for a user enter it in the"}
                      <br />
                      {"form 123|123 separated by pipes."}
                    </b>
                  </Typography>
                </Container>
              </Popover>
            </Grid>
            <Hidden smDown>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  fullWidth
                  className={classes.buttonCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={9} />
              <Grid item xs={3}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  fullWidth
                  className={classes.buttonCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </DialogContent>
      </form>
      {/**Show CImportUsers */}
      <ConfirmationImportUsers
        open={CIportUsers}
        handleClose={HideModal}
        CIUsershandle={importUsers}
        totalusers={csvUserInfo.length}
        importing_users={importing_users}
        success={success}
      />
    </Dialog>
  );
}

ImportUsersModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  getUsers: PropTypes.func,
  setError: PropTypes.func,
};
export default ImportUsersModal;
