import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Hidden } from "@material-ui/core";

/**
 * Multiple tabs that change the route on click
 * @param {object} props tabs: object with tab name as key and route as value, value: current value of tab
 * @returns <Paper><Tabs></Tabs></Paper>
 */
function NavigationTabs({ tabs, value, client_id }) {
  const history = useHistory();
  // Initialize user_role
  let user_role = localStorage.getItem("role");
  if (client_id) {
    tabs = {
      overview: `/app/client/${client_id}`,
      products: `/app/client/${client_id}/products`,
      users: `/app/client/${client_id}/users`,
      store: `/app/client/${client_id}/store/products`,
      approval: `/app/client/${client_id}/approval`,
    };
  }

  // Execute this on every render of this component
  useEffect(() => {
    // Refresh user_role
    user_role = localStorage.getItem("role");
  });

  const handleChange = (event, newValue) => {
    history.push(tabs[newValue]);
  };

  const admins = {
    overview: true,
    products: true,
    users: true,
    store: true,
    approval: true,
  };

  const permissions = {
    superadmin: admins,
    reselleradmin: admins,
    clientadmin: admins,
    clientproductmanager: {
      overview: false,
      products: true,
      users: true,
      store: true,
      approval: true,
    },
    clientusermanager: {
      overview: false,
      products: false,
      users: true,
      store: true,
      approval: true,
    },
    clientorderapprover: {
      overview: false,
      products: false,
      users: false,
      store: true,
      approval: true,
    },
  };

  return (
    <Paper square>
      <Hidden smUp>
        <div style={{ margin: "15px" }}></div>
      </Hidden>
      <Hidden lgUp>
        <Tabs
          value={value.toLowerCase()}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant={"scrollable"}
          scrollButtons="on"
        >
          {Object.keys(tabs).map((keyName, keyIndex) => {
            let lowerKeyName = keyName.toLowerCase();
            if (
              Object.prototype.hasOwnProperty.call(permissions, user_role) &&
              permissions[user_role][lowerKeyName]
            ) {
              return <Tab label={keyName} key={keyIndex} value={keyName} />;
            }
          })}
        </Tabs>
      </Hidden>
      <Hidden mdDown>
        <Tabs
          value={value.toLowerCase()}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant="fullWidth"
        >
          {Object.keys(tabs).map((keyName, keyIndex) => {
            let lowerKeyName = keyName.toLowerCase();
            if (
              Object.prototype.hasOwnProperty.call(permissions, user_role) &&
              permissions[user_role][lowerKeyName]
            ) {
              return <Tab label={keyName} key={keyIndex} value={keyName} />;
            }
          })}
        </Tabs>
      </Hidden>
    </Paper>
  );
}

NavigationTabs.propTypes = {
  tabs: PropTypes.object,
  value: PropTypes.string,
  client_id: PropTypes.number,
};

export default NavigationTabs;
