import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { createProductAttribute } from "../../api/Products";
import { createProductProductAttribute } from "../../api/ProductProductAttributes";
//import PropTypes from "prop-types";

const headCells = [
  {
    id: "Name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "display_order",
    disablePadding: false,
    label: "Display Order",
  },
  {
    id: "ControlType",
    disablePadding: false,
    label: "Control Type",
  },
  {
    id: "Required",
    disablePadding: false,
    label: "Required",
  },
];
/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup.string("Name").required("Name is required"),
  DisplayText: yup.string("Display Text").required("Display Text is required"),
  ControlType: yup.string("Control Type").required("Control type is required"),
});

export default function ProductAttributeModal(props) {
  const [initialValues, setInitialValues] = useState({
    Name: "",
    DisplayText: "",
    ControlType: "",
    display_order: "",
    required: false,
    templateId: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formik.resetForm();
    setInitialValues({
      Name: "",
      DisplayText: "",
      ControlType: "",
      display_order: "",
      required: false,
      templateId: "",
    });
  }, [props.modalOpen]);
  /***Formik initialization */
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      createProductAttribute(values).then((res) => {
        if (res.data.createProductAttribute) {
          let productAttr = {
            product: props.product_id,
            client: props.client_id,
            product_attribute:
              res.data.createProductAttribute.productAttribute.id,
            display_order: values.display_order,
            required: values.required,
            templateId: values.templateId,
          };
          createProductProductAttribute(productAttr)
            .then((xhr) => {
              if (xhr.data && xhr.data.createProductProductAttribute) {
                setLoading(false);
                props.toggleModal(true);
                props.successAddAttribute();
              } else {
                setLoading(false);
                props.successAddAttribute({
                  open: true,
                  message: `Error relating product attribute`,
                  severity: "error",
                });
              }
            })
            .catch((xhr) => {
              setLoading(false);
              console.log(xhr);
            });
        }
      });
    },
  });

  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      {!props.selectedTemplate && (
        <DialogTitle id="alert-dialog-title">
          {props.product_id
            ? "Add Product Attribute"
            : "Product Attribute Maintenance"}
        </DialogTitle>
      )}
      <DialogContent>
        {!props.selectedTemplate && (
          <Grid container spacing={2}>
            {/* Attribute name */}
            <Grid item xs={12}>
              <TextField
                size="small"
                id="Name"
                margin="dense"
                label="Name"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.Name}
                fullWidth
                error={formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={formik.touched.Name && formik.errors.Name}
              />
            </Grid>
            {/* Display text */}
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                id="DisplayText"
                name="DisplayText"
                margin="dense"
                label="Display Text"
                variant="outlined"
                value={formik.values.DisplayText}
                onChange={formik.handleChange}
                error={
                  formik.touched.DisplayText &&
                  Boolean(formik.errors.DisplayText)
                }
                helperText={
                  formik.touched.DisplayText && formik.errors.DisplayText
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                id="display_order"
                name="display_order"
                margin="dense"
                label="Display Order"
                variant="outlined"
                value={formik.values.display_order}
                onChange={formik.handleChange}
                error={
                  formik.touched.display_order &&
                  Boolean(formik.errors.display_order)
                }
                helperText={
                  formik.touched.display_order && formik.errors.display_order
                }
              />
            </Grid>
            {/*Control type select */}
            <Grid item xs={12}>
              <FormControl id="ControlType" fullWidth>
                <InputLabel id="ControlType">Control Type</InputLabel>
                <Select
                  labelId="ControlType"
                  id="ControlType"
                  name="ControlType"
                  value={formik.values.ControlType}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"dropdown"}>Drop Down</MenuItem>
                  <MenuItem value={"number"}>Number</MenuItem>
                  <MenuItem value={"radio"}>Radio</MenuItem>
                  <MenuItem value={"text"}>Text</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.required}
                      onChange={formik.handleChange}
                      name="required"
                      color="primary"
                    />
                  }
                  label="Required"
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
        {/* Select Template */}
        {!location.pathname.includes("assign") && props.templates && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography style={{ fontSize: "20px" }} gutterBottom>
                Or choose a template
              </Typography>
              <TextField
                fullWidth
                id="templateId"
                select
                margin="dense"
                label="Select Template"
                variant="outlined"
                value={formik.values.templateId}
                onChange={(event) => {
                  formik.setFieldValue("templateId", event.target.value);
                  props.changeSelectedTemplate(event.target.value);
                }}
                helperText={formik.errors.templateId}
              >
                <MenuItem value={null}>None</MenuItem>
                {props.templates.map((template) => (
                  <MenuItem key={template.id} value={template}>
                    {template.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* Template table with values */}
            {props.selectedTemplate && (
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell key={headCell.id} align={"left"}>
                            <b>{headCell.label} </b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.selectedTemplate &&
                        props.selectedTemplate.attributes.map((row) => (
                          <TableRow key={row}>
                            <TableCell>{row.Name}</TableCell>
                            <TableCell>{row.display_order}</TableCell>
                            <TableCell>{row.ControlType}</TableCell>
                            <TableCell>
                              {row.required == true ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ padding: " 8px 24px" }}>
        <Button onClick={props.toggleModal} variant="contained">
          Close
        </Button>
        <Button
          onClick={
            props.selectedTemplate
              ? props.saveTemplateAttributes
              : formik.handleSubmit
          }
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ProductAttributeModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  successAddAttribute: PropTypes.func,
  product_id: PropTypes.number,
  client_id: PropTypes.number,
  templates: PropTypes.array,
  selectedTemplate: PropTypes.number,
  changeSelectedTemplate: PropTypes.func,
  saveTemplateAttributes: PropTypes.func,
};
