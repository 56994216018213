import React, { useState, useEffect, useCallback } from "react";
const { useRef, useImperativeHandle } = React;
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router";

//API
import {
  deleteAttributeValue,
  deleteAttribute,
  GetAttributeTemplates,
  deleteAttributeTemplate,
  deleteTemplate,
  createAttributesTemplate,
} from "../api/AttributeTemplates";

//Custom Components
import AttributeTemplateSkeleton from "../components/AttributeTemplates/AttributeTemplateSkeleton";
import AttributeTemplateCard from "../components/AttributeTemplates/AttributeTemplateCard";
import SnackbarMessage from "../components/SnackbarMessage";

//Styles needed for responsiveness
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
}));

const AttributeTemplateCardList = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [attributes, setAttributes] = useState([]);
  const [offset, setOffset] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  // We need use useImperativeHandle in order to expose ref to getAttributes for data refreshing
  useImperativeHandle(ref, () => ({
    refreshData() {
      // List product attributes
      getAllAttributeTemplate();
    },
  }));

  /**Infinite scroll */
  const observer = useRef();
  //Create a ref to the las element of the list
  const lastElementRef = useCallback((node) => {
    if (lastPage) return;
    if (observer.current) {
      // disconect the observer to set the new last element
      observer.current.disconnect();
    }
    // set the new observer to the last element
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        GetAttributeTemplates(offset).then((result) => {
          const templates = result.data.attributeTemplates;
          AttrbuteTemplateFormat(templates, "concat");
          setOffset(offset + 20);
          if (templates.length < 20) setLastPage(true);
        });
      }
    });
    if (node) observer.current.observe(node);
  });

  /**
   * Get All attribute template
   */
  const getAllAttributeTemplate = () => {
    GetAttributeTemplates(0)
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.attributeTemplates &&
          result.data.attributeTemplates.lenght != 0
        ) {
          const attributeTemplates = result.data.attributeTemplates;
          AttrbuteTemplateFormat(attributeTemplates, "assigned");
          setOffset(20);
          setLastPage(false);
        }
        setLoading(false);
      })
      .catch((xhr) => console.log("Error: ", xhr));
  };

  /**
   * Format attributes templates
   * @param {Array} attributeTemplates
   * @param {String} action concat|assigned
   */
  const AttrbuteTemplateFormat = (attributeTemplates, action = "concat") => {
    let templates = [];
    for (let template of attributeTemplates) {
      const allAttributeTemplates =
        template &&
        template.product_product_attributes &&
        template.product_product_attributes.lenght != 0
          ? template.product_product_attributes
          : [];
      let templateAttributes = [];
      if (allAttributeTemplates && allAttributeTemplates.lenght != 0) {
        allAttributeTemplates.forEach((element) => {
          const attribute =
            element.product_attribute && element.product_attribute
              ? element.product_attribute
              : null;
          let values =
            attribute &&
            attribute.product_attribute_values &&
            attribute.product_attribute_values.lenght != 0
              ? attribute.product_attribute_values
              : [];
          values = values.map((value) => {
            return {
              id: value.id,
              Name: value.Name,
              DisplayOrder: value.DisplayOrder,
              CostAdjustment: value.CostAdjustment,
              SkuExtension: value.SkuExtension,
            };
          });
          let item = {
            id: element.id,
            display_order: element.display_order,
            attributeId: attribute && attribute.id ? attribute.id : null,
            Name: attribute && attribute.Name ? attribute.Name : null,
            DisplayText:
              attribute && attribute.DisplayText ? attribute.DisplayText : null,
            ControlType:
              attribute && attribute.ControlType ? attribute.ControlType : null,
            required: element && element.required ? element.required : false,
            values,
          };
          templateAttributes.push(item);
        });
      }
      let item = {
        id: template.id,
        Name: template.Name,
        reseller:
          template && template.reseller && template.reseller.id
            ? template.reseller.id
            : "",
        attributes: templateAttributes,
      };
      templates.push(item);
    }
    if (action === "concat") {
      let newAttributes = attributes;
      newAttributes = newAttributes.concat(templates);
      setAttributes(newAttributes);
    } else {
      setAttributes(templates);
    }
  };

  /**Initial loading */
  useEffect(() => {
    //Get all product attributes
    getAllAttributeTemplate();
  }, []);

  /**
   * Delete attributes Template
   * @param {Object} AttributesTemplate
   */
  const DeleteTemplate = (AttributesTemplate) => {
    if (
      AttributesTemplate &&
      AttributesTemplate.attributes &&
      AttributesTemplate.attributes.lenght != 0
    ) {
      const attributes = AttributesTemplate.attributes;
      for (let attribute of attributes) {
        if (attribute && attribute.values && attribute.values.lenght != 0) {
          const values = attribute.values;
          for (let value of values) {
            deleteAttributeValue(value.id).then(() => {});
          }
          deleteAttribute(attribute.attributeId).then(() => {});
          deleteAttributeTemplate(attribute.id).then(() => {});
        }
      }
    }
    deleteTemplate(AttributesTemplate.id)
      .then(() => {
        setError({
          open: true,
          message: "Attributes Template successfully removed",
          severity: "success",
        });
        getAllAttributeTemplate();
      })
      .catch((xhr) => {
        setError({
          open: true,
          message: `There was an error, please try again later:  ${xhr.response.data.errors[0].message}`,
          severity: "error",
        });
      });
  };

  /**
   * Make a copy from attributes template
   * @param {Object} AttributesTemplate
   */
  const duplicateAttributesTemplate = (AttributesTemplate) => {
    let attributes =
      AttributesTemplate &&
      AttributesTemplate.attributes &&
      AttributesTemplate.attributes.lenght != 0
        ? AttributesTemplate.attributes
        : [];
    let copyTemplate = {
      Name: `(Copy) ${AttributesTemplate.Name}`,
      reseller: AttributesTemplate.reseller,
      attributes,
    };
    //Create new attributes temple record
    createAttributesTemplate(copyTemplate)
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.createAttributesTemplate &&
          result.data.createAttributesTemplate.id
        ) {
          setError({
            open: true,
            message: "Attributes Template successfully created",
            severity: "success",
          });
          getAllAttributeTemplate();
        } else {
          setError({
            open: true,
            message: `There was an error, please try again later:  ${result.data.errors[0].message}`,
            severity: "error",
          });
        }
      })
      .catch((xhr) => {
        setError({
          open: true,
          message: `There was an error, please try again later:  ${xhr.response.data.errors[0].message}`,
          severity: "error",
        });
      });
  };

  /**
   * Delete/Make a copy template attribute
   * @param {Object} attributeTemplate
   * @param {String} Action delete/copy
   */
  const attributeTemplateModify = (AttributeTemplate, Action = "delete") => {
    if (Action == "delete") {
      DeleteTemplate(AttributeTemplate);
    } else {
      duplicateAttributesTemplate(AttributeTemplate);
    }
  };

  /**
   * Closed SnackbarMessage
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  return (
    <Container
      style={{ marginTop: "85px" }}
      className={classes.mainContainer}
      maxWidth="xl"
    >
      <Grid container spacing={3} style={{ padding: "20px" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            color="textSecondary"
            onClick={() => history.push("/app/products")}
            style={{ cursor: "pointer" }}
          >
            Products
          </Link>
          <Typography color="textSecondary">Attributes Templates</Typography>
        </Breadcrumbs>
        <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography variant="h4" gutterBottom>
              Attributes Templates
            </Typography>
          </Grid>
          <Grid item xs={13} sm={6} md={3} lg={3}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={() => history.push("/app/attributes/template/new")}
            >
              ADD TEMPLATE
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {loading ? (
            [1, 2, 3, 4, 5, 6].map((index) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}>
                  <AttributeTemplateSkeleton />
                </Grid>
              );
            })
          ) : attributes.length < 1 ? (
            <Grid item xs={12}>
              <Typography variant="h5">
                No attributes templates for this reseller
              </Typography>
            </Grid>
          ) : (
            attributes.map((attribute, index) => {
              if (attributes.length == index + 1) {
                return (
                  //Each last attribute template
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={2}
                    key={index}
                    ref={lastElementRef}
                  >
                    <AttributeTemplateCard
                      attribute={attribute}
                      attributeTemplateModify={attributeTemplateModify}
                      key={attribute.id}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}>
                    <AttributeTemplateCard
                      attribute={attribute}
                      attributeTemplateModify={attributeTemplateModify}
                      key={attribute.id}
                    />
                  </Grid>
                );
              }
            })
          )}
        </Grid>
      </Grid>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
});

AttributeTemplateCardList.displayName = "AttributeTemplateCardList";

export default AttributeTemplateCardList;
