import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import ConfirmationDialog from "../ConfirmationDialog";
import { useHistory } from "react-router-dom";

const options = ["Edit", "Delete", "Make a copy"];
const ITEM_HEIGHT = 48;

export default function AttributeTemplateCard(props) {
  let history = useHistory();
  const { attribute, attributeTemplateModify = () => {} } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [makeCopy, setMakeCopy] = useState(false);

  /**
   * Options List Actions
   * @param {*} event
   */
  const handleClose = (event, attribute) => {
    setAnchorEl(null);
    const action = event.target.id;
    switch (action) {
      case "Edit":
        localStorage.setItem("template", JSON.stringify(attribute));
        history.push(`/app/attributes/template/${attribute.id}`);
        break;
      case "Delete":
        setDeleteTemplate(true);
        break;
      case "Make a copy":
        setMakeCopy(true);
        break;
      default:
        break;
    }
  };

  /**
   * Get attribute templates JSX
   * @param {*} attributes
   * @returns JSX Element
   */
  const attributes = (attributes) => {
    const values = attributes.values.map((value) => {
      return value.Name;
    });
    return (
      <Grid key={attributes.id}>
        {attributes && attributes.attributeId ? (
          <React.Fragment>
            <Typography gutterBottom display="inline">
              {`${[attributes.Name]}: `}
            </Typography>
            <Typography color="textSecondary" display="inline" gutterBottom>
              {values.join(", ")}
            </Typography>
          </React.Fragment>
        ) : (
          <Typography gutterBottom display="inline">
            No Attributes for this template
          </Typography>
        )}
      </Grid>
    );
  };

  /**
   * Render card view
   */
  return (
    <Grid>
      <Card>
        <CardHeader
          title={attribute.Name}
          action={
            <IconButton
              aria-label="settings"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              id={option}
              key={option}
              onClick={(e) => handleClose(e, attribute)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
        <CardContent style={{ paddingTop: "0px" }}>
          <Grid>
            {attribute &&
            attribute.attributes &&
            attribute.attributes.length != 0 ? (
              attribute.attributes.map((values) => {
                return attributes(values);
              })
            ) : (
              <Typography gutterBottom display="inline">
                No Attributes for this template
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
      {/**Delete attribute template */}
      <ConfirmationDialog
        open={deleteTemplate}
        dialogTitle="Delete Template"
        dialogContentText="This template will be deleted, the information will be lost, do you want to continue?"
        handleClose={() => setDeleteTemplate(false)}
        confirmButtonClick={() => attributeTemplateModify(attribute, "delete")}
        closeButtonText="CANCEL"
        confirmButtonText="DELETE"
        PropsButton={{ size: "medium" }}
      />
      {/**Make a copy attribute template */}
      <ConfirmationDialog
        open={makeCopy}
        dialogTitle="Make A Copy Template"
        dialogContentText="This template will be duplicated, the information will be copy, do you want to continue?"
        handleClose={() => setMakeCopy(false)}
        confirmButtonClick={() => attributeTemplateModify(attribute, "copy")}
        closeButtonText="CANCEL"
        confirmButtonText="CONTINUE"
        PropsButton={{ size: "medium" }}
        confirmButtonColor="primary"
      />
    </Grid>
  );
}

AttributeTemplateCard.propTypes = {
  attribute: PropTypes.object,
  attributeTemplateModify: PropTypes.func,
};
