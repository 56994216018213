import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Paper from "@material-ui/core/Paper";

import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import SearchIcon from "@material-ui/icons/Search";

import { Container, Grid, TextField, InputAdornment } from "@material-ui/core";
import { deleteProductAttribute } from "../api/Products";
import { listUsers } from "../api/Users";

import Skeleton from "@material-ui/lab/Skeleton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import ProductAttributeModal from "../components/products/ProductAttributeModal";
import NavigationTabs from "../components/NavigationTabs";
import Autocomplete from "@material-ui/lab/Autocomplete";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Username",
    disablePadding: true,
    label: "Username",
  },
  {
    id: "Name",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "BillingDepartment",
    disablePadding: false,
    label: "Billing Department",
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label} </b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  tableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
  },
  tableCellName: {
    paddingLeft: "16px",
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
}));

export default function UsersPointsManagement() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [attributeModalOpen, setAttributeModalOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  /**Initial loading */
  useEffect(() => {
    // List products
    getUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const getUsers = () => {
    listUsers().then((result) => {
      console.log(result.data.users);
      setUsers(result.data.users);
      setLoading(false);
    });
  };

  const toggleDeleteModal = (attr) => {
    setOpenDeleteModal(true);
    setSelectedAttribute(attr);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedAttribute({});
  };

  //close snackbar message
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };
  const handleDeleteAttribute = () => {
    deleteProductAttribute(selectedAttribute.id).then((res) => {
      if (res.data.deleteProductAttribute) {
        setError({
          message: "Product attribute deleted.",
          severity: "success",
          open: true,
        });
        closeDeleteModal();
        getUsers();
      } else if (res.response.data.errors.length) {
        setError({
          message: "An error ocurred",
          severity: "error",
          open: true,
        });
      }
    });
  };

  const successAddAttribute = () => {
    setError({
      message: "Product attribute created successfully.",
      severity: "success",
      open: true,
    });
  };

  const toggleAttributeModal = (reload) => {
    if (reload) {
      getUsers();
    }
    setAttributeModalOpen(!attributeModalOpen);
  };

  const tabsAdmin = {
    Users: "/app/users",
    Users_Points_Management: "/app/users-points",
  };
  return (
    <Container
      className={classes.mainContainer}
      maxWidth="xl"
      style={{ marginTop: "65px" }}
    >
      <Grid container spacing={3} style={{ padding: "20px" }} id="second_grid">
        <Grid item xs={12} id="grid_title">
          <NavigationTabs tabs={tabsAdmin} value={"Users_Points_Management"} />
        </Grid>
        <Grid id="grid_search" item xs={12} sm={6} lg={4}>
          <Autocomplete
            freeSolo
            style={{ marginLeft: "auto" }}
            id="search"
            disableClearable
            //options={users}
            //getOptionLabel={(option) => option.search_label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search all users"
                margin="none"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            //   onChange={(event, value /*reason*/) => {
            //     handleClickOpen(value);
            //   }}
          />
        </Grid>
        <Grid item xs={12} id="grid_title">
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={users.length}
                />
                <TableBody>
                  {loading
                    ? [1, 2, 3, 4, 5].map((index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.tableCellName}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : stableSort(users, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.username}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.Details.BillingDepartment}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <LaunchIcon />
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggleDeleteModal(row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <ConfirmationDialog
        dialogTitle="Delete product attribute"
        dialogContentText="Product attribute will be deleted, con76tinue?"
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        open={openDeleteModal}
        handleClose={closeDeleteModal}
        confirmButtonClick={handleDeleteAttribute}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
      <ProductAttributeModal
        modalOpen={attributeModalOpen}
        toggleModal={toggleAttributeModal}
        successAddAttribute={successAddAttribute}
      />
    </Container>
  );
}
