// Import Libraries
import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import PropTypes from "prop-types";
import moment from "moment";

// Import API Services
import { getClientMessages } from "../../api/Clients";

export default function StoreAlertMesage(props) {
  const { clientId = localStorage.getItem("client_id") } = props;
  const [message, setMessage] = useState({
    id: "",
    message: "",
    startDate: "",
    endDate: "",
  });

  // Initial values
  useEffect(() => {
    // Get client alert message
    if (clientId) {
      getAlertMessage();
    }
  }, [clientId]);

  /**
   * Get Client Alert Message
   */
  const getAlertMessage = () => {
    getClientMessages(clientId).then((result) => {
      if (
        result &&
        result.data &&
        result.data.informationMessages &&
        Array.isArray(result.data.informationMessages) &&
        result.data.informationMessages.length !== 0
      ) {
        // Get firts message
        const message = result.data.informationMessages[0];
        setMessage({
          id: message.id,
          message: message.message,
          startDate: moment(message.startDate).format("MM/DD/YYYY"),
          endDate: moment(message.endDate).format("MM/DD/YYYY"),
        });
      }
    });
  };

  return (
    <div>
      {message &&
        message.id &&
        message.startDate <= moment().format("MM/DD/YYYY") &&
        message.endDate >= moment().format("MM/DD/YYYY") && (
          <Alert severity="info">
            <AlertTitle>
              <b>{message.message}</b>
            </AlertTitle>
          </Alert>
        )}
    </div>
  );
}

StoreAlertMesage.propTypes = {
  clientId: PropTypes.string,
};
