import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Link,
  Typography,
  Breadcrumbs,
  Divider,
  TextField,
  Button,
  Paper,
  Hidden,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Formik, Form } from "formik";
import * as yup from "yup";
import ProductAttributeValuesModal from "../components/products/ProductAttributeValuesModal";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import { makeStyles } from "@material-ui/core/styles";
import {
  createAttributesTemplate,
  updateAttributesTemplate,
} from "../api/AttributeTemplates";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import AttributeDetails from "../components/products/AttributeDetails";
import TableValueAtrribute from "../components/products/TableValueAttribute";

//Styles needed for responsiveness
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
}));

/**
 * Initial New template
 */
const initialTemplate = {
  Name: "",
  display_order: 1,
  DisplayText: "",
  ControlType: "",
  required: false,
  values: [],
};

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  nameTemplate: yup
    .string("Atribute Template Name")
    .required("Atribute Template Name is required"),
  Name: yup.string("Atribute Name").required("Atribute Name is required"),
  DisplayText: yup.string("Atribute Display Text"),
  display_order: yup
    .number()
    .typeError("Please enter number")
    .test(
      "valueGreaterThanZero",
      "Please select a value that is no less than 1",
      (value) => {
        if (value) {
          return value != 0 && value > 0;
        }
      }
    ),
  required: yup.string("Produt attribute required"),
  ControlType: yup.string("Atrubute Control Type"),
});

const initialTemplateValue = (id) => {
  let template = JSON.parse(localStorage.getItem("template"));
  let attributes =
    id != undefined
      ? template.attributes
      : [
          {
            Name: "",
            display_order: 1,
            DisplayText: "",
            ControlType: "",
            required: false,
            values: [],
          },
        ];
  return attributes;
};

const AttributeTemplate = () => {
  let history = useHistory();
  const [nameTemplate, setNameTemplate] = useState("New Template");
  const { id } = useParams();
  const [attributes, setAtributes] = useState(initialTemplateValue(id));
  const [reloadList, setReloadList] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const classes = useStyles();
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedValue({});
  };

  const handleDeleteValue = () => {
    let data = [...attributes];
    data[selectedValue.position].values.splice(selectedValue.positionValue, 1);
    setAtributes(data);
    closeDeleteModal();
  };

  const addAttributeDetails = () => {
    let newObject = {
      Name: "",
      display_order: 1,
      DisplayText: "",
      ControlType: "",
      required: false,
      values: [],
    };
    setAtributes([...attributes, newObject]);
  };

  /** Variable declaration  */
  let attribute_id = false;
  const [attributeModalOpen, setAttributeModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const toggleAttributeModal = () => {
    setAttributeModalOpen(!attributeModalOpen);
  };

  const recoverDataAttributeValues = (valuesChildren) => {
    let attributesCopy = [];
    attributesCopy = [...attributes];
    if (valuesChildren.positionValue || valuesChildren.positionValue === 0) {
      attributesCopy[valuesChildren.position].values[
        valuesChildren.positionValue
      ] = valuesChildren;
    } else {
      attributesCopy[valuesChildren.position].values.push(valuesChildren);
    }
    setAtributes(attributesCopy);
    setAttributeModalOpen(false);
  };

  /**Update attribute value information  */
  const setInfoToStateMain = (positionIndex, nameInput, data) => {
    let attributesCopy = [...attributes];
    attributesCopy[positionIndex][nameInput] = data;
    setAtributes(attributesCopy);
  };

  const validateForm = (data) => {
    let valid = true;
    let menssage = `Some fields are incorrect.`;
    const requeridField = [
      "Name",
      "display_order",
      "DisplayText",
      "ControlType",
    ];
    // Validate that you have the template name
    if (data.Name == "") {
      valid = false;
      menssage = `The name of the template is required.`;
    }
    // Validate attribute parameters
    for (let attribute of data.attributes) {
      for (let param in attribute) {
        // Verify that the fields are not empty.
        if (requeridField.includes(param) && attribute[param] == "") {
          valid = false;
          menssage = `Some fields are empty.`;
        }
        // Validation for Display Order
        if (param == "display_order" && attribute[param] <= 0) {
          valid = false;
        }
      }
    }
    if (!valid) {
      setError({
        open: true,
        message: menssage,
        severity: "error",
      });
      return;
    }
    return valid;
  };

  /**
   * Save New Template in db
   */
  const saveTemplate = () => {
    let data = {
      Name: nameTemplate,
      attributes: attributes,
      reseller: localStorage.getItem("reseller_id"),
    };
    if (!validateForm(data)) {
      return;
    }
    createAttributesTemplate(data)
      .then((result) => {
        if (result.data.createAttributesTemplate) {
          setAtributes([initialTemplate]);
          history.push("/app/attributes/templates");
        } else {
          // Display error
          setError({
            open: true,
            message: `Error: ${result.errors[0].message}`,
            severity: "error",
          });
        }
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: "An error occurred",
          severity: "error",
        });
      })
      .then(() => {});
  };

  // Edit/Update Template
  const updateTemplate = () => {
    let data = {
      id: id,
      Name: nameTemplate,
      attributes: attributes,
      reseller: localStorage.getItem("reseller_id"),
    };
    if (!validateForm(data)) {
      return;
    }
    updateAttributesTemplate(data)
      .then((result) => {
        if (result.data.updateAttributesTemplate) {
          const updatedTemplate = templateFormat(
            result.data.updateAttributesTemplate
          );
          localStorage.setItem("template", JSON.stringify(updatedTemplate));
          history.push("/app/attributes/templates");
        } else {
          // Display error
          setError({
            open: true,
            message: `Error: ${result.errors[0].message}`,
            severity: "error",
          });
        }
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: "An error occurred",
          severity: "error",
        });
      })
      .then(() => {});
  };

  /**
   * Close snackbar
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };

  // Format template for output
  const templateFormat = (template) => {
    return {
      id: template.id,
      Name: template.Name,
      reseller: template.reseller.id,
      attributes: template.product_product_attributes.map((item) => {
        return {
          id: item.id,
          display_order: item.display_order,
          attributeId: item.product_attribute.id,
          Name: item.product_attribute.Name,
          DisplayText: item.product_attribute.DisplayText,
          ControlType: item.product_attribute.ControlType,
          required: item.required,
          values: item.product_attribute.product_attribute_values,
        };
      }),
    };
  };

  useEffect(() => {
    if (id != undefined) {
      let template = JSON.parse(localStorage.getItem("template"));
      setNameTemplate(template.Name);
      localStorage.setItem("reseller_id", template.reseller);
    }
  }, []);

  return (
    <Container
      className={classes.mainContainer}
      style={{ marginTop: "65px" }}
      maxWidth="xl"
    >
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              onClick={() => history.push("/app/products")}
            >
              Products
            </Link>
            <Link
              underline="hover"
              color="inherit"
              onClick={() => history.push("/app/attributes/templates")}
              style={{ cursor: "pointer" }}
            >
              Attributes Templates
            </Link>
            <Typography color="textSecondary">Add Template</Typography>
          </Breadcrumbs>
        </Grid>

        {/* Desktop view buttons */}
        <Hidden xsDown={true}>
          {/* Input Name Template  */}
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              id="nameTemplate"
              margin="dense"
              variant="outlined"
              value={nameTemplate}
              onChange={(event) => setNameTemplate(event.target.value)}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ textAlign: "right" }}>
            <Button
              onClick={addAttributeDetails}
              variant="contained"
              style={{
                marginLeft: "1%",
                background: "#FDFEFE",
                color: "#3F4CEB",
                marginTop: "10px",
              }}
            >
              ADD ATRIBUTE
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: "10px", marginTop: "10px" }}
              onClick={() => history.push("/app/attributes/templates")}
            >
              CANCEL
            </Button>
            <Button
              onClick={id ? updateTemplate : saveTemplate}
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              SAVE
            </Button>
          </Grid>
        </Hidden>

        {/* Mobile view buttons */}
        <Hidden smUp={true}>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <TextField
              size="small"
              id="nameTemplate"
              margin="dense"
              variant="outlined"
              value={nameTemplate}
              onChange={(event) => setNameTemplate(event.target.value)}
              style={{ width: "100%" }}
            />
            <Button
              onClick={addAttributeDetails}
              variant="contained"
              style={{
                marginLeft: "1%",
                marginTop: "3%",
                background: "#FDFEFE",
                color: "#3F4CEB",
                width: "100%",
              }}
            >
              ADD ATRIBUTE
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: "3px", marginTop: "3%", width: "100%" }}
            >
              CANCEL
            </Button>
            <Button
              onClick={id ? updateTemplate : saveTemplate}
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: "3px", marginTop: "3%", width: "100%" }}
            >
              SAVE
            </Button>
          </Grid>
        </Hidden>
      </Grid>
      {!reloadList &&
        attributes.map((attribute, index) => {
          return (
            <Formik
              key={index}
              initialValues={attribute}
              validationSchema={validationSchema}
            >
              {(formikParent) => (
                <Form onSubmit={formikParent.handleSubmit}>
                  <Grid container style={{ padding: "20px" }}>
                    {/* Form Attribute Details and Table Values---  */}
                    <Paper
                      variant="outlined"
                      style={{
                        width:
                          formikParent.values.ControlType == "radio" ||
                          formikParent.values.ControlType == "dropdown"
                            ? "100%"
                            : "34%",
                      }}
                    >
                      <Grid container>
                        {/* Attribute Details */}
                        <AttributeDetails
                          setReloadList={setReloadList}
                          attributes={attributes}
                          setAtributes={setAtributes}
                          formikParent={formikParent}
                          setInfoToStateMain={setInfoToStateMain}
                          index={index}
                        />
                        {/* Divider */}
                        {(formikParent.values.ControlType == "radio" ||
                          formikParent.values.ControlType == "dropdown") && (
                          <Grid md={1}>
                            <Divider orientation="vertical" variant="middle" />
                          </Grid>
                        )}
                        {/* Table Values */}
                        {(formikParent.values.ControlType == "radio" ||
                          formikParent.values.ControlType == "dropdown") && (
                          <TableValueAtrribute
                            classes={classes}
                            setSelectedValue={setSelectedValue}
                            attribute={attribute}
                            setAttributeModalOpen={setAttributeModalOpen}
                            index={index}
                            setOpenDeleteModal={setOpenDeleteModal}
                          />
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Form>
              )}
            </Formik>
          );
        })}

      <ProductAttributeValuesModal
        modalOpen={attributeModalOpen}
        toggleModal={toggleAttributeModal}
        atribute_id={attribute_id}
        selectedValue={selectedValue}
        recoverDataAttributeValues={recoverDataAttributeValues}
      />
      <ConfirmationDialog
        dialogTitle="Delete product attribute value"
        dialogContentText="Product attribute value will be deleted, continue?"
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        open={openDeleteModal}
        handleClose={closeDeleteModal}
        confirmButtonClick={handleDeleteValue}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default AttributeTemplate;
