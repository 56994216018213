export const countries = [
	{
		name: 'USA',
		abbreviation: 'US'
	},
	{
		name: 'Canada',
		abbreviation: 'CA'
	}
];
