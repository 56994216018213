import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { listClientUsers } from "../../api/Users";
import { useParams } from "react-router";
import { PropTypes } from "prop-types";
import { updateClientContacts } from "../../api/Clients";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  action_buttons: {
    marginTop: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function SelectContacts(props) {
  const classes = useStyles();
  const theme = useTheme();
  let { client_id } = useParams();
  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [currentContacts, setCurrentContacts] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    listClientUsers(client_id).then((res) => {
      let iterateUsers = res.data.users;
      let selectedUsers = [];
      iterateUsers.forEach((user) => {
        user.displayName = user.Details
          ? user.Details.FirstName + " " + user.Details.LastName
          : user.username;
        props.contactUsers.forEach((selected) => {
          if (user.id == selected.id) {
            selectedUsers.push(user.id);
            user.selected = true;
          }
        });
      });
      setUsers(iterateUsers);
      setCurrentContacts(selectedUsers);
    });
  }, [props.contactUsers, props.open]);

  const handleChange = (event) => {
    setContacts(event.target.value);
  };

  const updateContacts = () => {
    setSaving(true);
    let newContacts = currentContacts.concat(contacts);
    updateClientContacts(client_id, newContacts).then((res) => {
      if (res.data.updateClient) {
        setSaving(false);
        props.handleClose();
        props.reloadClient();
        setContacts([]);
        setUsers([]);
      }
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.open}
      onClose={props.handleClose}
    >
      <form id="user_dialog_form">
        <DialogTitle id="user_dialog_title">Select contacts</DialogTitle>
        <DialogContent id="user_dialog_content" style={{ overflowY: "hidden" }}>
          <Grid container spacing={2} id="user_dialog_container">
            {/* Division categories */}
            <Grid item xs={12}>
              {users.length == currentContacts.length ? (
                <Grid item xs={12}>
                  <Typography>No users avaliable</Typography>
                </Grid>
              ) : (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-chip-label">Contacts</InputLabel>
                  <Select
                    disabled={users.length == currentContacts.length}
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={contacts}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              users.find((element) => element.id == value)
                                ? users.find((element) => element.id == value)
                                    .displayName
                                : ""
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {users.map((user) =>
                      !user.selected ? (
                        <MenuItem
                          key={user.id}
                          value={user.id}
                          style={getStyles(user, contacts, theme)}
                        >
                          {user.displayName ? user.displayName : ""}
                        </MenuItem>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            id="buttons_container"
            className={classes.action_buttons}
          >
            <Grid item xs={2}></Grid>
            <Grid item xs={4} md={6}></Grid>
            <Grid item xs={3} md={2}>
              <Button variant="contained" fullWidth onClick={props.handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={updateContacts}
                disabled={saving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
}

SelectContacts.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  contactUsers: PropTypes.array,
  reloadClient: PropTypes.func,
};

export default SelectContacts;
