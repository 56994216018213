import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Grid,
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slide,
} from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
//Styles needed for buttons responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function CreateClientModal({
  open,
  image,
  setImage,
  handleClose,
  formik,
  saving,
}) {
  const styles = useStyles();
  return (
    <Dialog
      id="create_client"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="create_client_title"
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={formik.handleSubmit} id="create_client_form">
        <DialogTitle id="create_client_title">Create Client</DialogTitle>
        <DialogContent
          id="create_client_content"
          style={{ overflowY: "hidden" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <center>
                <img
                  src={
                    !image.url
                      ? "../../../img/avid/avid-io_500x532.png"
                      : image.url
                  }
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "215px",
                  }}
                />
              </center>
              <Divider style={{ width: "100%" }} />
              <center>
                <Button component="label" color="primary">
                  <input
                    //id="client_image"
                    type="file"
                    name="image"
                    accept="image/*"
                    hidden
                    //onChange={formik.handleChange(value)}
                    onChange={(event) => {
                      setImage({
                        files: event.target.files[0],
                        url: URL.createObjectURL(event.target.files[0]),
                      });
                    }}
                  />
                  Upload Picture
                </Button>
              </center>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Client name */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="name"
                    margin="dense"
                    label="Client Name"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    fullWidth
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                {/* Client Unique ID */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    id="uniqueId"
                    name="uniqueId"
                    margin="dense"
                    label="Unique ID"
                    variant="outlined"
                    value={formik.values.uniqueId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.uniqueId && Boolean(formik.errors.uniqueId)
                    }
                    helperText={
                      formik.touched.uniqueId && formik.errors.uniqueId
                    }
                  />
                </Grid>
                {/* Client primary color */}
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="primary_color"
                    name="primary_color"
                    label="Primary Color"
                    variant="outlined"
                    type="color"
                    value={formik.values.primary_color}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primary_color &&
                      Boolean(formik.errors.primary_color)
                    }
                    helperText={
                      formik.touched.primary_color &&
                      formik.errors.primary_color
                    }
                  />
                </Grid>
                {/* Client Secondary color */}
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="secondary_color"
                    name="secondary_color"
                    // margin="dense"
                    label="Secondary Color"
                    variant="outlined"
                    type="color"
                    value={formik.values.secondary_color}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.secondary_color &&
                      Boolean(formik.errors.secondary_color)
                    }
                    helperText={
                      formik.touched.secondary_color &&
                      formik.errors.secondary_color
                    }
                  />
                </Grid>
                {/**Client ordering mode */}
                <Grid item xs={12} id="grid_ordering_mode">
                  <Typography variant="h6">Ordering mode</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row={true}
                      aria-label="ordering_mode"
                      name="ordering_mode"
                      id="ordering_mode"
                      value={formik.values.ordering_mode}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="Default"
                        control={<Radio />}
                        label="Invoice"
                      />
                      <FormControlLabel
                        value="Approval"
                        control={<Radio />}
                        label="Approval"
                      />
                      <FormControlLabel
                        value="Points"
                        control={<Radio />}
                        label="Payment and Points"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            id="buttons_container"
            className={styles.ButtonsContainers}
          >
            <Grid item xs={6} md={8}></Grid>
            <Grid item xs={3} md={2}>
              <Button
                className={styles.Button}
                onClick={handleClose}
                variant="contained"
                disabled={saving}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                className={styles.Button}
                type="submit"
                color="primary"
                variant="contained"
                disabled={saving}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
}

CreateClientModal.propTypes = {
  open: PropTypes.bool,
  image: PropTypes.object,
  setImage: PropTypes.func,
  handleClose: PropTypes.func,
  formik: PropTypes.object,
  saving: PropTypes.bool,
};

export default CreateClientModal;
