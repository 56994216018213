import React from "react";
import PropTypes from "prop-types";
const { useRef } = React;
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
//import HistoryIcon from '@material-ui/icons/History';

import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Switch, Route, useHistory } from "react-router-dom";
import { Context } from "../../context/UserState";
import { useContext, useEffect, useState } from "react";
import SnackbarMessage from "../SnackbarMessage";
import { ClientSelector } from "../ClientSelector";

// API
import {
  listClientsFromReseller,
  listClientsFromUser,
} from "../../api/Clients";
import { getUserStagedInvoices } from "../../api/Store";
import { checkTestingUser } from "../../api/Users";
import { deleteStagedLineItem } from "../../api/StagedLineItems";

const drawerWidth = 190;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
    paddingTop: "65px",
  },
  drawerContainerSmall: {
    overflow: "auto",
  },
  clientListItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    //whiteSpace: "nowrap",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));
//Get Theme Colors
const defaultColors = JSON.parse(localStorage.getItem("themeColors"));
export default function StoreLayout({ routes, ...props }) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [client_image_url, setClientImageUrl] = useState(null);
  const [clients, setClients] = useState([]);
  const [selected_client, setSelectedClient] = useState(null);
  const [test_user, setTestUser] = useState(false);
  const initial_invoice = {
    InvoiceId: "",
    order_invoice: null,
    staged_line_items: [],
  };
  const [currentInvoice, setCurrentInvoice] = useState(initial_invoice);

  // Save original theme
  const original_theme = useTheme();
  // Controling current theme colors
  const [theme_colors, setThemeColors] = useState(defaultColors || {
    primary: original_theme.palette.primary.main,
    secondary: original_theme.palette.secondary.main,
  });

  // Check if you are logged in
  useEffect(() => {
    // Save current location
    if (state.token) {
      localStorage.setItem("pathname", props.location.pathname);
    }
    if (!state.token) {
      let token = localStorage.getItem("token");
      let currentLocation = localStorage.getItem("pathname");
      if (token) {
        if (currentLocation != props.location.pathname) {
          localStorage.setItem("pathname", props.location.pathname);
          history.push("/");
        }
      }
      history.push("/");
    }
  });

  // List clients from user logged in, only once at load
  useEffect(async function () {
    let user_id = localStorage.getItem("user");
    let isTestUser = await checkTestingUser(user_id);
    setTestUser(isTestUser);
    (isTestUser
      ? listClientsFromReseller()
      : listClientsFromUser({ user_id: user_id })
    ).then((result) => {
      //get clients
      let localClient = localStorage.getItem("client_id");
      const clients = result.data.clients;
      const client =
        localClient != 0
          ? clients.find((cli) => cli.id == localClient)
          : clients.length > 0
          ? clients[0]
          : null;
      setClients(clients);
      setSelectedClient(client);
      if (localStorage.getItem("client_id") == 0) {
        localStorage.setItem("client_id", client.id);
      }
      //Get client's data
      ClientData(client);
    });
    //set initial shopping cart
    getUserStagedInvoices().then((res) => {
      res.data.stagedInvoices.forEach((invoice) => {
        if (!invoice.order_invoice) {
          setCurrentInvoice(invoice);
          return;
        }
      });
    });
  }, []);

  //removing products from current client
  const removingProducts = () => {
    if (test_user) {
      //check the current staged invoice
      getUserStagedInvoices().then((res) => {
        let currentStagedInvoice = false;
        if (res.data && res.data.stagedInvoices) {
          res.data.stagedInvoices.forEach((invoice) => {
            if (!invoice.order_invoice) {
              currentStagedInvoice = invoice;
            }
          });
          //removing products
          if (
            currentStagedInvoice &&
            currentStagedInvoice.staged_line_items.length > 0
          ) {
            const staged_line_items = currentStagedInvoice.staged_line_items;
            staged_line_items.forEach((item) => {
              deleteStagedLineItem(item.id).then((result) => {
                if (result) {
                  //set initial shopping cart
                  getUserStagedInvoices().then((res) => {
                    res.data.stagedInvoices.forEach((invoice) => {
                      if (!invoice.order_invoice) {
                        setCurrentInvoice(invoice);
                        return;
                      }
                    });
                  });
                }
              });
            });
          }
        }
      });
    }
  };

  //Get client's data
  const ClientData = (value = false) => {
    test_user ? removingProducts() : "";
    const client = value ? value : selected_client;
    // Extract logo from first client
    let url = client
      ? client.client_image
        ? client.client_image.url
        : null
      : null;
    // Set client logo
    setClientImageUrl(url);
    // Set colors
    let client_ok = client ? true : false;
    let primary_color_ok = client_ok && client.primary_color ? true : false;
    let secondary_color_ok = client_ok && client.secondary_color ? true : false;
    let colors = {
      primary: primary_color_ok
        ? client.primary_color
        : original_theme.palette.primary.main,
      secondary: secondary_color_ok
        ? client.secondary_color
        : original_theme.palette.secondary.main,
    };
    //Set client_id to localstorage
    localStorage.setItem("themeColors", JSON.stringify(colors));
    setThemeColors(colors);
  };

  //get products in cart whenever it changes
  const getUserCart = () => {
    getUserStagedInvoices().then((res) => {
      res.data.stagedInvoices.forEach((invoice) => {
        if (!invoice.order_invoice) {
          setCurrentInvoice(invoice);
          return;
        } else {
          setCurrentInvoice(initial_invoice);
        }
      });
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("reseller_id");
    localStorage.removeItem("user");
    localStorage.removeItem("pathname");
    localStorage.removeItem("role");
    localStorage.removeItem("client_id");
    localStorage.removeItem("isTestUser");

    dispatch({
      type: "SET_TOKEN",
      payload: null,
    });
  };

  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "error",
    });
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: theme_colors.primary,
      },
      secondary: {
        main: theme_colors.secondary,
      },
      type: theme_colors.primary.includes("#fff") ? "dark" : "light",
    },
  });

  // Get ref to some child component
  const childRef = useRef();

  return state.token ? (
    <ThemeProvider theme={theme}>
      {/* Appbar */}
      <AppBar position="fixed" className={classes.appBar} id="appbar">
        <Toolbar id="toolbar">
          <Grid
            id="grid_container"
            container
            alignItems="center"
            justify="space-between"
            spacing={1}
          >
            <Grid item xs={2}>
              <img
                src={client_image_url ? client_image_url : ""}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "50px",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/store/products")}
              />
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
              {(test_user || clients.length > 1) && (
                <ClientSelector
                  clients={clients}
                  client={selected_client}
                  selectedClient={setSelectedClient}
                  clientData={ClientData}
                />
              )}
            </Grid>
            <Grid item xs={5} sm={6} md={7} id="grid_icons">
              <Grid
                container
                //alignItems="right"
                justify="flex-end"
                id="grid_icons_container_aligned"
              >
                <IconButton
                  edge="end"
                  aria-label="account"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => {
                    let prevPath = localStorage.getItem("pathname");
                    localStorage.setItem("previousPath", prevPath);
                    history.push("/app/user");
                  }}
                >
                  <AccountCircle />
                </IconButton>
                <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => history.push("/store/checkout")}
                >
                  <Badge
                    badgeContent={currentInvoice.staged_line_items.length}
                    color="secondary"
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => logout()}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes
            key={i}
            {...route}
            childRef={childRef}
            getUserCart={getUserCart}
          />
        ))}
      </Switch>
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
    </ThemeProvider>
  ) : (
    ""
  );
}
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <div>
      <Route
        path={route.path}
        render={(props) => {
          // pass the sub-routes down to keep nesting
          return (
            <route.component
              {...props}
              ref={route.childRef}
              routes={route.routes}
              getUserCart={route.getUserCart}
            />
          );
        }}
      />
    </div>
  );
}

StoreLayout.propTypes = {
  routes: PropTypes.array,
  location: PropTypes.object,
};
