import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

export default function OrdersCards(props) {
  const history = useHistory();
  //Get customer data
  let user = props.order.user;
  let invoice_user = props.order.staged_invoices.length
    ? props.order.staged_invoices[0].create_user
    : false;
  //Get customer name

  //Get order date
  let date = new Date(props.order.created_at);
  //Get order date in format DD/MM/YYYY
  let newdate =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  //Get hours in format 12 hours am/pm
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = newdate + ", " + hours + ":" + minutes + " " + ampm;

  return (
    <Card>
      <CardActionArea
        onClick={() => {
          history.push(`/app/orders/${props.order.id}`);
        }}
      >
        <CardContent>
          <Grid>
            <Typography gutterBottom display="inline">
              Order Invoice Number:
            </Typography>
            <Typography color={"textSecondary"} display="inline" gutterBottom>
              {" " + props.order.id}
            </Typography>
          </Grid>
          <Grid style={{ height: "48px" }}>
            <Typography gutterBottom display="inline">
              Created Date:
            </Typography>
            <Typography
              color={"textSecondary"}
              gutterBottom
              display="inline"
              style={{ wordBreak: "break-all" }}
            >
              {" " + strTime}
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom display="inline">
              Customer:
            </Typography>
            <Typography color={"textSecondary"} gutterBottom display="inline">
              {invoice_user
                ? " " +
                  invoice_user.Details.FirstName +
                  " " +
                  invoice_user.Details.LastName
                : "No customer user"}
            </Typography>
          </Grid>
          <Grid style={{ height: "72px" }}>
            <Typography gutterBottom display="inline">
              Customer Email:
            </Typography>
            <Typography
              color={"textSecondary"}
              gutterBottom
              display="inline"
              style={{ wordBreak: "break-all" }}
            >
              {invoice_user ? " " + invoice_user.email : "No customer user"}
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom display="inline">
              Ordered By:
            </Typography>
            <Typography color={"textSecondary"} gutterBottom display="inline">
              {user
                ? " " + user.Details?.FirstName + " " + user.Details?.LastName
                : " No user"}
            </Typography>
          </Grid>
          <Grid style={{ height: "72px" }}>
            <Typography gutterBottom display="inline">
              Ordered By Email:
            </Typography>
            <Typography
              color={"textSecondary"}
              gutterBottom
              display="inline"
              style={{ wordBreak: "break-all" }}
            >
              {user ? " " + user.email : " No user"}
            </Typography>
          </Grid>
          <Grid>
            <Typography gutterBottom display="inline">
              Status:
            </Typography>
            <Typography color={"textSecondary"} gutterBottom display="inline">
              {" " + props.order.Status}
            </Typography>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

OrdersCards.propTypes = {
  order: PropTypes.object,
  //handleclickOpen: PropTypes.func,
};
