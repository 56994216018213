import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { createPoints } from "../../api/Points";
import { updateUserDivisionCategories } from "../../api/Users";
//Styles needed for buttons responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));

const validationSchema = yup.object({
  selectedDivision: yup
    .string("Division Category")
    .required("Division category is required."),
  points: yup.string("Points"),
});

function AddDivisionCategoryModal({
  open,
  handleClose,
  saving,
  user_id,
  currentDivisionCategories,
  getUserDvCat,
  setError,
  availableOptions,
}) {
  const [initialValues, setInitialValues] = useState({
    selectedDivision: "",
    points: 0,
  });
  const styles = useStyles();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveDivisionCategory(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
    setInitialValues({
      selectedDivision: "",
      points: 0,
    });
  }, [open]);

  const createPointsBalance = (values) => {
    let pointsToAdd = {
      id: values.selectedDivision,
      balance: values.points,
      original_balance: 0,
    };
    createPoints(user_id, pointsToAdd).then((res) => {
      if (res.data.createPoint) {
        setError({
          open: true,
          message: "Division category added successfully to user.",
          severity: "success",
        });
      }
      handleClose();
      getUserDvCat();
    });
  };

  //add the new category
  const saveDivisionCategory = (values) => {
    let categories = [...currentDivisionCategories];
    let categoriesArray = [];
    categories.forEach((category) => {
      categoriesArray.push(category.id);
    });
    categoriesArray.push(values.selectedDivision);

    updateUserDivisionCategories(user_id, categoriesArray).then((res) => {
      if (res.data.updateUser) {
        createPointsBalance(values);
      }
    });
  };
  return (
    <Dialog
      id="create_client"
      open={open}
      onClose={handleClose}
      aria-labelledby="create_client_title"
      fullWidth
    >
      <form id="create_client_form" onSubmit={formik.handleSubmit}>
        <DialogTitle id="create_client_title">
          Add division category
        </DialogTitle>
        <DialogContent
          id="create_client_content"
          style={{ overflowY: "hidden" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                id="division_category_form_control"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                error={
                  formik.touched.selectedDivision &&
                  Boolean(formik.errors.selectedDivision)
                }
              >
                <InputLabel id="division_category_label">
                  Division Category
                </InputLabel>
                <Select
                  labelId="division_category_label"
                  label="Division Category"
                  id="selectedDivision"
                  name="selectedDivision"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={formik.values.selectedDivision}
                  onChange={formik.handleChange}
                >
                  {availableOptions.length == 0 ? (
                    <MenuItem disabled value={0}>
                      No options
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {availableOptions.map((division_category) => (
                    <MenuItem
                      key={division_category.id}
                      value={division_category.id}
                    >
                      {division_category.Name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.selectedDivision &&
                    formik.errors.selectedDivision}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Points"
                id="points"
                name="points"
                placeholder="Points"
                margin="none"
                variant="outlined"
                type="number"
                value={formik.values.points}
                inputProps={{ min: 0 }}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            id="buttons_container"
            className={styles.ButtonsContainers}
          >
            <Grid item xs={6} md={8}></Grid>
            <Grid item xs={3} md={2}>
              <Button
                className={styles.Button}
                onClick={handleClose}
                variant="contained"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                className={styles.Button}
                type="submit"
                color="primary"
                variant="contained"
                disabled={saving}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
}

AddDivisionCategoryModal.propTypes = {
  open: PropTypes.bool,
  image: PropTypes.object,
  setImage: PropTypes.func,
  handleClose: PropTypes.func,
  saving: PropTypes.bool,
  user_id: PropTypes.string,
  currentDivisionCategories: PropTypes.array,
  getUserDvCat: PropTypes.func,
  setError: PropTypes.func,
  availableOptions: PropTypes.array,
};

export default AddDivisionCategoryModal;
