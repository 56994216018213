import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  makeStyles,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import SnackbarMessage from "../SnackbarMessage";
import ConfirmationDialog from "../ConfirmationDialog";
import AddDivisionCategoryModal from "../User/AddDivisionCategoryModal";
import AddStoreModal from "./AddStoreModal";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons//Delete";
import { IconButton } from "@material-ui/core";

//Api
import {
  getUserById,
  updateUserDivisionCategories,
  updateUserStores,
} from "../../api/Users";
import { createPoints } from "../../api/Points";
import {
  listClientsFromUser,
  getUserDivisionCategories,
} from "../../api/Clients";

// Import components
import PointSchedulerDialog from "../PointSchedulerDialog";
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    padding: "20px",
  },
  tableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
    width: "50%",
  },
  removeStoreBtn: {
    float: "left",
    marginTop: "8px",
  },
  storesContainer: {
    height: "auto",
    maxHeight: "722px",
    minHeight: 330,
    overflow: "auto",
  },
  titleStores: {
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },
  headRowCategory: {
    background: "#f6f6f6",
    height: "49px",
  },
}));

// local hierarchy value table
const hierarchies = {
  superadmin: 7,
  reselleradmin: 6,
  clientadmin: 5,
  clientproductmanager: 4,
  clientusermanager: 3,
  clientorderapprover: 2,
  customer: 1,
};

/**
 * Validate that users have access according to the hierarchy
 * @param {string} role user in session
 * @param {string} roleProfile visited user
 * @return {boolean} has access
 */
const userAccess = (role, roleProfile) => {
  // exceptions
  const userWithoutAccess = ["clientorderapprover", "customer"];
  if (userWithoutAccess.includes(role)) {
    return false;
  }
  // evaluate permission by hierarchy
  const profileHierarchy = hierarchies[roleProfile];
  const editorHierarchy = hierarchies[role];
  const hasAccess = editorHierarchy >= profileHierarchy;
  return hasAccess;
};

export default function UserPointsContainer(props) {
  const classes = useStyles();
  const [DivisionCat, setDivisionCat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDivision, setSelectedDivison] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { user_id } = props;
  const role = localStorage.getItem("role");
  const [roleProfile, setRoleProfile] = useState("");

  const [openAddStoreModal, setOpenAddStoreModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmDialog, setConfirmDialog] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [userStores, setUserStores] = useState([]);
  const [stores, setStores] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [allUserDivsionCategories, setallUserDivsionCategories] = useState([]);
  const [paginationCategories, setPaginationCategories] = useState([]);
  const [refreshStores, setRefreshStores] = useState(false);
  const [clientDivisionCategories, setClientDivisionCategories] = useState([]);

  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**Initial user data*/
  useEffect(() => {
    //Get initial user data
    setLoading(true);
    getUserDvCat();
    getUserStores();
    setRefreshStores(false);
    getUserDivisionCategoriesByUserId();
  }, [refreshStores]);

  //Get user data
  const getUserDvCat = () => {
    if (user_id) {
      setLoading(true);
      getUserById(user_id)
        .then((xhr) => {
          if (xhr.data) {
            const user = xhr.data.user;
            let categories = [];
            user.division_categories.forEach((cat) => {
              let category = {};
              category.name = cat.Name;
              category.id = cat.id;
              category.balance = cat.points.length
                ? cat.points[cat.points.length - 1].Balance
                  ? cat.points[cat.points.length - 1].Balance
                  : 0
                : 0;
              category.original_balance = cat.points.length
                ? cat.points[cat.points.length - 1].Balance
                  ? cat.points[cat.points.length - 1].Balance
                  : 0
                : 0;
              categories.push(category);
              category.changed = false;
              //assign the division categories for each client
              const clientFound = user?.clients?.findIndex(
                (store) => store?.id === cat?.clients[0]?.id
              );
              if (clientFound != -1) {
                cat.balance = category.balance;
                cat.original_balance = category.original_balance;
                if (user.clients[clientFound].divisionCategories) {
                  user.clients[clientFound].divisionCategories.push(cat);
                } else {
                  user.clients[clientFound].divisionCategories = [cat];
                }
              }
            });
            setRoleProfile(user.role.type);
            loadPagination(user.clients);
            setallUserDivsionCategories(user.division_categories);
            // Processing client data
            if (user?.clients && user.username) {
              // Get client code
              const clientCode = user.username.split("-")[0];
              // Filter user clients
              user.clients = user.clients.map((client) => {
                return {
                  customClient: clientCode == client.subdomain,
                  ...client,
                };
              });
            }
            setStores(user.clients);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * Generates paging for the user's stores
   * @param {array} clients
   */
  const loadPagination = (clients) => {
    let pagination = [];
    clients.forEach(() => {
      pagination.push({
        rowsPerPage: 4,
        page: 0,
      });
    });
    setPaginationCategories(pagination);
  };

  /**
   * Description: Function to get the user's stores
   */
  const getUserStores = () => {
    listClientsFromUser({ user_id }).then((response) => {
      setUserStores(response.data.clients);
    });
  };

  const createPointsBalance = (points) => {
    if (points.changed) {
      createPoints(user_id, points).then((res) => {
        if (res.data?.createPoint) {
          setError({
            open: true,
            message: "Points saved",
            severity: "success",
          });
        }
        getUserDvCat();
      });
    }
  };

  //Toggle delete modal
  const toggleConfirmationModal = (category) => {
    setConfirmTitle("Delete division category");
    setConfirmDialog(
      "Are you sure you want to delete this division category from this user?"
    );
    setOpenConfirmation(true);
    setSelectedDivison(category);
  };

  const confirmDeleteDivisionCategory = () => {
    let categories = [...allUserDivsionCategories];
    let categoriesArray = [];
    categories.forEach((category) => {
      if (category.id != selectedDivision.id) {
        categoriesArray.push(category.id);
      }
    });
    updateUserDivisionCategories(user_id, categoriesArray).then((res) => {
      if (res.data.updateUser) {
        setError({
          open: true,
          message: "Division category successfully deleted from user.",
          severity: "success",
        });
        getUserDvCat();
        getUserDivisionCategoriesByUserId();
        setOpenConfirmation(false);
      }
    });
  };

  /**
   * Launches store deletion confirmation modal
   * @param {obj} store object type element to be deleted
   */
  const toggleConfirmDeleteStore = (store) => {
    setConfirmTitle("Delete store");
    setConfirmDialog(
      "Are you sure you want to remove this store from this user?"
    );
    setOpenConfirmation(true);
    setSelectedStore(store);
  };

  /**
   * Consumes service to clear the store
   */
  const confirmDeleteStore = () => {
    const userStoresIds = userStores.map((store) => store.id);
    const clients = [...userStoresIds];
    const positionStore = clients.findIndex(
      (clientId) => clientId === selectedStore.id
    );
    clients.splice(positionStore, 1);
    const request = {
      clients,
      id: user_id,
    };
    updateUserStores(request).then((response) => {
      if (response.errors) {
        console.log(response.errors);
        return;
      }
      setRefreshStores(true);
    });
  };

  //Select page
  const handleChangePage = (event, pageNumber, position) => {
    let pagination = [...paginationCategories];
    pagination[position].page = pageNumber;
    setPaginationCategories(pagination);
  };

  //PerPage
  const handleChangeRowsPerPage = (event, position) => {
    let pagination = [...paginationCategories];
    pagination[position].rowsPerPage = +event.target.value;
    pagination[position].page = 0;
    setPaginationCategories(pagination);
  };

  /**
   * Description: Function to get all available division categories of the store
   * @param {array} options all division categories of the store
   * @param {array} [divisionCategories=[]] division categories that the store is already assigned
   * @return {array} division categories available
   */
  const categoriesOptions = (options, divisionCategories = []) => {
    const availableOptions = [];
    options.forEach((option) => {
      if (!divisionCategories.find((division) => division.id === option.id)) {
        availableOptions.push(option);
      }
    });
    return availableOptions;
  };

  // Get user division categories
  useEffect(() => {
    getUserDivisionCategoriesByUserId();
  }, [user_id]);

  /**
   * Get user division categories
   */
  const getUserDivisionCategoriesByUserId = () => {
    getUserDivisionCategories(user_id).then((res) => {
      setClientDivisionCategories(res);
    });
  };

  return (
    <div>
      <Paper
        variant="outlined"
        className={classes.paper + " " + classes.storesContainer}
      >
        {/**User points title*/}
        <Grid container>
          <Grid item sm={8} xs={12}>
            <Typography variant="h6" gutterBottom>
              User Stores
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            {userAccess(role, roleProfile) ? (
              <>
                <Grid container alignItems="center" justify="flex-end">
                  {props.client_id && props.client_id != "No client" && (
                    <PointSchedulerDialog
                      openButtonText="MANAGE POINTS"
                      openButtonVariant="text"
                      openButtonColor="primary"
                      openButtonFullWidth={false}
                      dialogTitle="Manage Points"
                      division_categories={clientDivisionCategories}
                      setError={setError}
                      getSchedulePoints={getUserDivisionCategoriesByUserId}
                      client_id={props.client_id}
                      closeButtonText="Cancel"
                      closeButtonVariant="contained"
                      closeButtonColor="default"
                      confirmButtonText="Save"
                      confirmButtonVariant="contained"
                      confirmButtonColor="primary"
                      userIds={[user_id]}
                      clientFromDivisionCategory={true}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px", float: "right" }}
                    onClick={() => {
                      setOpenAddStoreModal(true);
                    }}
                  >
                    ASSIGN
                  </Button>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {stores.map((store, pos) => {
          return (
            <div key={store.id}>
              <Grid item sm={12} className={classes.titleStores}>
                {store.name}
              </Grid>
              <center>
                <div>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow className={classes.headRowCategory}>
                          <TableCell
                            id={"divisionCategory"}
                            align={"left"}
                            padding={"none"}
                            className={classes.tableCell}
                          >
                            <b>Division Category</b>
                          </TableCell>
                          <TableCell
                            key={"points"}
                            align={"left"}
                            padding={"none"}
                            className={classes.tableCell}
                          >
                            <b>Points</b>
                          </TableCell>
                          <TableCell
                            key={"addDivisionCategory"}
                            align={"center"}
                            padding={"none"}
                            className={classes.tableCell}
                          >
                            {userAccess(role, roleProfile) && (
                              <IconButton
                                onClick={() => {
                                  const options = categoriesOptions(
                                    store.options,
                                    store.divisionCategories
                                  );
                                  setAvailableOptions(options);
                                  setDivisionCat(allUserDivsionCategories);
                                  setOpenAddModal(true);
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading
                          ? [1, 2, 3].map((index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell
                                    className={classes.tableCell}
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <Skeleton />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    <Skeleton />
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className={classes.tableCell}
                                  >
                                    <Skeleton />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : store.divisionCategories &&
                            store.divisionCategories
                              .slice(
                                paginationCategories[pos].page *
                                  paginationCategories[pos].rowsPerPage,
                                paginationCategories[pos].page *
                                  paginationCategories[pos].rowsPerPage +
                                  paginationCategories[pos].rowsPerPage
                              )
                              .map((cat, index) => {
                                return (
                                  <TableRow tabIndex={-1} key={cat.id}>
                                    <TableCell
                                      className={classes.tableCell}
                                      component="th"
                                      id={index}
                                      scope="row"
                                      padding="none"
                                    >
                                      {cat.Name.length > 28
                                        ? cat.Name.slice(0, 29) + "..."
                                        : cat.Name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={classes.tableCell}
                                    >
                                      <TextField
                                        disabled={
                                          !userAccess(role, roleProfile)
                                        }
                                        variant={"outlined"}
                                        type="number"
                                        label="Points"
                                        size={"small"}
                                        onBlur={() => {
                                          createPointsBalance(cat);
                                        }}
                                        onChange={(e) => {
                                          let categories = [
                                            ...allUserDivsionCategories,
                                          ];
                                          categories.forEach((category) => {
                                            if (category.id == cat.id) {
                                              category.balance = e.target.value;
                                              category.changed = true;
                                            }
                                          });
                                          setallUserDivsionCategories(
                                            categories
                                          );
                                          setDivisionCat(categories);
                                        }}
                                        value={cat.balance}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={classes.tableCell}
                                    >
                                      {userAccess(role, roleProfile) ? (
                                        <IconButton
                                          onClick={() => {
                                            toggleConfirmationModal(cat);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container>
                    <Grid item sm={6} position="start">
                      {userAccess(role, roleProfile) && (
                        <>
                          {store?.customClient ? (
                            <Tooltip title="Default store, not removable">
                              <span>
                                <Button
                                  color="secondary"
                                  className={classes.removeStoreBtn}
                                  size="small"
                                  onClick={() =>
                                    toggleConfirmDeleteStore(store)
                                  }
                                  disabled={store?.customClient}
                                >
                                  {"Remove"}
                                </Button>
                              </span>
                            </Tooltip>
                          ) : (
                            <Button
                              color="secondary"
                              className={classes.removeStoreBtn}
                              size="small"
                              onClick={() => toggleConfirmDeleteStore(store)}
                            >
                              Remove
                            </Button>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      <TablePagination
                        rowsPerPageOptions={[4]}
                        component="div"
                        count={
                          store.divisionCategories
                            ? store.divisionCategories.length
                            : 0
                        }
                        rowsPerPage={paginationCategories[pos]?.rowsPerPage}
                        page={paginationCategories[pos]?.page}
                        onChangePage={(e, pageNumber) => {
                          handleChangePage(e, pageNumber, pos);
                        }}
                        onChangeRowsPerPage={(e) => {
                          handleChangeRowsPerPage(e, pos);
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </center>
            </div>
          );
        })}
      </Paper>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
      {openConfirmation && (
        <ConfirmationDialog
          open={openConfirmation}
          dialogTitle={confirmTitle}
          dialogContentText={confirmDialog}
          handleClose={() => {
            setOpenConfirmation(false);
            setSelectedStore("");
            setSelectedDivison("");
          }}
          confirmButtonClick={
            selectedStore != ""
              ? confirmDeleteStore
              : confirmDeleteDivisionCategory
          }
        />
      )}
      {openAddModal && (
        <AddDivisionCategoryModal
          open={openAddModal}
          client_id={props.client_id}
          handleClose={() => {
            setOpenAddModal(false);
          }}
          user_id={user_id}
          currentDivisionCategories={DivisionCat}
          getUserDvCat={() => {
            getUserDvCat();
            getUserDivisionCategoriesByUserId();
          }}
          setError={setError}
          clients={props.clients}
          availableOptions={availableOptions}
        />
      )}
      {openAddStoreModal && (
        <AddStoreModal
          open={openAddStoreModal}
          handleClose={() => setOpenAddStoreModal(false)}
          userId={user_id}
          userStores={userStores}
          setRefreshStores={() => setRefreshStores(true)}
        />
      )}
    </div>
  );
}

UserPointsContainer.propTypes = {
  user_id: PropTypes.string,
  client_id: PropTypes.string,
  isAdmin: PropTypes.bool,
  clients: PropTypes.array,
};
