const UserReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_PERSON":
      return {
        ...state,
        person: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SET_ROLES":
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
