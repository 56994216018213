import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function ConfirmationDialog({
  dialogTitle = "Title",
  dialogContentText = "Are you sure you want to continue?",
  closeButtonText = "No",
  closeButtonVariant = "contained",
  closeButtonColor = "default",
  confirmButtonText = "Yes",
  confirmButtonClick = () => {},
  handleClose = () => {},
  confirmButtonVariant = "contained",
  confirmButtonColor = "secondary",
  open = false,
  PropsButton = { size: "small" },
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant={closeButtonVariant}
          color={closeButtonColor}
          {...PropsButton}
        >
          {closeButtonText}
        </Button>
        <Button
          onClick={() => {
            confirmButtonClick();
            handleClose();
          }}
          variant={confirmButtonVariant}
          color={confirmButtonColor}
          {...PropsButton}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  closeButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonClick: PropTypes.func,
  handleClose: PropTypes.func,
  confirmButtonVariant: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  open: PropTypes.bool,
  PropsButton: PropTypes.object,
};

export default ConfirmationDialog;
