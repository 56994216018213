import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import * as yup from "yup";

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  clients: yup.array(
    yup.object({
      id: yup.string("Client").required("Client is required"),
    })
  ),
});

export default function AddClientDialog(props) {
  const {
    open = false,
    options = [],
    save = () => {},
    toggleModal = () => {},
  } = props;
  const [initialValues, setInitialValues] = useState({
    clients: "",
  });

  useEffect(() => {
    formik.resetForm();
    setInitialValues({
      clients: "",
    });
  }, [open]);

  /***Formik initialization */
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const clientIds = values.clients.map((client) => client.id);
      save(clientIds, "Add");
      toggleModal();
    },
  });

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Add client</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/**Client selector modal */}
          <Grid item xs={12}>
            <Autocomplete
              autoComplete
              autoHighlight
              freeSolo
              id="clients"
              clearOnBlur
              options={options}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add client"
                  placeholder="Add client"
                  margin="none"
                  variant="outlined"
                />
              )}
              onChange={(event, value) =>
                formik.setFieldValue("clients", value)
              }
              multiple
              fullWidth
            />
            {formik.touched.clients && Boolean(formik.errors.clients) ? (
              <p
                style={{
                  marginLeft: "14px",
                  marginRight: "14px",
                  marginTop: "4px",
                  color: "#f44336",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontSize: "0.75rem",
                }}
              >
                {formik.touched.clients && formik.errors.clients}
              </p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={3} md={3}></Grid>
          <Grid item xs={3} md={3}>
            <Button onClick={toggleModal} variant="contained">
              Close
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

AddClientDialog.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.array,
  save: PropTypes.func,
  toggleModal: PropTypes.func,
};
