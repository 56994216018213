import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

export const listRoles = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        query{
          roles{
            id
            name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data.roles) {
      let roles = [];
      response.data.data.roles.forEach((role) => {
        role.id == "1" || role.id == "2" ? "" : roles.push(role);
      });
      response.data.data.roles = roles;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
