import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const ControlTypes = [
  {
    name: "Dropdown",
    value: "dropdown",
  },
  {
    name: "Number",
    value: "number",
  },
  {
    name: "Radio",
    value: "radio",
  },
  {
    name: "Text",
    value: "text",
  },
];

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  delete: {
    float: "right",
    marginTop: "15px",
  },
  fullwidth: {
    width: "100%",
  },
}));

export default function AttributeDetails(props) {
  const classes = useStyles();
  const deleteAttributeDetails = (index) => {
    props.setReloadList(true);
    let data = [...props.attributes];
    data.splice(index, 1);
    props.setAtributes(data);
    setTimeout(() => {
      props.setReloadList(false);
    }, 0);
  };

  return (
    <Grid
      container
      spacing={2}
      md={
        props.formikParent.values.ControlType == "radio" ||
        props.formikParent.values.ControlType == "dropdown"
          ? 4
          : 12
      }
      style={{ padding: "20px" }}
    >
      {/* Attribute Details */}
      <Grid item xs={12} md={12}>
        {/* Form Attribute Details */}
        <Typography variant="h6" gutterBottom style={{ paddingLeft: "10px" }}>
          Attribute Details
        </Typography>
      </Grid>
      {/**Produc attribute - Name */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="Name"
          margin="dense"
          label="Name"
          variant="outlined"
          value={props.formikParent.values.Name}
          onChange={(event, child) => {
            props.formikParent.handleChange(event, child);
            props.setInfoToStateMain(
              props.index,
              event.target.id,
              event.target.value
            );
          }}
          helperText={props.formikParent.errors.Name}
          size="small"
        />
      </Grid>
      {/**Produc attribute - Display Order */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          type="number"
          size="small"
          inputProps={{ min: 1 }}
          id="display_order"
          margin="dense"
          label="Display Order"
          variant="outlined"
          value={props.formikParent.values.display_order}
          onChange={(event, child) => {
            props.formikParent.handleChange(event, child);
            props.setInfoToStateMain(
              props.index,
              event.target.id,
              event.target.value
            );
          }}
          helperText={props.formikParent.errors.display_order}
        />
      </Grid>
      {/**Produc attribute - Display Text */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id="DisplayText"
          margin="dense"
          label="Display Text"
          variant="outlined"
          value={props.formikParent.values.DisplayText}
          onChange={(event, child) => {
            props.formikParent.handleChange(event, child);
            props.setInfoToStateMain(
              props.index,
              event.target.id,
              event.target.value
            );
          }}
          helperText={props.formikParent.errors.DisplayText}
        />
      </Grid>
      {/**Produc attribute - Control type */}
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="ControlType"
          select
          margin="dense"
          label="Control Type"
          variant="outlined"
          value={props.formikParent.values.ControlType}
          onChange={(event) => {
            props.formikParent.setFieldValue("ControlType", event.target.value);
            props.setInfoToStateMain(
              props.index,
              "ControlType",
              event.target.value
            );
          }}
          helperText={props.formikParent.errors.ControlType}
          size="small"
        >
          {ControlTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {/**Product attribute Requiered and Button Delete*/}
      <Grid item xs={12}>
        <FormControlLabel
          style={{ marginTop: "15px" }}
          control={
            <Checkbox
              name="required"
              color="primary"
              checked={props.formikParent.values.required}
              onChange={(event, child) => {
                props.formikParent.handleChange(event, child);
                props.setInfoToStateMain(props.index, event.target.name, child);
              }}
              helperText={props.formikParent.errors.required}
            />
          }
          label=" Required"
        ></FormControlLabel>

        <Button
          onClick={() => {
            deleteAttributeDetails(props.index);
            setTimeout(() => {
              props.formikParent.setValues();
            }, 500);
          }}
          variant="contained"
          color="secondary"
          className={classes.delete}
        >
          DELETE
        </Button>
      </Grid>
    </Grid>
  );
}

AttributeDetails.propTypes = {
  setReloadList: PropTypes.func,
  attributes: PropTypes.array,
  setAtributes: PropTypes.func,
  formikParent: PropTypes.object,
  setInfoToStateMain: PropTypes.func,
  index: PropTypes.number,
};
