import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import SelectContacts from "./SelectContacts";
import ConfirmationDialog from "../ConfirmationDialog";
import { updateClientContacts } from "../../api/Clients";
import { useParams } from "react-router";
import { Skeleton } from "@material-ui/lab";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  contactsContainer: {
    maxHeight: "290px",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "10px",
  },
}));

function ClientContact(props) {
  let { client_id } = useParams();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [contactToDelete, setContactToDelete] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  //Open delete dialog and select the id of the user to be deleted
  const openDeleteContact = (id) => {
    setContactToDelete(id);
    setOpenConfirmation(true);
  };

  //update the client with the list of contacts without the contact to be deleted
  const confirmDeleteContact = () => {
    let newContacts = [];
    props.contact_user.forEach((contact) => {
      if (contact.id !== contactToDelete) {
        newContacts.push(contact.id);
      }
    });

    updateClientContacts(client_id, newContacts).then((res) => {
      if (res.data.updateClient) {
        setOpenConfirmation(false);
        props.reloadClient();
      }
    });
  };
  return (
    <div>
      <Paper className={classes.paper}>
        {props.loading ? (
          // Skeleton
          <Grid container spacing={2}>
            <Grid item xs={8} md={6}>
              <Skeleton height={41.6} />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Hidden smUp>
                <Skeleton height={36} />
              </Hidden>
              <Hidden xsDown>
                <Skeleton
                  height={36}
                  width={136.48}
                  style={{ float: "right" }}
                />
              </Hidden>
            </Grid>
            {[1, 2].map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton height={32} width={"60%"} />
                <Skeleton height={29.6} width={"50%"} />
                <Skeleton height={29.6} width={"40%"} />
                <Skeleton
                  height={36}
                  width={88.86}
                  style={{ float: "right" }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={8} md={6}>
              <Typography variant="h4">Contact</Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <Hidden smUp>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => setOpen(true)}
                >
                  Add Contact
                </Button>
              </Hidden>
              <Hidden xsDown>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => setOpen(true)}
                >
                  Add Contact
                </Button>
              </Hidden>
            </Grid>

            <Grid container spacing={2} className={classes.contactsContainer}>
              {props.contact_user && props.contact_user.length ? (
                props.contact_user.map((user) => {
                  return (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Typography variant="h5" component="h2" noWrap={true}>
                        Name:{" "}
                        {user.Details
                          ? user.Details.FirstName + " " + user.Details.LastName
                          : ""}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        USERNAME: {user.username}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Billing Department:{" "}
                        {user.Details ? user.Details.BillingDepartment : ""}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ float: "right" }}
                        onClick={() => openDeleteContact(user.id)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  );
                })
              ) : (
                <Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">No contact assigned</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <SelectContacts
          open={open}
          handleClose={handleClose}
          contactUsers={props.contact_user}
          reloadClient={props.reloadClient}
        />
        <ConfirmationDialog
          open={openConfirmation}
          dialogTitle="Delete Contact"
          dialogContentText="Are you sure you want to delete this contact?"
          handleClose={() => {
            setOpenConfirmation(false);
          }}
          confirmButtonClick={confirmDeleteContact}
        />
      </Paper>
    </div>
  );
}

ClientContact.propTypes = {
  contact_user: PropTypes.array,
  reloadClient: PropTypes.func,
  loading: PropTypes.bool,
};

export default ClientContact;
