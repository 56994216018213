import React, { useState, useEffect, useCallback } from "react";
const { /*forwardRef,*/ useRef, useImperativeHandle } = React;
import {
  Typography,
  Container,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { startOfDay, endOfDay } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
//Api
import {
  searchOrdersInvoices,
  getLimitOrderInvoiceById,
  sendOrdersToAcumatica,
} from "../api/OrderInvoices";
//Orders Cards
import OrdersCards from "../components/Orders/OrdersCards";
import OrderCardSkeleton from "../components/Orders/OrderCardSkeleton";
import { useHistory } from "react-router";
import SnackbarMessage from "../components/SnackbarMessage";

//Styles needed for resposives view
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    // top: '50%',
    // left: '20%',
    marginTop: 5,
    marginLeft: 5,
  },
}));

//We need forwardRef in order to expose ref to getOrdersInvoicesByUser
const OrdersInvoices = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const [orders_invoices, setOrdersInvoices] = useState([]);
  const [ordersList, setOrders] = useState([]);
  const [offset, setOffset] = useState(0);
  const [lastpage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [acumatica_enabled, setAcumaticaEnabled] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [filter_by, setFilterBy] = useState("all");
  const [clientFilter, setClientFilter] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    start: null,
    end: null,
  });
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**Loading initial values*/
  //We need use useImperativeHandle in order to expose ref to getOrderInvoices for data refreshing
  useImperativeHandle(ref, () => ({
    refreshData() {
      //Orders Invoices list
      getOrderInvoices();
    },
  }));

  /*Infinite scroll*/
  const observer = useRef();
  //Create a ref to the last element of the list
  const lastElementRef = useCallback((node) => {
    if (lastpage) return;
    if (observer.current) {
      //disconect the observer to set the new last element
      observer.current.disconnect();
    }
    //set the new observer to the last element
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        //get role
        const role = localStorage.getItem("role");
        //get filter
        const filter =
          role == "reselleradmin" || role == "superadmin"
            ? { reseller_id: localStorage.getItem("reseller_id") }
            : role == "clientadmin" ||
              role == "clientorderapprover" ||
              role == "clientproductmanager" ||
              role == "clientusermanager"
            ? { client_id: localStorage.getItem("client_id") }
            : null;
        // Check if trying to get orders no synced with Acumatica
        let synced_orders = filter_by === "not_synced";
        getLimitOrderInvoiceById(
          null,
          offset,
          filter,
          clientFilter,
          dateFilter,
          synced_orders
        )
          .then((result) => {
            let newOrderInvoicesList = orders_invoices.concat(
              result.data.orderInvoices
            );
            setOrdersInvoices(newOrderInvoicesList);
            setOffset(offset + 20);
            if (result.data.orderInvoices.length < 20) setLastPage(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    if (node) observer.current.observe(node);
  });

  //List orders at the first time
  useEffect(() => {
    //set from_store
    localStorage.setItem("from_store", "OrderHistory");
    //Check if the seected client filter is part of the selected reseller
    let selectedInvoiceClient = localStorage.getItem("InvoiceClient");
    const checkUsername = (client) => client.id === selectedInvoiceClient;

    if (selectedInvoiceClient && props.clients.some(checkUsername)) {
      setClientFilter(selectedInvoiceClient);
    } else {
      localStorage.removeItem("InvoiceClient");
      //Lists orders
      getOrderInvoices();
    }
  }, []);

  const getOrderInvoices = () => {
    //get role
    const role = localStorage.getItem("role");
    //get filter
    const filter =
      role == "reselleradmin" || role == "superadmin"
        ? { reseller_id: localStorage.getItem("reseller_id") }
        : role == "clientadmin" ||
          role == "clientorderapprover" ||
          role == "clientproductmanager" ||
          role == "clientusermanager"
        ? { client_id: localStorage.getItem("client_id") }
        : null;
    // Check if trying to get orders no synced with Acumatica
    let synced_orders = filter_by === "not_synced";
    //Reset OrdersList from 0 to 20
    getLimitOrderInvoiceById(
      null,
      0,
      filter,
      clientFilter,
      dateFilter,
      synced_orders
    ).then((result) => {
      setOrdersInvoices(result.data.orderInvoices);
      setOffset(20);
      if ("client" in result.data) {
        setAcumaticaEnabled(result.data.client.acumatica ? true : false);
      }
      setLoading(false);
    });
  };

  const syncOrdersWithAcumatica = () => {
    setSending(true);
    let params = {
      client_id: clientFilter,
    };
    sendOrdersToAcumatica(params)
      .then((response) => {
        if (response.data.sendOrdersToAcumatica) {
          getOrderInvoices();
          setSending(false);
          setError({
            open: true,
            message: "Orders successfully synced",
            severity: "success",
          });
        } else {
          let error_message = response.errors
            ? response.errors[0].message
            : "An error occurred";
          // Display error
          setError({
            open: true,
            message: `${error_message}. Please try again later.`,
            severity: "error",
          });
        }
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: `An error occurred`,
          severity: "error",
        });
      })
      .then(() => {
        // Reload orders
        getOrderInvoices();
        // Change loading flag
        setSending(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    setLastPage(false);
    getOrderInvoices();
  }, [clientFilter, filter_by, dateFilter]);

  const handleDateChange = (date, position) => {
    let newDate = { ...dateFilter };
    let formatedDate = position == "start" ? startOfDay(date) : endOfDay(date);
    newDate[position] = date !== null ? formatedDate : date;
    setDateFilter(newDate);
  };
  return (
    <Container
      style={{ marginTop: "85px" }}
      maxWidth="xl"
      className={classes.mainContainer}
    >
      <Grid container spacing={3} style={{ padding: "20px" }} id="second_grid">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Order Invoice History
          </Typography>
        </Grid>
        {localStorage.getItem("role") == "reselleradmin" ||
        localStorage.getItem("role") == "superadmin" ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} lg={4}>
              <FormControl id="ControlType" fullWidth>
                <InputLabel id="ControlType">Filter by client</InputLabel>
                <Select
                  labelId="ControlType"
                  id="ControlType"
                  name="ControlType"
                  value={clientFilter}
                  onChange={(e) => {
                    if (e.target.value !== 0)
                      localStorage.setItem("InvoiceClient", e.target.value);
                    else localStorage.removeItem("InvoiceClient");
                    setLastPage(false);
                    setClientFilter(e.target.value);
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {props.clients.map((client, index) => [
                    <MenuItem key={index} value={client.id}>
                      {client.name}
                    </MenuItem>,
                  ])}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <FormControl id="form_control_filter_by" fullWidth>
                <InputLabel id="input_label_filter_by">Filter by</InputLabel>
                <Select
                  labelId="input_label_filter_by"
                  id="select_filter_by"
                  name="select_filter_by"
                  value={filter_by}
                  onChange={function (e) {
                    setFilterBy(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="not_synced">
                    Not synced with Acumatica
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        ) : (
          <Hidden lgDown>
            <Grid lg={4}></Grid>
          </Hidden>
        )}
        <Hidden lgDown>
          <Grid lg={4}></Grid>
        </Hidden>
        <Grid item xs={12} sm={12} lg={4}>
          <Autocomplete
            autoComplete
            autoHighlight
            clearOnEscape
            freeSolo
            style={{ marginLeft: "auto" }}
            id="search"
            disableClearable
            clearOnBlur
            filterOptions={(options) => options}
            options={ordersList}
            getOptionLabel={(option) => option.search_label}
            onChange={(event, value) => {
              if (event.key === "Enter" && !value.id) {
                event.preventDefault();
                return false;
              } else {
                history.push(`/app/orders/${value.id}`);
              }
            }}
            loading={loading_search}
            onInputChange={(event, value) => {
              if (value.length > 2) {
                setLoadingSearch(true);
                //search by search term
                searchOrdersInvoices({
                  search_term: value,
                  user_id: null,
                  role: localStorage.getItem("role"),
                }).then((results) => {
                  if (results.data) {
                    setLoadingSearch(false);
                    setOrders(results.data.orderInvoices);
                  }
                });
              }
            }}
            onClose={() => {
              setOrders([]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search orders"
                margin="none"
                InputProps={{
                  ...params.InputProps,
                  type: "search",

                  endAdornment: (
                    <InputAdornment position="end">
                      {loading_search ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              style={{ width: "100%" }}
              label="Start Date"
              format="MM/dd/yyyy"
              clearable
              disableFuture
              maxDate={dateFilter.end ? dateFilter.end : new Date("2100-01-01")}
              value={dateFilter.start}
              onChange={(date) => handleDateChange(date, "start")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              style={{ width: "100%" }}
              label="End Date"
              format="MM/dd/yyyy"
              clearable
              disableFuture
              minDate={
                dateFilter.start ? dateFilter.start : new Date("1900-01-01")
              }
              value={dateFilter.end}
              onChange={(date) => handleDateChange(date, "end")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {clientFilter != 0 && filter_by === "not_synced" ? (
          <Grid item xs={12} sm={12} lg={4}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !orders_invoices.length ||
                loading ||
                sending ||
                !acumatica_enabled
              }
              onClick={() => syncOrdersWithAcumatica()}
            >
              Send to Acumatica
            </Button>
            {sending && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Grid>
        ) : null}
        {/* Loading Cards*/}
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {loading ? (
            [1, 2, 3, 4].map((index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <OrderCardSkeleton />
                </Grid>
              );
            })
          ) : orders_invoices.length < 1 ? (
            <Grid item xs={12}>
              <Typography variant={"h5"}>
                No orders for this reseller
              </Typography>
            </Grid>
          ) : (
            orders_invoices.map((order, index) => {
              if (orders_invoices.length == index + 1) {
                //Put a ref on the last element of the list
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    ref={lastElementRef}
                  >
                    <OrdersCards
                      order={order}
                      key={order.id}
                      //handleClickOpen={() => handleClickOpen(order.id)}
                    />
                  </Grid>
                );
              } else {
                //render normal element
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <OrdersCards
                      order={order}
                      key={order.id}
                      //handleclickOpen={() => handleClickOpen(order.id)}
                    />
                  </Grid>
                );
              }
            })
          )}
        </Grid>
      </Grid>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
});

OrdersInvoices.displayName = "OrdersInvoices";

OrdersInvoices.propTypes = {
  clients: PropTypes.array,
};

export default OrdersInvoices;
