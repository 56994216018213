import DashboardLayout from "./components/DashboardLayout";
//import ProductList from "./pages/ProductList";
import UserList from "./pages/UserList";
import ProductForm from "./components/products/ProductForm";
import Login from "./pages/Login";
import ClientProducts from "./pages/ClientProducts";
import ClientApprovals from "./pages/ClientApprovals";
import ClientOverview from "./pages/ClientOverwiew";
import StoreLayout from "./components/store/StoreLayout";
import StoreProducts from "./pages/StoreProducts";
import ProductAttributes from "./pages/ProductAttributes";
import ProductAttributeDetails from "./pages/ProductAttributeDetails";
import Checkout from "./pages/Checkout";
import OrderDetails from "./pages/OrderDetails";
import UsersPointsManagement from "./pages/UsersPointsManagement";
import UserProfile from "./pages/UserProfile";
import AssignClientProducts from "./pages/AssignClientProducts";
import Reports from "./pages/Reports";
import OrdersInvoices from "./pages/OrdersInvoices";
import SetNewPassword from "./pages/SetNewPassword";
import ProductListTable from "./pages/ProductListTable";
import AttributeTemplateCardList from "./pages/AttributeTemplateCardList";
import AttributeTemplate from "./pages/AttributeTemplate";

const routes = [
  {
    path: "/app",
    component: DashboardLayout,
    routes: [
      {
        path: "/app/users",
        component: UserList,
        exact: true,
      },
      {
        path: "/app/client/:client/user/:id",
        component: UserProfile,
        exact: true,
      },
      {
        path: "/app/users-points",
        component: UsersPointsManagement,
        exact: true,
      },
      {
        path: "/app/products",
        //component: ProductList,
        component: ProductListTable,
        exact: true,
      },
      {
        path: "/app/add-product",
        component: ProductForm,
        exact: true,
      },
      {
        path: "/app/product/:id",
        component: ProductForm,
        exact: true,
      },
      {
        path: "/app/client/:client_id",
        component: ClientOverview,
        exact: true,
      },
      {
        path: "/app/client/:client_id/users",
        component: UserList,
        exact: true,
      },
      {
        path: "/app/client/:client_id/products",
        component: ClientProducts,
        exact: true,
      },
      {
        path: "/app/client/:client_id/approval",
        component: ClientApprovals,
        exact: true,
      },
      {
        path: "/app/client/:client_id/products/assign",
        component: AssignClientProducts,
        exact: true,
      },
      {
        path: "/app/client/:client_id/products/assign/:id",
        component: ProductForm,
        exact: true,
      },
      {
        path: "/app/client/:client_id/store/products",
        component: StoreProducts,
        exact: true,
      },
      {
        path: "/app/store/checkout",
        component: Checkout,
        exact: true,
      },
      {
        path: "/app/product-attributes",
        component: ProductAttributes,
        exact: true,
      },
      {
        path: "/app/product-attribute/:attribute_id",
        component: ProductAttributeDetails,
        exact: true,
      },
      {
        path: "/app/user",
        component: UserProfile,
        exact: true,
      },
      {
        path: "/app/user/:id",
        component: UserProfile,
        exact: true,
      },
      {
        path: "/app/client/:client_id/store/products/user/:id",
        component: UserProfile,
        exact: true,
      },
      {
        path: "/app/orders",
        component: OrdersInvoices,
        exact: true,
      },
      {
        path: "/app/orders/:order_id",
        component: OrderDetails,
        exact: true,
      },
      {
        path: "/app/reports",
        component: Reports,
        exact: true,
      },
      {
        path: "/app/attributes/templates",
        component: AttributeTemplateCardList,
        exact: true,
      },
      {
        path: "/app/attributes/template/new",
        component: AttributeTemplate,
        exact: true,
      },
      {
        path: "/app/attributes/template/:id",
        component: AttributeTemplate,
        exact: true,
      },
    ],
  },
  {
    path: "/store",
    component: StoreLayout,
    routes: [
      {
        path: "/store/products",
        component: StoreProducts,
        exact: true,
      },
      {
        path: "/store/orders/:order_id",
        component: OrderDetails,
        exact: true,
      },
      {
        path: "/store/checkout",
        component: Checkout,
        exact: true,
      },
      {
        path: "/store/orders",
        component: OrdersInvoices,
        exact: true,
      },
      {
        path: "/store/user",
        component: UserProfile,
        exact: true,
      },
    ],
  },
  {
    path: "/reset-password",
    component: SetNewPassword,
  },
  {
    path: "/",
    component: Login,
  },
];

export default routes;
