import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { searchClientApprovalGroupUsers } from "../../api/Users";
import { useParams } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";
import { createApprovalGroup, updateApprovalGroup } from "../../api/Clients";
import SearchIcon from "@material-ui/icons/Search";

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup
    .string("Approval Group Name")

    .required("Approval group name is required")
    .test(
      "not empty",
      "Approval Group must contain characters",
      function (value) {
        return /\S/.test(value);
      }
    ),
});

function ApprovalGroupModal(props) {
  const [initial_users, setInitialUsers] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({ Name: "" });
  const [saving, setSaving] = useState(false);
  const role = localStorage.getItem("role");
  let { client_id } = useParams();
  const [searchTerms, setSearchTerms] = React.useState("");
  const [searching, setSearching] = useState(false);

  // Formik hook
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSaving(true);
      let user_ids = [];
      selectedUsers.forEach((user) => {
        user_ids.push(user.id);
      });
      if (props.selectedApprovalGroup.id) {
        updateApprovalGroup(
          props.selectedApprovalGroup.id,
          client_id,
          values.Name,
          user_ids
        ).then((res) => {
          if (res) {
            setSelectedUsers([]);
            setInitialValues({ Name: "" });
            props.reloadClient();
            props.handleClose();
            setSaving(false);
          }
        });
      } else {
        createApprovalGroup(client_id, values.Name, user_ids).then((res) => {
          if (res) {
            setSelectedUsers([]);
            setInitialValues({ Name: "" });
            props.reloadClient();
            props.handleClose();
            setSaving(false);
          }
        });
      }
    },
  });

  //Use Effect to trigger after the search input changes
  React.useEffect(() => {
    setSearching(true);
    let timer1 = setTimeout(() => {
      // Get users list
      searchClientApprovalGroupUsers(client_id, searchTerms)
        .then((result) => {
          if (result && result.data && result.data.users) {
            let users = result.data.users;
            setUsers(users);
            if (initial_users === null) {
              setInitialUsers(users);
            }
            setSearching(false);
          }
        })
        .catch((error) => {
          console.log("searchClientApprovalGroupUsers -----> ", error);
        });
    }, 500);
    // Cleanup function
    return () => {
      clearTimeout(timer1);
    };
  }, [searchTerms]);

  useEffect(() => {
    formik.resetForm({ values: { Name: "" } });
    if (props.selectedApprovalGroup.id) {
      setInitialValues({ Name: props.selectedApprovalGroup.Name });
      props.selectedApprovalGroup.users.forEach((user) => {
        user.name = user.Details
          ? user.Details.FirstName + " " + user.Details.LastName
          : user.username;
      });
      setSelectedUsers([...props.selectedApprovalGroup.users]);
    } else {
      setInitialValues({ Name: "" });
      setSelectedUsers([]);
    }
  }, [props.open]);

  const unselectUser = (user) => {
    for (let index = 0; index < selectedUsers.length; index++) {
      if (selectedUsers[index].id == user.id) {
        selectedUsers.splice(index, 1);
      }
    }
    setSelectedUsers([...selectedUsers]);
  };

  const selectUsers = (user) => {
    let alreadyInList = false;
    selectedUsers.forEach((selected) => {
      if (selected.id == user.id) alreadyInList = true;
    });
    if (!alreadyInList) setSelectedUsers([...selectedUsers, user]);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id="alert-dialog-title">Approval Groups</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="Name"
              margin="dense"
              label="Name"
              variant="outlined"
              value={formik.values.Name}
              onChange={formik.handleChange}
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={formik.touched.Name && formik.errors.Name}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              style={{ marginLeft: "auto" }}
              id="search"
              disableClearable
              options={searching ? [] : users}
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.search_label}
              autoHighlight
              loading={searching}
              onChange={(event, value) => {
                if (event.key === "Enter" && !value.id) {
                  event.preventDefault();
                  return false;
                } else {
                  value ? selectUsers(value) : null;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users"
                  margin="none"
                  onChange={(e) => {
                    setSearchTerms(e.target.value);
                  }}
                  placeholder="Search users"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedUsers.map((user, index) => {
              return role == "superadmin" && role == "reselleradmin" ? (
                <Chip
                  style={{ margin: "2px", maxWidth: "100%" }}
                  key={index}
                  label={user.name}
                  onDelete={() => unselectUser(user)}
                />
              ) : role !== "superadmin" &&
                role !== "reselleradmin" &&
                user.test == true ? (
                ""
              ) : (
                <Chip
                  style={{ margin: "2px", maxWidth: "100%" }}
                  key={index}
                  label={user.name}
                  onDelete={() => unselectUser(user)}
                />
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        <Button
          variant={"contained"}
          color={"default"}
          size={"small"}
          onClick={() => {
            props.handleClose();
            setUsers(initial_users);
          }}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={formik.handleSubmit}
          disabled={saving}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApprovalGroupModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reloadClient: PropTypes.reloadClient,
  selectedApprovalGroup: PropTypes.object,
};

export default ApprovalGroupModal;
