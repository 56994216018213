import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createProductAttributeValue,
  updateProductAttributeValue,
} from "../../api/Products";
import SnackbarMessage from "../SnackbarMessage";
//import PropTypes from "prop-types";

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup.string("Name").required("Name is required"),
  DisplayOrder: yup.string("Display Order"),
  CostAdjustment: yup.string("Cost Adjustment"),
  SkuExtension: yup.string("Sku Extension"),
});

export default function ProductAttributeValuesModal(props) {
  const [initialValues, setInitialValues] = useState({
    Name: "",
    DisplayOrder: "",
    CostAdjustment: "",
    SkuExtension: "",
  });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });

  /**
   * Close snackbar
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };

  useEffect(() => {
    formik.resetForm();
    if (props.selectedValue.id) {
      setInitialValues({
        id: props.selectedValue.id,
        Name: props.selectedValue.Name,
        DisplayOrder: props.selectedValue.DisplayOrder,
        CostAdjustment: props.selectedValue.CostAdjustment,
        SkuExtension: props.selectedValue.SkuExtension,
      });
    } else {
      setInitialValues({
        Name: "",
        DisplayOrder: "",
        CostAdjustment: "",
        SkuExtension: "",
      });
    }
    if (props.selectedValue.position != undefined) {
      setInitialValues({
        id: props.selectedValue.id,
        Name: props.selectedValue.Name,
        DisplayOrder: props.selectedValue.DisplayOrder,
        CostAdjustment: props.selectedValue.CostAdjustment,
        SkuExtension: props.selectedValue.SkuExtension,
        position: props.selectedValue.position,
        positionValue: props.selectedValue.positionValue,
      });
    }
  }, [props.modalOpen]);

  /***Formik initialization */
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.selectedValue.position != undefined) {
        values.position = props.selectedValue.position;
        // A value of 0 is given when the field is sent empty.
        if (values.CostAdjustment == "") {
          values.CostAdjustment = 0;
        }
        if (values.DisplayOrder == "") {
          values.DisplayOrder = 0;
        }
        // Detects when the value is negative
        let menssage = `Some fields are incorrect.`;
        if (values.DisplayOrder || values.CostAdjustment < 0) {
          setError({
            open: true,
            message: menssage,
            severity: "error",
          });
          return;
        }
        props.recoverDataAttributeValues(values);
        return;
      }
      setSaving(true);

      if (props.selectedValue.id) {
        updateProductAttributeValue(props.selectedValue.id, values).then(
          (res) => {
            setSaving(false);
            if (res.data.updateProductAttributeValue) {
              props.toggleModal(true);
              props.successUpdateAttribute();
            }
          }
        );
      } else {
        createProductAttributeValue(props.atribute_id, values).then((res) => {
          setSaving(false);
          if (res.data?.createProductAttributeValue) {
            props.toggleModal(true);
            props.successAddAttribute();
          }
        });
      }
    },
  });
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        Product Attribute Values
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Attribute name */}
          <Grid item xs={12}>
            <TextField
              size="small"
              id="Name"
              margin="dense"
              label="Name"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.Name}
              fullWidth
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={formik.touched.Name && formik.errors.Name}
            />
          </Grid>
          {/* Display text */}
          <Grid item xs={12}>
            <TextField
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              size="small"
              fullWidth
              id="DisplayOrder"
              name="DisplayOrder"
              margin="dense"
              label="Display Order"
              variant="outlined"
              value={formik.values.DisplayOrder}
              onChange={formik.handleChange}
              error={
                formik.touched.DisplayOrder &&
                Boolean(formik.errors.DisplayOrder)
              }
              helperText={
                formik.touched.DisplayOrder && formik.errors.DisplayOrder
              }
            />
          </Grid>
          {/*Control type select */}
          <Grid item xs={12}>
            <TextField
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              size="small"
              fullWidth
              id="CostAdjustment"
              name="CostAdjustment"
              margin="dense"
              label="Cost Adjustment"
              variant="outlined"
              value={formik.values.CostAdjustment}
              onChange={formik.handleChange}
              error={
                formik.touched.CostAdjustment &&
                Boolean(formik.errors.CostAdjustment)
              }
              helperText={
                formik.touched.CostAdjustment && formik.errors.CostAdjustment
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="SkuExtension"
              margin="dense"
              label="SkuExtension"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.SkuExtension}
              fullWidth
              error={
                formik.touched.SkuExtension &&
                Boolean(formik.errors.SkuExtension)
              }
              helperText={
                formik.touched.SkuExtension && formik.errors.SkuExtension
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: " 8px 24px" }}>
        <Button onClick={props.toggleModal} variant="contained">
          Close
        </Button>
        <Button
          onClick={formik.handleSubmit}
          type="submit"
          color="primary"
          variant="contained"
          disabled={saving}
        >
          Save Changes
        </Button>
      </DialogActions>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

ProductAttributeValuesModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  successAddAttribute: PropTypes.func,
  successUpdateAttribute: PropTypes.func,
  atribute_id: PropTypes.number,
  selectedValue: PropTypes.object,
  recoverDataAttributeValues: PropTypes.func,
};
