// Libraries
import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";

//MUI
import {
  TextField,
  Link,
  Grid,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";

//API
import { forgotPassword } from "../../api/Users";

/**
 * Main function component
 * @param {object} param0 Component props
 * @returns <form>
 */
export default function ResetPassword({ classes, setView, setError }) {
  const [loading, setLoading] = React.useState(false);
  //  Formik validation schema
  const validationSchema = yup.object({
    email: yup.string("Enter your email").required("Email is required"),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      forgotPassword(values.email).then((res) => {
        if (res.data.forgotPassword && res.data.forgotPassword.ok) {
          setError({
            open: true,
            message: "Forgot password email sent, please check your inbox",
            severity: "success",
          });
          formik.resetForm();
        } else {
          setError({
            open: true,
            message: "Error: the email doesn't exists.",
            severity: "error",
          });
        }
      }).catch(() => {
        setError({
          open: true,
          message: "Error while requesting forgotPassword",
          severity: "error",
        });
      }).then(() => {
        setLoading(false);
      });
    },
  });

  return (
    // <Grid container justify="center">
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Typography variant="h6" align="center">Forgot Password</Typography>
        <Typography variant="subtitle2" align="center">
          Enter your email to restore password
        </Typography>
        <TextField
          margin="normal"
          size="small"
          type="email"
          fullWidth
          id="email"
          name="email"
          label="Email"
          placeholder="Email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            color="primary"
            endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            loadingPosition="end"
            variant="contained"
            className={classes.submit}
            disabled={loading}
          >
            Restore Password
          </Button>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Link href="#" variant="body2" onClick={() => setView("login")}>
            Login
          </Link>
        </Grid>
      </form>
    // </Grid>
  );
}

ResetPassword.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object,
  loading: PropTypes.bool,
  setView: PropTypes.func,
  setError: PropTypes.func,
};
