import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import { makeStyles } from "@material-ui/core/styles";

import { ButtonBase, Divider, Grid } from "@material-ui/core";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  imageContainer: {
    height: "60px",
    width: "60px",
  },
  elementContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "1px solid #e0e0e0",
  },
}));

export default function ImagesModal(props) {
  const classes = useStyles();

  const [dragId, setDragId] = useState();

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = props.images.find((box) => box.tmpId === dragId);
    const dropBox = props.images.find(
      (box) => box.tmpId === ev.currentTarget.id
    );

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = props.images.map((box) => {
      if (box.tmpId === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.tmpId === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    props.setImages(newBoxState);
  };

  useEffect(() => {
    let localOrder = 1;
    props.images.forEach((image) => {
      image.tmpId = image.id
        ? image.id
        : image.tmpId
        ? image.tmpId
        : localOrder.toString();
      image.order = image.order ? image.order : localOrder;
      localOrder = localOrder + 1;
    });
    props.setImages(props.images);
  }, [props.modalOpen]);

  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggleModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Product Images</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/*Product Images*/}
          <Grid xs={12} container spacing={2} className={classes.paper}>
            <Grid item xs={2}>
              #
            </Grid>
            <Grid item xs={8}>
              Image
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {props.images
            .sort((a, b) => a.order - b.order)
            .map((pic, index) => {
              return (
                <Grid
                  draggable={true}
                  id={pic.tmpId}
                  onDragOver={(ev) => ev.preventDefault()}
                  onDragStart={handleDrag}
                  onDrop={handleDrop}
                  container
                  xs={12}
                  className={classes.elementContainer}
                  style={{ height: "120px" }}
                  key={pic.tmpId}
                  spacing={3}
                >
                  <Grid item xs={2}>
                    {pic.order}
                  </Grid>
                  <Grid item xs={8}>
                    <ButtonBase
                      className={classes.imageContainer}
                      style={{
                        backgroundImage: `url(${
                          pic.url ? pic.url : "/img/avid/avid-io_250x266.png"
                        })`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    ></ButtonBase>
                  </Grid>
                  <Grid item xs={1} style={{ alignSelf: "flex-end" }}>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        props.toggleDeleteImage(index);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1} style={{ alignSelf: "flex-end" }}>
                    <DragHandleIcon size="large" />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: " 8px 24px" }}>
        <Button onClick={props.toggleModal} variant="contained">
          Close
        </Button>
        <Button component="label" color="primary" disabled={props.saving}>
          <input
            type="file"
            name="image"
            accept="image/*"
            hidden
            onChange={(event) => {
              let imagesArray = [...props.images];
              imagesArray.unshift({
                files: event.target.files[0],
                url: URL.createObjectURL(event.target.files[0]),
              });
              //iterate through images to set the new order
              let localOrder = imagesArray.length;

              imagesArray.forEach((image) => {
                image.tmpId = image.id
                  ? image.id
                  : image.tmpId
                  ? image.tmpId
                  : localOrder.toString();
                image.order = image.order ? image.order : localOrder;
              });
              props.setImages(imagesArray);
              props.setUploadCount(props.uploadCount + 1);
            }}
          />
          Upload Image
        </Button>
        <Button
          onClick={() => props.formik.handleSubmit()}
          color="primary"
          variant="contained"
          disabled={props.saving}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ImagesModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  images: PropTypes.array,
  setImages: PropTypes.func,
  setUploadCount: PropTypes.func,
  uploadCount: PropTypes.number,
  formik: PropTypes.object,
  toggleDeleteImage: PropTypes.func,
  saving: PropTypes.bool,
};
