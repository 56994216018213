import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

export const listStagedInvoicesFromUser = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let store_user = JSON.parse(localStorage.getItem("store_user"));
  let user =
    store_user && store_user.id ? store_user.id : localStorage.getItem("user");
  // let whereAttributes = store_user
  //   ? `(where: { client: ${store_user.client_id} })`
  //   : `(where: { client: ${localStorage.getItem("client_id")} })`;
  // console.log(whereAttributes);
  axiosOptions.data = {
    query: `
      query{
        userStagedInvoice(create_user: ${user}){
          id
          InvoiceId
          order_invoice{
            id
          }
          staged_line_items{
            id
            product{
              id
              Name
              Image{
                url
              }
              Price
              Sku
              Description
              Summary
              product_product_attributes {
                id
                required
                client {
                  id
                }
                product_attribute {
                  id
                  Name
                  DisplayText
                  product_attribute_values {
                    Name
                    id
                    CostAdjustment
                  }
                  ControlType
                }
              }
              product_attributes{
                id
                Name
                ControlType
                DisplayText
                product_attribute_values{
                  id
                  CostAdjustment
                  Name
                }
              }
              division_categories{
                Name
              }
              divisions:product_division_categories{
                id
                division:division_category{
                  id
                  Name
                }
              }
            }
            LineItemJson
            AttributeDescription
            Quantity
            ProductSku
            division_category{
              id
              Name
              ordering_mode
            }
            Price
            PointsUsed
            Cost
            AvailablePoints
          }
          remaining{
            DivisionCategoryName
            RemainigPoints
          }
          total_price
          point_cost_adjustment
          card_required
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    let result = response.data.data.userStagedInvoice;
    return result != null
      ? result.order_invoice == null
        ? result
        : null
      : result;
  } catch (error) {
    return error;
  }
};
