// Libraries
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { Grid, makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";

// Custom components
import SnackbarMessage from "../components/SnackbarMessage";
import ShoppingCart from "../components/checkout/ShoppingCart";
import ShippingDetails from "../components/checkout/ShippingDetails";
import CreditCardDetails from "../components/checkout/CreditCardDetails";

// API
import { listStagedInvoicesFromUser } from "../api/StagedInvoices";
import { createAddress } from "../api/Addresses";
import { createUserCustomer } from "../api/Customers";
import { createOrderInvoice } from "../api/OrderInvoices";
import { listClientsFromReseller, listClientsFromUser } from "../api/Clients";
import { listUserCustomers } from "../api/Customers";
import { checkTestingUser } from "../api/Users";

// Styles
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  papers: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      padding: "20px",
      width: "100%",
      height: "100%",
    },
  },
}));

// Main component
const Checkout = ({ getUserCart }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [staged_invoice, setStagedInvoice] = React.useState(null);
  const [saving, setSaving] = useState(false);
  const [loading_addresses, setLoadingAddresses] = useState(true);
  const [card_required, setCardRequired] = useState(false);
  const [client, setClient] = React.useState({
    logo: "",
    mode: "",
    custom_addresses: null,
  });
  const [customAddresses, setCustomAddresses] = useState(true);
  const [customers, setCustomers] = useState([]);
  // Get staged_invoice
  const getUserStagedInvoice = () => {
    //check if the selected user is not the logged user
    let store_user = JSON.parse(localStorage.getItem("store_user"));
    let user_id =
      store_user && store_user.id
        ? store_user.id
        : localStorage.getItem("user");
    return listStagedInvoicesFromUser(user_id);
  };

  // List user staged_invoice at the first load
  React.useEffect(() => {
    GetUserStagedInvoice();
  }, []);

  /**
   * List user staged_invoice at the first load
   */
  const GetUserStagedInvoice = () => {
    // Data
    const user_id = localStorage.getItem("user");
    const reseller_id = localStorage.getItem("reseller_id");
    const user_store = JSON.parse(localStorage.getItem("store_user"));
    const client_id = localStorage.getItem("client_id");
    const role = localStorage.getItem("role");
    //params to filter
    let params = {
      reseller_id: reseller_id,
    };
    //add client if exist
    user_store
      ? user_store.client_id
        ? (params.client_id = user_store.client_id)
        : client_id
        ? (params.client_id = client_id)
        : ""
      : client_id
      ? (params.client_id = client_id)
      : "";
    //User of the session or user of the store
    const userIdToSearch = user_store ? user_store.id : user_id;
    //Get division category id from user store or user id from session
    user_store
      ? user_store.division_categories.length
        ? (params.divCat = user_store.division_categories[0].id)
        : (params.user_id = userIdToSearch)
      : (params.user_id = userIdToSearch);
    // List staged_invoice
    getUserStagedInvoice()
      .then((result) => {
        const requiredcard =
          result && result.card_required ? result.card_required : false;
        setStagedInvoice(result);
        //Test user
        checkTestingUser(userIdToSearch).then((IsTestUser) => {
          /**
           * For superadmin and reselleradmin users,
           * I use the Listclientfromreseller,
           * because these may not be associated with a client
           */
          (IsTestUser || role == "superadmin" || role == "reselleradmin"
            ? listClientsFromReseller(client_id)
            : listClientsFromUser(params)
          )
            .then((xhr) => {
              if (
                xhr &&
                xhr.data &&
                xhr.data.clients &&
                xhr.data.clients.length > 0
              ) {
                //Validate custom addresses
                const client = xhr.data.clients[0];
                const customAddresses = client.custom_addresses;
                UsersCustomers(customAddresses ? true : false, client.id);
                // Get client_image_url
                let client_logo = client.client_image.url;
                // Get client mode
                let client_mode = client.mode ? client.mode : "Default";
                setClient({
                  logo: client_logo,
                  mode: client_mode,
                  custom_addresses: customAddresses,
                });
                setCardRequired(requiredcard);
              }
            })
            .catch((xhr) => {
              console.log(xhr);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get Custmer's list
  const UsersCustomers = async (customAddresses, clientUser) => {
    setLoadingAddresses(true);
    //Lists customers from client
    let customers_client = [];
    if (!customAddresses) {
      await listUserCustomers(null, clientUser)
        .then((result) => {
          if (result.data && result.data.customers) {
            if (result.data.customers.length > 0) {
              customers_client = result.data.customers;
            } else {
              customers_client = [];
            }
            setCustomers(customers_client);
            setShowForm(customers_client == 0);
            setCustomAddresses(customAddresses);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } else {
      //get store user
      const user_store = JSON.parse(localStorage.getItem("store_user"));
      //user id
      const user_id = localStorage.getItem("user");
      // List customers from user
      await listUserCustomers(
        user_store && user_store.id ? user_store.id : user_id
      )
        .then((result) => {
          if (result.data && result.data.customers) {
            let customers_user;
            if (result.data.customers.length > 0) {
              customers_user = result.data.customers;
            } else {
              customers_user = [];
            }
            //Set all customers
            let all_users;
            if (customers_user.length > 0) {
              all_users = customers_client.concat(customers_user);
            } else {
              all_users = customers_client;
            }
            setCustomers(all_users);
            setShowForm(all_users == 0);
            setCustomAddresses(customAddresses);
            //setAddresses(all_users);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // Previous request have an await, before them set loading_addresses to false
    setLoadingAddresses(false);
  };

  // Manage errors
  const [error, setError] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  // Close snackbar
  const closeSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  // Show/hide shipping details form
  const [show_form, setShowForm] = React.useState(false);
  // Flag to know if canada is selected or no
  const [canadaSelected, setCanadaSelected] = useState(false);

  // Initial form data
  const initial_values = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    second_address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "US",
    credit_card_number: "",
    expiration_year: "",
    expiration_month: "",
    security_code: "",
    customer: "",
  };

  // Use this regex for US
  const usRegEx = /^[0-9A-Z]{5}$/;
  // Use this regex when Canada is selected (taken from Acumatica > Configuration > Countries/States > Canada)
  const canadaRegEx =
    /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
  // Formik validation schema
  const validation_schema = yup.object({
    first_name: show_form
      ? yup.string("First Name").required("First Name is required")
      : yup.string("First Name"),
    last_name: show_form
      ? yup.string("Last Name").required("Last Name is required")
      : yup.string("Last Name"),
    email: show_form
      ? yup
          .string("Confirmation Email")
          .email("Invalid email address")
          .required("Confirmation Email is required")
      : yup.string("Confirmation Email").email("Invalid email address"),
    phone: show_form
      ? yup
          .string("Phone")
          .min(10, "Phone must be 10 digits")
          .max(10, "Phone must be 10 digits")
          .required("Phone is required")
      : yup.string("Phone"),
    address: show_form
      ? yup.string("Address").required("Address is required")
      : yup.string("Address"),
    second_address: yup.string("Address 2").optional(),
    city: show_form
      ? yup.string("City").required("City is required")
      : yup.string("City"),
    state: show_form
      ? yup
          .string("State")
          .required(`${canadaSelected ? "Province" : "State"} is required`)
      : yup.string("State"),
    postal_code: show_form
      ? yup
          .string("Postal Code")
          .test(
            "maxLenght",
            `Postal Code must be ${
              canadaSelected ? "6 characters" : "5 digits"
            }`,
            (value) => {
              if (value) {
                const postalCodeMaxLenght = canadaSelected ? 6 : 5;
                return value.replaceAll(" ", "").length === postalCodeMaxLenght;
              }
            }
          )
          .test("onlyNumbers", "Only numbers", (value) => {
            if (value) {
              return canadaSelected ? true : !isNaN(value);
            }
          })
          .matches(
            canadaSelected ? canadaRegEx : usRegEx,
            "Invalid Postal Code"
          )
          .required("Postal Code is required")
      : yup.string("Postal Code"),
    country: yup.string("Country").optional(),
    note: yup.string("Note").optional(),
    credit_card_number: card_required
      ? yup
          .number()
          .positive("Credit Card Number")
          .required("Credit Card Number is required")
      : yup.number().positive("Credit Card Number").optional(),
    expiration_year: card_required
      ? yup.string("Expiration Year").required("Expiration Year is required")
      : yup.string("Expiration Year").optional(),
    expiration_month: card_required
      ? yup.string("Expiration Month").required("Expiration Month is required")
      : yup.string("Expiration Month").optional(),
    security_code: card_required
      ? yup
          .number()
          .positive("Security Code")
          .required("Security Code is required")
      : yup.number().positive("Security Code").optional(),
    customer: show_form
      ? yup.number().optional()
      : yup.number().required("Shipping Details are required"),
  });

  const createOrder = async (order_values, creating_address = false) => {
    // Create order
    await createOrderInvoice(order_values)
      .then(async (xhr) => {
        if (xhr.data && xhr.data.orderInvoice) {
          const order_id = xhr.data.orderInvoice.id;
          // Go to one of the following pages:
          history.push(
            (localStorage.getItem("store_user") ||
              localStorage.getItem("client_id")) &&
              localStorage.getItem("role") != "customer"
              ? `/app/orders/${order_id}`
              : `/store/orders/${order_id}`
          );
        } else {
          if (creating_address) {
            await UsersCustomers(client.custom_addresses, client.id);
            let reset_values = {
              ...formik.values,
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              address: "",
              second_address: "",
              city: "",
              state: "",
              postal_code: "",
              country: "US",
              customer: order_values.customer_id,
            };
            formik.setValues(reset_values);
          }
          setSaving(false);
          setError({
            open: true,
            message: `Error creating order`,
            severity: "error",
          });
        }
      })
      .catch(() => {
        setSaving(false);
        setError({
          open: true,
          message: `Error creating order`,
          severity: "error",
        });
      });
  };

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validation_schema,
    onSubmit: (values) => {
      setSaving(true);
      // alert(JSON.stringify(values, null, 2));
      let order_values = {
        total: staged_invoice.total_price,
        note: values.note,
        staged_invoice_id: staged_invoice.id,
        credit_card_number: values.credit_card_number,
        expiration_year: values.expiration_year,
        expiration_month: values.expiration_month,
        security_code: values.security_code,
      };
      //check if the selected user is not the logged user
      //Save thwe user who is logged
      let user_id = localStorage.getItem("user");
      order_values.user_id = user_id;
      // Check if customer was selected
      if (values.customer) {
        order_values.customer_id = values.customer;
        // Start create order process with selected customer
        createOrder(order_values);
      } else {
        // Create address
        let address_values = {
          street: values.address,
          second_street: values.second_address,
          city: values.city,
          state: values.state,
          zip: values.postal_code,
          country: values.country,
        };
        createAddress(address_values)
          .then((xhr) => {
            if (xhr.data && xhr.data.createAddress) {
              let address_id = xhr.data.createAddress.address.id;
              // Create customer
              //Check if the store user is not the logged user
              let store_user = JSON.parse(localStorage.getItem("store_user"));

              let customer_values = {
                first_name: values.first_name,
                last_name: values.last_name,
                address_id: address_id,
                email: values.email,
                phone: values.phone,
                user_id:
                  store_user && store_user.id
                    ? store_user.id
                    : localStorage.getItem("user"),
              };
              createUserCustomer(customer_values)
                .then((xhr) => {
                  if (xhr.data && xhr.data.createCustomer) {
                    let customer_id = xhr.data.createCustomer.customer.id;
                    order_values.customer_id = customer_id;
                    // Start create order process with new customer
                    createOrder(order_values, true);
                  } else {
                    setSaving(false);
                    setError({
                      open: true,
                      message: `Error creating customer`,
                      severity: "error",
                    });
                  }
                })
                .catch(() => {
                  setSaving(false);
                  setError({
                    open: true,
                    message: `Error creating customer`,
                    severity: "error",
                  });
                });
            } else {
              setSaving(false);
              let error_message = xhr.errors
                ? xhr.errors[0].message
                : "Error creating address";
              setError({
                open: true,
                message: `Error: ${error_message}`,
                severity: "error",
              });
            }
          })
          .catch(() => {
            setSaving(false);
            setError({
              open: true,
              message: `Error creating address`,
              severity: "error",
            });
          });
      }
    },
  });
  // View
  return (
    // Container
    <Container
      id="main_container"
      maxWidth="lg"
      style={{ marginTop: "85px" }}
      className={
        location.pathname.includes("app") &&
        (localStorage.getItem("role") == "superadmin" ||
          localStorage.getItem("role") == "reselleradmin")
          ? classes.mainContainer
          : ""
      }
    >
      {/* Shopping Cart */}
      <Grid id="grid_shopping_cart" item xs={12} className={classes.papers}>
        <ShoppingCart
          staged_invoice={staged_invoice}
          getUserCart={(reload_invoice) => {
            getUserCart();
            if (reload_invoice) {
              GetUserStagedInvoice();
            }
          }}
        />
      </Grid>
      {staged_invoice &&
      staged_invoice.staged_line_items &&
      staged_invoice.staged_line_items.length > 0 ? (
        <form onSubmit={formik.handleSubmit} id="shipping_and_card_form">
          {/* Shipping Details */}
          <Grid id="grid_shipping" item xs={12} className={classes.papers}>
            <ShippingDetails
              formik={formik}
              show_form={show_form}
              setShowForm={setShowForm}
              customAddresses={customAddresses}
              customers={customers}
              loading_addresses={loading_addresses}
              setCanadaSelected={setCanadaSelected}
            />
          </Grid>
          {/* Credit Card Details */}
          <Grid id="grid_credit_card" item xs={12} className={classes.papers}>
            <CreditCardDetails
              formik={formik}
              saving={saving}
              card_required={card_required}
              customAddresses={customAddresses}
              addresses={customers}
            />
            {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              margin: "20px 8px 8px 8px",
            }}
          >
            Place Order
          </Button> */}
          </Grid>
        </form>
      ) : null}
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={closeSnackbar}
      />
    </Container>
  );
};

Checkout.propTypes = {
  getUserCart: PropTypes.func,
};

export default Checkout;
