import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * List all clients
 * @returns object
 */
export const listClients = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Set filter by reseller_id
  var where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
      query{
        clients(${where}){
          id
          name
          primary_color
          secondary_color
          subdomain
          mode
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get client by Subdomain
 * @param {*} values
 * @returns object
 */
export const getCllientbySubdomain = async (values) => {
  /*axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };*/
  var client_where = `where:{subdomain:["${values.subdomain}"]}`;
  var where_user = `where:{username:["${values.username}"]}`;
  axiosOptions.data = {
    query: `
    query{
      clients(${client_where}){
       id
        name
        subdomain
        users(${where_user}){
          id
          username
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List all clients from user
 * @returns object
 */
export const listClientsFromUser = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Set filter by reseller_id
  var where = `where:{
    ${values.user_id ? `users: [${values.user_id}]` : ""}, 
    ${values.reseller_id ? `reseller:[${values.reseller_id}]` : ""},
    ${values.divCat != null ? `division_categories:[${values.divCat}]` : ""}
    ${values.client_id ? `id:[${values.client_id}]` : ""}
  }`;
  axiosOptions.data = {
    query: `
      query{
        clients(${where}){
          id
          name
          mode
          primary_color
          secondary_color
          client_image{
            url
          }
          custom_addresses
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List all clients from reseller (client selector)
 * @returns object
 */
export const listClientsFromReseller = async (client_id = false) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Set filter by reseller_id
  var where = `where:{ 
    reseller: [${reseller_id}], 
    ${client_id ? `id:[${client_id}]` : ""}
  }`;
  axiosOptions.data = {
    query: `
      query{
        clients(${where}){
          id
          name
          mode
          primary_color
          secondary_color
          client_image{
            url
          }
          custom_addresses
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List limited products from single client
 * @param {int} client_id
 * @returns object
 */
export const limitListClientProducts = async (client_id, offset) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        query{
          client(id: ${client_id}) {
            products(limit:20, start:${offset}, sort:"Name", where:{active:${1}}){
              id,
              Name,
              Sku,
              Price,
              Published,
              Image{
                url
              }
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data.client;
  } catch (error) {
    return error;
  }
};

/**
 * Get client overview info
 * @param {int} client_id
 * @returns object
 */
export const getClientOverviewInfo = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query{
      client(id:${client_id}){
        name
        id
        mode
        subdomain
        primary_color
        secondary_color
        approval_groups{
          id
          Name
          users{
            id
            Details
            username
            test
          }
        }
        client_image{
          url
        }
        contact_user_ids{
          id
          username
          email
          Details
        }
        acumatica
        use_billing_department
        AcumaticaSetting
        custom_addresses
        AcumaticaResellerClientSettings
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data.client;
  } catch (error) {
    return error;
  }
};

/**
 * Create client view
 * @param {obj} client_data
 * @returns object
 */
export const postClientCreateview = async (client_data) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get current reseller
  const reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
    mutation{
      createClient(
        input:{
          data:{
            name:"${client_data.name}",
            mode: ${client_data.ordering_mode},
            subdomain:"${client_data.uniqueId}",
            primary_color:"${client_data.primary_color}",
            secondary_color:"${client_data.secondary_color}",
            reseller: ${reseller_id},
            ${
              client_data.client_image ===
              parseInt(client_data.client_image, 10)
                ? ", client_image:" + client_data.client_image
                : ""
            }
            AcumaticaResellerClientSettings: ${client_data.acumatica_setting}
          }
        }
      )
      {
        client{
          id
          name
          subdomain
          primary_color
          secondary_color
          client_image{
            url
          }
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update Client info and image
 * @param {*} values
 * @returns
 */
export const updateClient = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateClient(
            input:{
              where:{id:${values.id}},
              data:{
                name:"${values.name}",
                mode: ${values.ordering_mode},
                subdomain:"${values.subdomain}",
                primary_color:"${values.primary_color}",
                secondary_color:"${values.secondary_color}",
                ${
                  values.image === parseInt(values.image, 10)
                    ? ",client_image:" + values.image
                    : ""
                }
              }
            }
          )
          {
            client{
              id
              name
              subdomain
              primary_color
              secondary_color
              client_image{
                url
              }
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update Client custom addresses only
 * @param {*} values
 * @returns
 */
export const updateClientCustomAddresses = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateClient(
            input:{
              where:{id:${values.id}},
              data:{
                custom_addresses:${values.custom_addresses},
              }
            }
          )
          {
            client{
              id
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update client acumatica settings
 * @param {object} values
 * @returns {object}
 */
export const updateAcumaticaSettings = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateClient(
          input: {
            where: { id: ${values.id} }
            data: {
              acumatica: ${values.enabled_acumatica}
              use_billing_department: ${values.department} 
              AcumaticaSetting: {
                Location: "${values.location}"
                CustomerId: "${values.customer_id}"
                Company: "${values.company}"
                EndpointVersion: "${values.api_version}"
                Name: "${values.username}"
                Password: "${values.password}"
              }
              AcumaticaResellerClientSettings: ${values.acumatica_setting}
            }
          }
        ) {
          client {
            id
            name
            acumatica
            AcumaticaSetting
            use_billing_department
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Delete client
 * @param {int} id
 * @returns object
 */
export const deleteClient = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        deleteClient(
          input:{
            where:{id:${id}},
          }
        )
        {
          client{
            id
            name
            subdomain
            primary_color
            secondary_color
            client_image{
              url
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create division category
 * @param {obj} client_data
 * @returns object
 */
export const postDivisionCategory = async (data, client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      createDivisionCategory(
        input:{
          data:{
            Name:"${data.Name}"
            ordering_mode:${
              data.ordering_mode != "" ? data.ordering_mode : `null`
            }
            clients:[${client_id.client_id}]
          }
        }
      )
      {
        divisionCategory{
          id
          Name
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update division category
 * @param {obj} client_data
 * @returns object
 */
export const editDivisionCategory = async (data, id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      updateDivisionCategory(
        input:{
          where:{
            id:${id}
          }
          data:{
            Name:"${data.Name}"
            ordering_mode:${
              data.ordering_mode != "" ? data.ordering_mode : `null`
            }
          }
        }
      
      )
      {
        divisionCategory{
          id
          Name
        }
      }
    }
      
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get division categories
 * @returns object
 */
export const getDivisionCategories = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  const clientId =
    typeof client_id == "object"
      ? client_id?.client_id
        ? client_id.client_id
        : null
      : client_id;
  let where = clientId ? `where:{clients: [${clientId}]}` : ``;
  axiosOptions.data = {
    query: `
    query{
      divisionCategories(${where}){
        Name
        id
        ordering_mode
        points{
          Balance
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data.divisionCategories;
  } catch (error) {
    return error;
  }
};

/**
 * Delete divison category
 * @param {int} id
 * @returns object
 */
export const deleteDivisionCategory = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        deleteDivisionCategory(
          input:{
            where:{id:${id}},
          }
        )
        {
          divisionCategory{
            id
            Name
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update Client contacts
 * @param {*} values
 * @returns
 */
export const updateClientContacts = async (id, contact_user_ids) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateClient(
            input:{
              where:{id:${id}},
              data:{
                contact_user_ids:[${contact_user_ids}]
              }
            }
          )
          {
            client{
              id
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create a new approval group
 * @param {*} client_id
 * @param {*} name
 * @param {*} user_ids
 * @returns
 */
export const createApprovalGroup = async (client_id, name, user_ids) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          createApprovalGroup(
            input:{
              data:{
                Name:"${name}"
                client:${client_id}
                users:[${user_ids}]
              }
            }
          )
          {
            approvalGroup{
              id
              Name
              users{
                username
              }
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update an approval group
 * @param {*} id
 * @param {*} client_id
 * @param {*} name
 * @param {*} user_ids
 * @returns
 */
export const updateApprovalGroup = async (id, client_id, name, user_ids) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateApprovalGroup(
            input:{
              where:{id:${id}},
              data:{
                Name:"${name}"
                client:${client_id}
                users:[${user_ids}]
              }
            }
          )
          {
            approvalGroup{
              id
              Name
              users{
                username
              }
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteApprovalGroup = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          deleteApprovalGroup(
            input:{
              where:{id:${id}},
            }
          )
          {
            approvalGroup{
              id
              Name
              users{
                username
              }
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List assignable products from single client
 * @param {int} client_id
 * @returns object
 */
export const limitListAssignableProducts = async (client_id, offset) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        query{
          products:clientAvailableProducts(
            client_id: ${client_id}
            limit:20,
            start:${offset},
            sort:"Name",
            where:{Published:${true} active: ${1}}
          ){
            id,
            Name,
            Sku,
            Price,
            Published,
            Image{
              url
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const searchAssignableProducts = async (client_id, search_term) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where: {
    _or: [
      { Name_contains: "${search_term}" active: ${1}},
      { Sku_contains: "${search_term}" active: ${1}}
    ]
  }`;
  axiosOptions.data = {
    query: `
      query{
        products:clientAvailableProducts(
          client_id: ${client_id}
          sort:"Name",
          ${where}
        ){
          id,
          Name,
          Sku,
          Price,
          Published,
          Image{
            url
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data && response.data.data.products) {
      // create search label
      response.data.data.products.forEach((product) => {
        let search_label = product.Name + " - " + product.Sku;
        product.search_label = search_label;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get clients by prodcutId
 * @param {int} productId
 * @returns object
 */
export const getClientsbyProductId = async (productId) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
        query{
          clients(where: {products:${productId} reseller: ${reseller_id}}){
            id
            name
          }
          attributes:productProductAttributes(where:{product:${productId}}){
            id
            client{
              id
            }
          }
          categories:productDivisionCategories(where:{product:${productId}}){
            id
            client{
              id
            }
          }
          newClients:clients(where: { reseller: ${reseller_id}}){
            id
            name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get client messages
 * @param {Number} ClientId
 * @returns object - API Response
 */
export const getClientMessages = async (
  clientId = localStorage.getItem("client_id")
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Set filter by ClientId
  const where = `where:{client: [${clientId}]}`;
  axiosOptions.data = {
    query: `
      query{
        informationMessages(${where}){
          id
          message
          startDate
          endDate
          published_at
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update Client Message
 * @param {*} values Required params
 * @returns {Object} API Response
 */
export const updateClientMessage = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      updateInformationMessage(
        input:{
          where:{id:${values.id}}
          data:{
            message:"${values.message}"
            startDate:"${values.startDate}"
            endDate:"${values.endDate}"
            published_at:${
              values.published_at ? `"${values.published_at}"` : null
            }
          }
        }
      ){
        informationMessage{
          id          
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create Client Message
 * @param {*} values Required Params
 * @returns {Object} API Response
 */
export const CreateClientMessage = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      createInformationMessage(
        input:{
          data:{
            client: "${values.clientId}"
            message:"${values.message}"
            startDate:"${values.startDate}"
            endDate:"${values.endDate}"
          }
        }
      ){
        informationMessage{
          id          
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get User division categories
 * @param {Int} UserId User id
 * @returns Array User division categories
 */
export const getUserDivisionCategories = async (userId) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query {
      divisionCategories(where: { users_permissions_users: [${userId}], _limit: -1 }) {
        Name
        id
        ordering_mode
        points(where: { user_id: [${userId}], _limit: -1 }) {
          Balance
        }
        clients{
          id
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data.divisionCategories;
  } catch (error) {
    return error;
  }
};
