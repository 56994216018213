// Libraries
import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

// API

// Style for color contrast in the selector
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
  },
}));

export function ResellerSelector({
  getClients,
  refreshData,
  user_resellers,
  reseller,
  setReseller,
  userRole,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Autocomplete
      id="autocomplete"
      autoComplete
      autoHighlight
      clearOnEscape
      fullWidth
      loading={user_resellers.length <= 0}
      value={reseller}
      classes={{
        inputRoot: classes.root,
        popupIndicator: classes.root,
      }}
      disableClearable
      options={user_resellers}
      getOptionLabel={(option) => option.name}
      size="small"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            // label="Reseller"
            // variant="outlined"
            // InputProps={{
            //   classes: {
            //     // root: classes.cssOutlinedInput,
            //     // focused: classes.cssFocused,
            //     notchedOutline: classes.root,
            //   },
            // }}
            // classes={{
            //   root: classes.root,
            //   // notchedOutline: classes.root,
            // }}
          />
        );
      }}
      // Changing reseller
      onChange={(event, newValue) => {
        // Set new reseller
        setReseller(newValue);
        localStorage.setItem("reseller_id", newValue.id);
        localStorage.removeItem("Products");
        // Refresh data
        getClients();
        refreshData();
        history.push("/app/products");
      }}
      disabled={userRole == "reselleradmin"}
    />
  );
}

ResellerSelector.propTypes = {
  getClients: PropTypes.func,
  refreshData: PropTypes.func,
  user_resellers: PropTypes.array,
  reseller: PropTypes.object,
  setReseller: PropTypes.func,
  userRole: PropTypes.string,
};
