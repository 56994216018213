import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * List approval groups
 * @returns object
 */
export const listApprovalGroups = async (client_id = null) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Set filter by reseller_id
  let where = `where: { client: { reseller: ${reseller_id} } }`;
  if (client_id) {
    where = `where: { client: ${client_id} }`;
  }
  axiosOptions.data = {
    query: `
        query {
          approvalGroups(${where}) {
            id
            Name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
