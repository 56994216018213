import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

export const createAddress = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        createAddress(
          input: {
            data: {
              Street: "${values.street}"
              Street2: "${values.second_street}"
              City: "${values.city}"
              State: "${values.state}"
              Zip: "${values.zip}"
              Country: "${values.country}"
            }
          }
        ) {
          address {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *  update an existing address
 * @param {int} id
 * @param {*} values
 * @returns
 */
export const updateAddress = async (id, values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateAddress(
          input: {
            where:{id:${id}}
            data: {
              Street: "${values.street}"
              Street2: "${values.second_street}"
              City: "${values.city}"
              State: "${values.state}"
              Zip: "${values.zip}"
              Country: "${values.country}"
            }
          }
        ) {
          address {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * delete an address
 * @param {*} id
 * @returns
 */
export const deleteAddress = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          deleteAddress(
            input:{
              where:{id:${id}},
            }
          )
          {
            address{
              id
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
