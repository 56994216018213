// Libraries
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Divider,
  TextField,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Breadcrumbs,
  Container,
  Link,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLocation, useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ConfirmationDialog from "../ConfirmationDialog";
import Carousel from "react-material-ui-carousel";
import ImagesModal from "./ImagesModal";
import ProductAttributeModal from "./ProductAttributeModal";
import LaunchIcon from "@material-ui/icons/Launch";
import { Autocomplete, Skeleton } from "@material-ui/lab";

// API
import {
  createProduct,
  getProductById,
  updateProduct,
  updateProductClients,
  getProductCategories,
  createProductCategory,
  updateProductPublished,
  addTemplateAttributes,
} from "../../api/Products";
import {
  deleteProductDivisionCategory,
  getProductDivisionCategoriesByProduct,
} from "../../api/ProductDivisionCategories";
import {
  deleteProductProductAttribute,
  getProductProductAttributesByProduct,
} from "../../api/ProductProductAttributes";
import { deleteProductAttribute } from "../../api/Products";
import { getClientsbyProductId } from "../../api/Clients";

import { AttributeSelectTemplates } from "../../api/AttributeTemplates";

// Custom components
import SnackbarMessage from "../../components/SnackbarMessage";
import TableWithAppBar from "./TableWithAppBar";
import DeleteProductModal from "../products/DeleteProductModal";
import AlertDialog from "../AlertDialog";
import AddDivisionCategoryDialog from "./AddDivisionCategoryDialog";
//import AddAttributeDialog from "./AddAttributeDialog";
import AddClientDialog from "./AddClientDialog";

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup.string("Product Name").required("Product Name is required"),
  Description: yup.string("Product Description"),
  Summary: yup.string("Product Summary"),
  Price: yup
    .number()
    .required("Product Price is required")
    .typeError("Price must be a number"),
  Sku: yup.string("SKU").required("Product SKU is required"),
  Published: yup.bool(),
});

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  save: {
    float: "right",
    marginTop: "15px",
  },
  cancel: {
    float: "right",
    marginTop: "15px",
    [theme.breakpoints.up(400)]: {
      marginRight: "15px",
    },
  },
  cancel_on_create: {
    float: "right",
    marginTop: "15px",
    marginRight: "15px",
  },
  mainContainer: {
    marginTop: "65px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "95px",
    },
  },
  fullwidth: {
    width: "100%",
  },
}));

// Main component
const ProductForm = () => {
  let history = useHistory();
  const [productName, setProductName] = useState("");
  const [initialValues, setInitialValues] = useState({
    Name: "",
    Description: "",
    Summary: "",
    Price: "",
    Sku: "",
    Published: false,
    id: false,
    Image: [],
  });
  const [product_categories, setProductCategories] = useState([]);
  const [product_attributes, setProductAttributes] = useState([]);
  const [all_categories, setAllCategories] = useState([]);
  const [filtered_categories, setFilteredCategories] = useState([]);
  const [all_attributes, setAllAttributes] = useState([]);
  const [filtered_attributes, setFilteredAttributes] = useState([]);
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  //const [image, setImage] = useState("");
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openAttributeDialog, setOpenAttributeDialog] = useState(false);
  const [clients, setClients] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [uploadCount, setUploadCount] = useState(0); //this one is to check how many new images are being added
  //const [activeImageIndex, setActiveImageIndex] = useState("");
  const [openDeleteImage, setOpenDeleteImage] = useState(false);
  const [deleteImages, setDeleteImages] = useState([]);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [categories, setCategories] = useState([{ id: "", Name: "" }]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [productClients, setProductClients] = useState([]);
  const [productCategoriesCopy, setProductcategoriesCopy] = useState([]);
  const [productAttributesCopy, setProductAttributesCopy] = useState([]);
  const [deleteAllClients, setDeleteAllClients] = useState(false);
  const [Published, setPublished] = useState(false);
  const [clientsOptions, setClientOptions] = useState([]);
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [alertUnpublisher, setAlertUnpublisher] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  //check if url contains id
  let { id, client_id } = useParams();
  let location = useLocation();
  //formik
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      values.client_id = client_id;
      //Checking if a new category is created
      if (selectedCategory && selectedCategory.id == "0") {
        //Creating a new category
        await createProductCategory(selectedCategory.Name).then((result) => {
          if (
            result &&
            result.data &&
            result.data.createProductCategory &&
            result.data.createProductCategory.productCategory
          ) {
            //Getting the new record
            const productCategory =
              result.data.createProductCategory.productCategory;
            //Assigning the new id
            values.productCategory = productCategory.id;
            setSelectedCategory(productCategory);
          }
        });
      } else if (selectedCategory && selectedCategory.id) {
        //Assigning the procut category id
        values.productCategory = selectedCategory.id;
      } else {
        values.productCategory = null;
      }
      //check if there are images to be deleted
      if (deleteImages.length >= 1) {
        deleteImages.forEach((image) => {
          axios
            .delete(`${process.env.REACT_APP_REST_URL}/upload/files/${image}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
      //if there are no new images
      if (uploadCount <= 0) {
        if (id) {
          updateProductCall(values);
        } else {
          createProductCall(values);
        }
      } else {
        //check the image array to determine if there are new images
        let imageCount = 0;
        images.forEach((image) => {
          if (image.files) {
            let formData = new FormData();
            formData.append("files", image.files);
            axios
              .post(`${process.env.REACT_APP_REST_URL}/upload`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                let newImage = res.data[0];
                newImage.order = image.order;
                values.Image.push(newImage);
                imageCount++;
                if (imageCount == uploadCount) {
                  if (id) {
                    updateProductCall(values);
                  } else {
                    createProductCall(values);
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
    },
  });

  const toggleDeleteModal = (event = null) => {
    if (event && event.sucess == "deleted_product") {
      //Show SnackbarMessage
      setError({
        open: true,
        message: "Product Deleted Successfully",
        severity: "success",
      });

      setTimeout(() => {
        history.replace("/app/products");
      }, 3000);
    }
    setDeleteModalOpen(!deleteModalOpen);
  };

  // Refresh cateories on delete or create
  const refreshCategories = () => {
    getProductDivisionCategoriesByProduct(id, client_id).then((xhr) => {
      formatCategories(xhr.data.productDivisionCategories);
      // Filter categories
      let filtered_categories = all_categories.filter((category) => {
        let exists = xhr.data.productDivisionCategories.some((pc) => {
          return pc.division_category.id == category.id;
        });
        return !exists;
      });
      setFilteredCategories(filtered_categories);
    });
  };

  // Refresh attributes on delete or create
  const refreshAttributes = () => {
    getProductProductAttributesByProduct(id, client_id).then((xhr) => {
      formatAttributes(xhr.data.productProductAttributes);
      // Filter attributes to prevent add multiplicated rows
      let filtered_attributes = all_attributes.filter((attribute) => {
        let exists = xhr.data.productProductAttributes.some((pa) => {
          return pa.product_attribute.id == attribute.id;
        });
        return !exists;
      });
      setFilteredAttributes(filtered_attributes);
    });
  };

  // Get categories and format them
  const formatCategories = (categories) => {
    let product_categories = categories.map((category) => {
      return {
        name: category.division_category.Name,
        display_order: category.display_order,
        id: category.id,
        icon: {
          content: (
            <AlertDialog
              openButton="icon"
              openButtonColor="inherit"
              dialogTitle="Unlink Division Category"
              dialogContentText="This division category will be removed from the product. Do you want to continue?"
              confirmButtonClick={() =>
                deleteProductDivisionCategory(category.id).then(() => {
                  //refreshCategories();
                  //Show SnackbarMessage
                  setError({
                    open: true,
                    message: "Division category successfully removed",
                    severity: "success",
                  });
                })
              }
            />
          ),
          align: "right",
        },
      };
    });
    setProductCategories(product_categories);
  };

  // Get attributes and format them
  const formatAttributes = (attributes) => {
    let product_attributes = attributes.map((attribute) => {
      return {
        name: attribute.product_attribute.Name,
        control_type: attribute.product_attribute.ControlType,
        display_order: attribute.display_order,
        id: attribute.id,
        required: attribute.required ? "Yes" : "No",
        icon: {
          content: (
            <AlertDialog
              openButton="icon"
              openButtonColor="inherit"
              dialogTitle="Delete Attribute"
              dialogContentText="This attribute will be removed from the product. Do you want to continue?"
              confirmButtonClick={() =>
                deleteProductProductAttribute(attribute.id).then(() => {
                  deleteProductAttribute(attribute.product_attribute.id).then(
                    () => {
                      //refreshAttributes();
                      //Show SnackbarMessage
                      setError({
                        open: true,
                        message: "Product attribute successfully removed",
                        severity: "success",
                      });
                    }
                  );
                })
              }
            />
          ),
          align: "right",
        },
        link: {
          content: (
            <LaunchIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                //Set the product id to return from sttribute details
                let prevPath = localStorage.getItem("pathname");
                localStorage.setItem("previousPath", prevPath);
                localStorage.setItem("productAttributeOwner", id);
                history.push(
                  `/app/product-attribute/${attribute.product_attribute.id}`
                );
              }}
            />
          ),
          align: "right",
        },
      };
    });
    setProductAttributes(product_attributes);
  };

  //get product data
  const getProductData = () => {
    if (id) {
      getProductById(id, client_id)
        .then((result) => {
          // Get product
          let product = result?.data?.product;
          // Validate if product is active
          if (product && !product.active) {
            history.push("/app/products");
          }
          // Set all division categories & product attributes
          setAllCategories(result.data.divisionCategories);
          setAllAttributes(result.data.productAttributes);
          if (product.Published != true) {
            product.Published = false;
          }
          // Set product info
          setInitialValues(product);
          setPublished(product.Published ? "Published" : "Unpublished");
          setProductName(product.Name);
          if (product.Image.length) {
            setImages(product.Image);
          }
          // Set product categories
          formatCategories(product.categories);
          // Set product attributes
          formatAttributes(product.attributes);
          // Filter categories to prevent add multiplicated rows
          let filtered_categories = result.data.divisionCategories.filter(
            (category) => {
              let exists = product.categories.some((pc) => {
                return pc.division_category.id == category.id;
              });
              return !exists;
            }
          );
          setFilteredCategories(filtered_categories);
          // Filter attributes to prevent add multiplicated rows
          let filtered_attributes = result.data.productAttributes.filter(
            (attribute) => {
              let exists = product.attributes.some((pa) => {
                return pa.product_attribute.id == attribute.id;
              });
              return !exists;
            }
          );
          setFilteredAttributes(filtered_attributes);
          //product current clients
          let addClients = product.clients.map((dc) => {
            return dc.id;
          });
          setClients(addClients);
          setLoading(false);
          //Set product category
          if (product.product_category && product.product_category.id) {
            setSelectedCategory(product.product_category);
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const getAllattribute = () => {
    AttributeSelectTemplates()
      .then((result) => {
        if (result.data) {
          const data = result.data.attributeTemplates;
          let templates = data.map((template) => {
            return {
              id: template.id,
              Name: template.Name,
              attributes: template.product_product_attributes.map((item) => {
                return {
                  id: item.id,
                  display_order: item.display_order,
                  attributeId: item.product_attribute.id,
                  Name: item.product_attribute.Name,
                  DisplayText: item.product_attribute.DisplayText,
                  ControlType: item.product_attribute.ControlType,
                  required: item.required,
                };
              }),
            };
          });
          setTemplates(templates);
        }
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: "An error occurred",
          severity: "error",
        });
      });
  };

  // Exec this only once at first load
  useEffect(() => {
    //Remove the local storage item that sets where to return if user clicks on attribute detail
    localStorage.removeItem("productAttributeOwner");
    localStorage.removeItem("previousPath");
    getProductData();
    getAllProductCategories();
    GetClientsByProductId();
    getAllattribute();
  }, []);

  useEffect(() => {
    if (id) {
      if (all_categories.length > 0) {
        refreshCategories();
      }
      if (all_attributes.length > 0) {
        refreshAttributes();
      }
    }
  }, [error]);

  const updateProductCall = (values) => {
    setDeleteImages([]);
    setUploadCount(0);
    values.imagesArrray = [];
    let sortedImages = values.Image.sort(function (a, b) {
      return a.order - b.order;
    });
    values.Image = sortedImages;
    values.Image.forEach((pic) => {
      values.imagesArrray.push(pic.id);
    });
    updateProduct(values)
      .then((xhr) => {
        setSaving(false);
        if (xhr && xhr.data && xhr.data.errors) {
          setError({
            open: true,
            message: `Error: ${xhr.response.data.errors[0].message}`,
            severity: "error",
          });
        } else {
          //setImage("");
          getProductData();
          setOpenImagesModal(false);
          setError({
            open: true,
            message: "Product Saved Successfully",
            severity: "success",
          });
          localStorage.removeItem("currentProductPage");
          localStorage.removeItem("currentProductRows");
          localStorage.removeItem("Products");
          localStorage.removeItem("resellerProducts");
        }
      })
      .catch((xhr) => {
        console.log(xhr);
      });
  };

  const createProductCall = (values) => {
    setUploadCount(0);
    values.imagesArrray = [];
    values.Image.forEach((pic) => {
      values.imagesArrray.push(pic.id);
    });
    createProduct(values)
      .then((xhr) => {
        if (xhr.data.errors) {
          setSaving(false);
          setError({
            open: true,
            message: `Error: ${xhr.response.data.errors[0].message}`,
            severity: "error",
          });
        } else {
          setOpenImagesModal(false);
          setError({
            open: true,
            message: "Product Created Successfully",
            severity: "success",
          });
          localStorage.removeItem("currentProductPage");
          localStorage.removeItem("currentProductRows");
          localStorage.removeItem("Products");
          localStorage.removeItem("resellerProducts");
          setTimeout(() => {
            history.replace(
              values.client_id
                ? `/app/client/${values.client_id}/products`
                : `/app/product/${xhr.data.createProduct.product.id}`
            );
          }, 2000);
        }
      })
      .catch((xhr) => {
        console.log(xhr);
        setError({
          open: true,
          message: `An error occurred, please check your data and try again.`,
          severity: "error",
        });
        setSaving(false);
      });
  };

  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  const assignProductToClient = () => {
    setSaving(true);
    let addClients = clients;
    addClients.push(client_id);
    setClients(addClients);
    updateClients();
  };

  const unassignProduct = () => {
    let addClients = clients;
    const index = addClients.indexOf(client_id);
    if (index > -1) {
      addClients.splice(index, 1);
    }
    setClients(addClients);
    product_categories.forEach((category) => {
      deleteProductDivisionCategory(category.id);
    });
    product_attributes.forEach((attribute) => {
      deleteProductProductAttribute(attribute.id);
    });
    updateClients();
  };

  const updateClients = () => {
    updateProductClients(id, clients)
      .then((res) => {
        if (res.data.updateProduct.product.id) {
          setSaving(false);
          setError({
            open: true,
            message: "Product Assigned Successfully",
            severity: "success",
          });
          history.push(`/app/client/${client_id}/products`);
        } else {
          setError({
            open: true,
            message: `There was an error, please try again later`,
            severity: "error",
          });
        }
      })
      .catch((xhr) => {
        setError({
          open: true,
          message: `There was an error, please try again later:  ${xhr.response.data.errors[0].message}`,
          severity: "error",
        });
      });
  };

  const toggleDeleteImage = (id) => {
    setDeleteId(id);
    setOpenDeleteImage(true);
  };

  /**
   * check if the images are already uploaded, if so, add the ids to an array that will delete them if changes are saved
   * if the images are new, just remove them from array
   */
  const deleteImage = () => {
    let deleteArray = [...deleteImages];
    //if the image has an id, add the id to the array to be deleted
    if (deleteId) {
      deleteArray.push(deleteId);
      setDeleteImages(deleteArray);
    } else {
      //if the image does not have an id, means the image is not yet in the server, so just remove it drom the array and deacreace the upload count
      setUploadCount(uploadCount - 1);
    }

    images.splice(deleteId, 1);
  };

  const toggleAttributeModal = (reloadAttributes) => {
    if (reloadAttributes) {
      refreshAttributes();
    }
    setSelectedTemplate(null);
    setOpenAttributeDialog(!openAttributeDialog);
  };

  const successAddAttribute = () => {
    setError({
      message: "Product attribute created successfully.",
      severity: "success",
      open: true,
    });
  };

  /**
   * Get all produc categories
   */
  const getAllProductCategories = () => {
    getProductCategories().then((result) => {
      //Get product categories
      if (result && result.data && result.data.productCategories) {
        const categories = result.data.productCategories;
        setCategories(categories);
      }
    });
  };

  /**
   * Search product categories
   * @param {Array} categories product categories
   * @param {String} query search term
   */
  const filterCategories = (categories, query) => {
    //searching elements
    const search = categories.filter((category) =>
      category.Name.toLowerCase().includes(query.toLowerCase())
    );
    //Checking if it already exists
    const exist = categories.findIndex((category) => category.id == "0");
    //If it exists, it is updated, if it does not exist, it is added
    if (search && search.length <= 1) {
      let newCategory = {
        id: "0",
        Name: `Add "${query}"`,
      };
      if (search.length == 1 && search[0].id !== "0") {
        categories.splice(-1);
      } else {
        if (exist == -1) {
          categories.push(newCategory);
        } else {
          categories[exist] = newCategory;
        }
      }
      setCategories(categories);
    } else {
      if (exist != -1) {
        categories.splice(-1);
      }
      setCategories(categories);
    }
  };

  /**
   * Prepare the name of the new category
   */
  useEffect(() => {
    if (
      selectedCategory &&
      selectedCategory.id == 0 &&
      selectedCategory.Name.startsWith("Add")
    ) {
      selectedCategory.Name = selectedCategory.Name.slice(
        5,
        selectedCategory.Name.length - 1
      );
      setSelectedCategory(selectedCategory);
    }
  }, [selectedCategory]);

  /**
   * Format product clients
   * @param {Object} productClients
   */
  const formatProductClients = (productClients) => {
    let clients = productClients.map((client) => {
      return {
        name: client.name,
        id: client.id,
        icon: {
          content: (
            <AlertDialog
              openButton="icon"
              openButtonColor="inherit"
              dialogTitle="Remove assigned client"
              dialogContentText="The product will no longer be available to this client. Do you want to continue?"
              confirmButtonClick={() =>
                DeleteClientFromProduct(client.id, "One")
              }
              confirmButtonText="Continue"
              confirmButtonColor="primary"
              closeButtonText="Cancel"
            />
          ),
          align: "right",
        },
      };
    });
    setProductClients(clients);
  };

  /**
   * Format product clients
   * @param {Object} clients
   */
  const GetClientsByProductId = () => {
    getClientsbyProductId(id)
      .then((result) => {
        //Get attributes
        if (result && result.attributes) {
          const attributes = result.attributes;
          let productAttributes = attributes.map((dc) => {
            return {
              attributeId: dc.id,
              clientId: dc.client && dc.client.id ? dc.client.id : null,
            };
          });
          setProductAttributesCopy(productAttributes);
        }
        //Get categories
        if (result && result.categories) {
          const categories = result.categories;
          let produtCategories = categories.map((category) => {
            return {
              categoryId: category.id,
              clientId:
                category && category.client && category.client.id
                  ? category.client.id
                  : null,
            };
          });
          setProductcategoriesCopy(produtCategories);
        }
        //Get all clients that product does not belong
        if (result && result.newClients) {
          const newClients = result.newClients;
          result.clients.forEach((client) => {
            const index = newClients.findIndex(
              (newClient) => newClient.id == client.id
            );
            if (index > -1) {
              newClients.splice(index, 1);
            }
          });
          setClientOptions(newClients);
        }
        //Get clients
        if (result && result.clients) {
          const clients = result.clients;
          formatProductClients(clients);
        }
      })
      .catch((xhr) => {
        console.log("Error: ", xhr);
      });
  };

  /**
   * Delete Client from produt
   * @param {Int} clientId clientId
   * @param {String} action one|All|Add
   */
  const DeleteClientFromProduct = (clientId, action = "One") => {
    getClientsbyProductId(id).then((clients) => {
      if (clients && clients.clients) {
        const productClients = clients.clients.map(
          (productClient) => productClient.id
        );
        //Set client ids
        let newClientsIds = productClients;
        // //Remove One client
        if (action == "One") {
          const index = newClientsIds.findIndex((client) => client == clientId);
          if (index > -1) {
            newClientsIds.splice(index, 1);
          }
        } else if (
          action == "Add" &&
          Array.isArray(clientId) &&
          clientId.length > 0
        ) {
          newClientsIds = newClientsIds.concat(clientId);
        } else if (action == "All") {
          //Remove all clients
          newClientsIds = [];
        }

        if (action !== "Add") {
          //Get categories
          for (let category of productCategoriesCopy) {
            if (
              category &&
              category.clientId &&
              category.clientId == clientId
            ) {
              deleteProductDivisionCategory(category.categoryId);
            } else if (action == "All") {
              category && category.clientId && category.clientId != null
                ? deleteProductDivisionCategory(category.categoryId)
                : "";
            }
          }
          //Get attributes
          for (let attribute of productAttributesCopy) {
            if (
              attribute &&
              attribute.clientId &&
              attribute.clientId == clientId
            ) {
              deleteProductProductAttribute(attribute.attributeId);
            } else if (action == "All") {
              attribute && attribute.clientId && attribute.clientId != null
                ? deleteProductProductAttribute(attribute.attributeId)
                : "";
            }
          }
        }
        //Update product
        updateProductClients(id, newClientsIds)
          .then((res) => {
            if (res.data.updateProduct.product.id) {
              //"Product Assigned Successfully"
              setError({
                open: true,
                message: `${action == "All" ? "All Clients" : "Client"} ${
                  action == "Add" ? "Added" : "Deleted"
                } Successfully`,
                severity: "success",
              });
              //Get all product clients
              GetClientsByProductId();
              //Get all product
              getProductData();
            } else {
              setError({
                open: true,
                message: `There was an error, please try again later`,
                severity: "error",
              });
            }
          })
          .catch((xhr) => {
            setError({
              open: true,
              message: `There was an error, please try again later:  ${xhr.response.data.errors[0].message}`,
              severity: "error",
            });
          });
      }
    });
  };

  const changeSelectedTemplate = (value) => {
    setSelectedTemplate(value);
  };

  const saveTemplateAttributes = () => {
    addTemplateAttributes({ id, templateId: selectedTemplate.id })
      .then(() => {
        toggleAttributeModal(true);
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: `An error occurred`,
          severity: "error",
        });
      });
  };

  return (
    <Container
      id="main_container"
      className={classes.mainContainer}
      maxWidth="xl"
    >
      <Grid container spacing={3} style={{ padding: "20px" }}>
        {/* Show current location */}
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {client_id ? (
              <Link
                color="textSecondary"
                onClick={() =>
                  history.push(`/app/client/${client_id}/products`)
                }
                style={{ cursor: "pointer" }}
              >
                Products
              </Link>
            ) : (
              //<Typography color="textSecondary">Products</Typography>
              <Link
                color="textSecondary"
                onClick={() => history.push(`/app/products`)}
                style={{ cursor: "pointer" }}
              >
                Products
              </Link>
            )}

            {location.pathname.includes("assign") && client_id ? (
              <Link
                color="textSecondary"
                onClick={() =>
                  history.push(`/app/client/${client_id}/products/assign`)
                }
                style={{ cursor: "pointer" }}
              >
                Assign Product
              </Link>
            ) : (
              ""
            )}

            <Typography color="textSecondary">
              {id ? productName : "New"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Image */}
        <Grid item xs={12} md={5} lg={4}>
          <Paper className={classes.paper} style={{ maxHeight: "350px" }}>
            <Carousel
              autoPlay={false}
              navButtonsAlwaysVisible={images.length > 1 ? true : false}
              navButtonsAlwaysInvisible={images.length > 1 ? false : true}
              animation={"slide"}
              className={classes.fullwidth}
              // onChange={(index) => {
              //   setActiveImageIndex(index);
              // }}
            >
              {images.length < 1 ? (
                <Grid
                  item
                  xs={12}
                  className={classes.paper}
                  style={{ minHeight: "300px" }}
                >
                  <img
                    src={"/img/avid/avid-io_250x266.png"}
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                </Grid>
              ) : (
                images.map((pic, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      className={classes.paper}
                      style={{ height: "300px" }}
                      key={index}
                    >
                      <img
                        src={
                          pic.url ? pic.url : "/img/avid/avid-io_250x266.png"
                        }
                        style={{ maxWidth: "100%", maxHeight: "280px" }}
                      />
                    </Grid>
                  );
                })
              )}
            </Carousel>
            <br />

            <Divider style={{ width: "100%" }} />
            {!location.pathname.includes("assign") ? (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    let localOrder = 1;
                    images.forEach((image) => {
                      image.tmpId = image.id
                        ? image.id
                        : image.tmpId
                        ? image.tmpId
                        : localOrder.toString();
                      image.order = image.order ? image.order : localOrder;
                      localOrder = localOrder + 1;
                    });
                    setImages(images);
                    setOpenImagesModal(true);
                  }}
                >
                  Edit Images
                </Button>
                {/* <Button component="label" color="primary">
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    hidden
                    onChange={(event) => {
                      let imagesArray = [...images];
                      imagesArray.unshift({
                        files: event.target.files[0],
                        url: URL.createObjectURL(event.target.files[0]),
                      });
                      setImages(imagesArray);
                      setUploadCount(uploadCount + 1);
                    }}
                  />
                  Upload Picture
                </Button> */}
                {/* {images.length >= 1 ? (
                  <Button color="secondary" onClick={toggleDeleteImage}>
                    Delete Picture
                  </Button>
                ) : (
                  ""
                )} */}
              </div>
            ) : (
              ""
            )}
          </Paper>
          {/**Client list */}
          {id && !location.pathname.includes("assign") ? (
            <Grid item xs={12} style={{ marginTop: "25px" }}>
              <TableWithAppBar
                title="Assigned to"
                toolbarIconClick={() => {
                  if (Published == "Unpublished") {
                    setAlertUnpublisher(true);
                  } else {
                    setOpenAddClientDialog(true);
                  }
                }}
                items={productClients}
                noRecordsMessage="There are no Clients yet"
              />
            </Grid>
          ) : (
            ""
          )}
          {location.pathname.includes("assign") ? (
            <Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.save}
                onClick={assignProductToClient}
                style={{ marginLeft: "15px" }}
                disabled={saving}
              >
                Save and Assign
              </Button>
              <Button
                variant="contained"
                className={classes.cancel}
                onClick={() =>
                  client_id
                    ? history.push(`/app/client/${client_id}/products`)
                    : history.push(`/app/products`)
                }
              >
                Cancel
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {/* Form */}
        <Grid item xs={12} md={7} lg={8}>
          <Paper className={classes.paper}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
              {/* Fields */}
              <Grid container spacing={3}>
                {/* Published checkbox */}
                <Grid item xs={12}>
                  <FormGroup row>
                    {client_id ? (
                      clients.includes(client_id) ? (
                        <Button
                          style={{ marginLeft: "auto" }}
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          endIcon={<DeleteIcon />}
                          onClick={() => {
                            setOpenConfirmation(true);
                          }}
                        >
                          Unassign
                        </Button>
                      ) : (
                        ""
                      )
                    ) : (
                      <FormControlLabel
                        style={{ marginLeft: "auto" }}
                        control={
                          <Checkbox
                            checked={
                              formik.values.Published != null
                                ? formik.values.Published
                                : false
                            }
                            onChange={formik.handleChange}
                            onClick={() => {
                              formik.values.Published != null &&
                              formik.values.Published &&
                              clients.length > 0
                                ? setDeleteAllClients(true)
                                : "";
                            }}
                            name="Published"
                            color="primary"
                            disabled={location.pathname.includes("assign")}
                          />
                        }
                        label="Published"
                      />
                    )}
                  </FormGroup>
                </Grid>
                {/* Name */}
                <Grid item xs={12} lg={6}>
                  {loading ? (
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="Name"
                      name="Name"
                      label="Name"
                      variant="outlined"
                      value={formik.values.Name}
                      onChange={formik.handleChange}
                      error={formik.touched.Name && Boolean(formik.errors.Name)}
                      helperText={formik.touched.Name && formik.errors.Name}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
                {/* Description */}
                <Grid item xs={12} lg={6}>
                  {loading ? (
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="Description"
                      name="Description"
                      label="Description"
                      variant="outlined"
                      value={formik.values.Description}
                      onChange={formik.handleChange}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
                {/* Summary */}
                <Grid item xs={12}>
                  {loading ? (
                    <Skeleton variant="rect" width={"100%"} height={118} />
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="Summary"
                      name="Summary"
                      label="Summary"
                      variant="outlined"
                      value={formik.values.Summary}
                      onChange={formik.handleChange}
                      multiline
                      rows={6}
                      rowsMax={6}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
                {/* Price */}
                <Grid item xs={12} lg={6}>
                  {loading ? (
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="Price"
                      name="Price"
                      label="Price"
                      variant="outlined"
                      value={formik.values.Price}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Price && Boolean(formik.errors.Price)
                      }
                      helperText={formik.touched.Price && formik.errors.Price}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
                {/* SKU */}
                <Grid item xs={12} lg={6}>
                  {loading ? (
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      id="Sku"
                      name="Sku"
                      label="SKU"
                      variant="outlined"
                      value={formik.values.Sku}
                      onChange={formik.handleChange}
                      error={formik.touched.Sku && Boolean(formik.errors.Sku)}
                      helperText={formik.touched.Sku && formik.errors.Sku}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
                {/**Product categorie */}
                <Grid item xs={12} lg={6}>
                  {loading ? (
                    <Typography variant="h3">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <Autocomplete
                      autoComplete
                      autoHighlight
                      freeSolo
                      style={{ marginLeft: "auto" }}
                      id="productCategory"
                      clearOnBlur
                      options={categories}
                      getOptionLabel={(option) =>
                        option.Name ? option.Name : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add Product Category"
                          placeholder="Product Category"
                          margin="none"
                          variant="outlined"
                          onChange={(e) => {
                            if (e.target.value.length > 2) {
                              filterCategories(categories, e.target.value);
                            } else if (e.target.value.length == 0) {
                              setSelectedCategory({});
                            }
                          }}
                        />
                      )}
                      value={selectedCategory}
                      onChange={(event, value) => {
                        setSelectedCategory(value);
                      }}
                      disabled={location.pathname.includes("assign")}
                    />
                  )}
                </Grid>
              </Grid>
              {id ? (
                <React.Fragment>
                  {/* Division Categories */}
                  {client_id ? (
                    <Grid item xs={12} style={{ marginTop: "25px" }}>
                      <TableWithAppBar
                        title="Division Categories"
                        toolbarIconClick={() => setOpenCategoryDialog(true)}
                        headers={[
                          { name: "Name" },
                          { name: "Display Order", colSpan: 2 },
                        ]}
                        items={product_categories}
                        noRecordsMessage="There are no division categories yet"
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {/* Product Attributes */}
                  <Grid item xs={12} style={{ marginTop: "25px" }}>
                    <TableWithAppBar
                      title="Product Attributes"
                      toolbarIconClick={() => setOpenAttributeDialog(true)}
                      headers={[
                        { name: "Name" },
                        { name: "Control Type" },
                        { name: "Display Order" },
                        { name: "Required", colSpan: 3 },
                      ]}
                      items={product_attributes}
                      noRecordsMessage="There are no Product attributes yet"
                    />
                  </Grid>
                </React.Fragment>
              ) : null}
              {/* Buttons */}
              {!location.pathname.includes("assign") ? (
                <Grid item xs={12}>
                  {id ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: "15px" }}
                      onClick={() => toggleDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.save}
                    disabled={saving}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    className={id ? classes.cancel : classes.cancel_on_create}
                    onClick={() =>
                      client_id
                        ? history.push(`/app/client/${client_id}/products`)
                        : history.push("/app/products")
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              ) : (
                ""
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
      <DeleteProductModal
        deleteModalOpen={deleteModalOpen}
        toggleDeleteModal={toggleDeleteModal}
        productId={Number(id)}
        error={setError}
      />
      <AddDivisionCategoryDialog
        open={openCategoryDialog}
        handleClose={() => setOpenCategoryDialog(false)}
        division_categories={filtered_categories}
        product_id={Number(id)}
        setError={setError}
        refreshData={refreshCategories}
        client_id={Number(client_id)}
      />
      {/* <AddAttributeDialog
        open={openAttributeDialog}
        handleClose={() => setOpenAttributeDialog(false)}
        product_attributes={filtered_attributes}
        product_id={Number(id)}
        setError={setError}
        refreshData={refreshAttributes}
        client_id={Number(client_id)}
      /> */}

      <ProductAttributeModal
        modalOpen={openAttributeDialog}
        toggleModal={toggleAttributeModal}
        successAddAttribute={successAddAttribute}
        product_attributes={filtered_attributes}
        product_id={Number(id)}
        client_id={client_id}
        templates={templates}
        changeSelectedTemplate={changeSelectedTemplate}
        selectedTemplate={selectedTemplate}
        saveTemplateAttributes={saveTemplateAttributes}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        open={openConfirmation}
        dialogTitle="Confirm Unassignment"
        dialogContentText="Are you sure you want to unassign this product?"
        handleClose={() => {
          setOpenConfirmation(false);
        }}
        confirmButtonClick={unassignProduct}
        closeButtonText="Cancel"
        confirmButtonText="Unassign"
      />
      <ConfirmationDialog
        open={openDeleteImage}
        dialogTitle="Confirm Delete"
        dialogContentText="Are you sure you want to delete this image?"
        handleClose={() => {
          setOpenDeleteImage(false);
        }}
        confirmButtonClick={deleteImage}
        closeButtonText="Cancel"
        confirmButtonText="Delete"
      />
      <ImagesModal
        images={images}
        modalOpen={openImagesModal}
        uploadCount={uploadCount}
        toggleModal={() => setOpenImagesModal(false)}
        setImages={setImages}
        setUploadCount={setUploadCount}
        formik={formik}
        toggleDeleteImage={toggleDeleteImage}
        saving={saving}
      />
      {/**Delete All clients from product*/}
      <ConfirmationDialog
        open={deleteAllClients}
        dialogTitle="Unpublish Product"
        dialogContentText="The product will no longer be available to assigned clients. Do you want to continue?"
        handleClose={() => {
          //Close modal
          setDeleteAllClients(false);
          if (Published == "Published") {
            formik.values.Published = !formik.values.Published;
          }
        }}
        confirmButtonClick={() => {
          DeleteClientFromProduct(null, "All");
          updateProductPublished({ id: id, Published: false }).then(
            (result) => {
              if (
                result &&
                result.data &&
                result.data.updateProduct &&
                result.data.updateProduct.product
              ) {
                setError({
                  open: true,
                  message: "Product Unpublished Successfully",
                  severity: "success",
                });
                getProductData();
              }
            }
          );
        }}
        closeButtonText="Cancel"
        confirmButtonText="Continue"
        confirmButtonColor="primary"
        PropsButton={{ size: "medium" }}
      />
      {/**Add client dialog */}
      <AddClientDialog
        open={openAddClientDialog}
        options={clientsOptions}
        save={DeleteClientFromProduct}
        toggleModal={() => {
          setOpenAddClientDialog(!openAddClientDialog);
        }}
      />
      {/**Published product */}
      <ConfirmationDialog
        open={alertUnpublisher}
        dialogTitle="Publish Product"
        dialogContentText="The product should be published to be assigned to a client. Do you want to publish it?"
        handleClose={() => {
          //Close modal
          setAlertUnpublisher(false);
        }}
        confirmButtonClick={() => {
          updateProductPublished({ id: id, Published: true }).then((result) => {
            if (
              result &&
              result.data &&
              result.data.updateProduct &&
              result.data.updateProduct.product
            ) {
              setError({
                open: true,
                message: "Product Published Successfully",
                severity: "success",
              });
              getProductData();
            }
          });
        }}
        closeButtonText="Cancel"
        confirmButtonText="Continue"
        confirmButtonColor="primary"
        PropsButton={{ size: "medium" }}
      />
    </Container>
  );
};

//ProductForm.propTypes = {};

export default ProductForm;
