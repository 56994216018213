import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import { Container } from "@material-ui/core";
import { listProductAttributes, deleteProductAttribute } from "../api/Products";
import Skeleton from "@material-ui/lab/Skeleton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import ProductAttributeModal from "../components/products/ProductAttributeModal";
import { useHistory } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "DisplayText",
    disablePadding: false,
    label: "Display Text",
  },
  {
    id: "ControlType",
    disablePadding: false,
    label: "Control Type",
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label} </b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root} color={"secondary"}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Product Attributes
      </Typography>

      <Tooltip title="Add Attribute">
        <IconButton
          aria-label="Add Attribute"
          onClick={props.toggleAttributeModal}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  toggleAttributeModal: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  mainContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
      marginTop: "85px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "110px",
    },
  },
  tableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
  },
  tableCellName: {
    paddingLeft: "16px",
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
}));

export default function ProductAttributes() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [attributeModalOpen, setAttributeModalOpen] = useState(false);
  let history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  /**Initial loading */
  useEffect(() => {
    // List products
    getProductAttributes();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, attributes.length - page * rowsPerPage);

  const getProductAttributes = () => {
    listProductAttributes().then((result) => {
      let resultAttributes = result.data.productAttributes;
      resultAttributes.forEach((element) => {
        if (element.DisplayText == null) {
          element.DisplayText = "";
        }
      });
      setAttributes(resultAttributes);
      setLoading(false);
    });
  };

  const toggleDeleteModal = (attr) => {
    setOpenDeleteModal(true);
    setSelectedAttribute(attr);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedAttribute({});
  };

  //close snackbar message
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };
  const handleDeleteAttribute = () => {
    deleteProductAttribute(selectedAttribute.id).then((res) => {
      if (res.data.deleteProductAttribute) {
        setError({
          message: "Product attribute deleted.",
          severity: "success",
          open: true,
        });
        closeDeleteModal();
        getProductAttributes();
      } else if (res.response.data.errors.length) {
        setError({
          message: "An error ocurred",
          severity: "error",
          open: true,
        });
      }
    });
  };

  const successAddAttribute = () => {
    setError({
      message: "Product attribute created successfully.",
      severity: "success",
      open: true,
    });
  };

  const toggleAttributeModal = (reloadAttributes) => {
    if (reloadAttributes) {
      getProductAttributes();
    }
    setAttributeModalOpen(!attributeModalOpen);
  };
  return (
    <Container className={classes.mainContainer} maxWidth="xl">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          toggleAttributeModal={toggleAttributeModal}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={attributes.length}
            />
            <TableBody>
              {loading
                ? [1, 2, 3, 4, 5].map((index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={classes.tableCellName}
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          <Skeleton />
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Skeleton />
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : stableSort(attributes, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell
                            className={classes.tableCellName}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.Name}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row.DisplayText}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {row.ControlType}
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            <LaunchIcon
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                history.push(`/app/product-attribute/${row.id}`)
                              }
                            />
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleDeleteModal(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={attributes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        dialogTitle="Delete product attribute"
        dialogContentText="Product attribute will be deleted, continue?"
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        open={openDeleteModal}
        handleClose={closeDeleteModal}
        confirmButtonClick={handleDeleteAttribute}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
      <ProductAttributeModal
        modalOpen={attributeModalOpen}
        toggleModal={toggleAttributeModal}
        successAddAttribute={successAddAttribute}
      />
    </Container>
  );
}
