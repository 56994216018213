import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Button, Grid } from "@material-ui/core";

function ConfirmationImportUsers({
  open,
  handleClose,
  CIUsershandle,
  totalusers,
  importing_users,
  success,
}) {
  return (
    <div>
      <Dialog
        id="userimport_dialog"
        open={open}
        onClose={importing_users ? () => {} : handleClose}
        aria-labelledby="Cnfirmation_import_users"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">{"Import Users"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {importing_users && !success
              ? "Importing..."
              : importing_users && success
              ? "Your request to import users will be handled in the background, and the results will be mailed to you in a few more minutes."
              : totalusers == 1
              ? "Are you sure you want to create " + totalusers + " user?"
              : totalusers > 1
              ? "Are you sure you want to create " + totalusers + " users?"
              : "Your user list is empty"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid item xs={6} md={8} />

          <Grid item xs={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  fullWidth
                  disabled={importing_users}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  onClick={success ? handleClose : CIUsershandle}
                  color="primary"
                  autoFocus
                  variant="contained"
                  fullWidth
                  disabled={!totalusers >= 1 || (importing_users && !success)}
                  startIcon={
                    importing_users && !success? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                >
                  {success ? "Accept" : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ConfirmationImportUsers.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  CIUsershandle: PropTypes.func,
  totalusers: PropTypes.number,
  importing_users: PropTypes.bool,
  success: PropTypes.bool,
};
export default ConfirmationImportUsers;
