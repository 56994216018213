import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * Create product_productAttribute
 * @param {int} id
 * @returns object
 */
export const createProductProductAttribute = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        createProductProductAttribute(
          input: {
            data: {
              ${values.product ? `product: ${values.product}` : ""}
              ${values.client ? `client: ${values.client}` : ""}
              
              product_attribute: ${values.product_attribute}
              display_order: ${values.display_order}
              required: ${values.required}
            }
          }
        ) {
          productProductAttribute {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete product_product_attribute
 * @param {int} id
 * @returns object
 */
export const deleteProductProductAttribute = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        deleteProductProductAttribute(input: { where: { id: ${id} } }) {
          productProductAttribute {
            id
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List all product_product_attributes by product_id
 * @param {int} id
 * @returns object
 */
export const getProductProductAttributesByProduct = async (
  id,
  client_id = null
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where:{product: ${id}${
    client_id ? ", client: " + client_id : ""
  }}`;
  axiosOptions.data = {
    query: `
      query {
        productProductAttributes(sort: "product_attribute.Name", ${where}) {
          id
          product_attribute{
            id
            Name
            ControlType
          }
          display_order
          required
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductProductAttribute = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  //Where id
  let where = `where:{id:${values.id}}`;
  //Update data
  axiosOptions.data = {
    query: `
  mutation{
    updateProductProductAttribute(
      input:{
        ${where}
        data:{
          display_order:${values.display_order}
          required:${Boolean(values.required)}
        }
      }
    ){
      productProductAttribute{
        id
      }
    }
  }
  `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
