import React from "react";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const ProductCardSkeleton = () => {
  return (
    <Card
      style={{
        cursor: "pointer",
        height: "511px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Skeleton variant="rect" height={300} />
      <CardContent>
        <Typography variant="h5" component="h2" noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          <Skeleton animation="wave" />
        </Typography>
      </CardContent>
      <CardActions style={{ marginTop: "auto" }}>
        <Skeleton
          width={82}
          style={{ marginLeft: "auto" }}
          variant="text"
          animation="wave"
        />
      </CardActions>
    </Card>
  );
};

export default ProductCardSkeleton;
