import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// API
import { createSchedulePoints } from "../api/Points";
import moment from "moment";

// Custom Styles
const useStyles = makeStyles(() => ({
  datePickerIconbutton: {
    "& div": {
      "& div": {
        "& > button": {
          marginRight: "-15px",
        },
      },
    },
  },
}));
function PointSchedulerDialog({
  // Open button props
  openButtonText = "Manage Points",
  openButtonVariant = "contained",
  openButtonColor = "primary",
  openButtonFullWidth = false,
  // Dialog props
  dialogTitle = "Manage Points",
  division_categories = [],
  setError,
  getSchedulePoints,
  client_id,
  userIds = null,
  clientFromDivisionCategory = false,
  // Close button props
  closeButtonText = "Cancel",
  closeButtonVariant = "contained",
  closeButtonColor = "default",
  // Confirm button props
  confirmButtonText = "Save",
  // confirmButtonClick = () => {},
  confirmButtonVariant = "contained",
  confirmButtonColor = "primary",
}) {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const classes = useStyles();

  // Open dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    formik.resetForm({
      values: initial_values,
    });
  };

  // Initial form data
  const initial_values = {
    division_category: "",
    add_set: "AddPoints",
    number_of_points: "",
    date: null,
    note: "",
    frequency: 0,
    frequency_limit: 1,
  };

  // Formik validation schema
  const validationSchema = yup.object({
    division_category: yup
      .string("Division Category")
      .required("Division category is required"),
    add_set: yup.string("Add or reset").required("Add or Reset is required"),
    number_of_points: yup
      .string("Points")
      .required("Number of points is required"),
    date: yup.date("Date").when("frequency", {
      is: (value) => value === "Anniversary",
      then: yup.date("Date").nullable(),
      otherwise: yup.date("Date").required("Date is required"),
    }),
    note: yup.string("Note"),
    frequency: yup.string("Frequency"),
    frequency_limit: yup.string("Frequency limit"),
  });

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (originalValues) => {
      // Clone the values object to avoid mutating the original
      const values = { ...originalValues };
      setSaving(true);
      // Search for the client in the division category
      if (clientFromDivisionCategory) {
        const divisionCategory = division_categories.find(
          (item) => item.id == values.division_category
        );
        const clientDivisionCategoryId =
          divisionCategory &&
          divisionCategory.clients &&
          Array.isArray(divisionCategory.clients) &&
          divisionCategory.clients.length != 0
            ? divisionCategory.clients[0].id
            : client_id;
        values.client_id = clientDivisionCategoryId;
      } else {
        values.client_id = client_id;
      }
      values.userIds = userIds;
      if (values.date) {
        values.date = moment(values.date);
        values.date = values.date.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        values.date = values.date.toISOString();
      }
      if (!values.division_category) {
        values.division_category = null;
      }
      // alert(JSON.stringify(values, null, 2));
      createSchedulePoints(values)
        .then((xhr) => {
          if (xhr && xhr.data && xhr.data.createQueueWorker) {
            handleClose();
            setError({
              open: true,
              message: "Scheduled points successfully",
              severity: "success",
            });
            formik.resetForm();
            getSchedulePoints();
          } else {
            let error_message =
              xhr && xhr.errors
                ? xhr.errors[0].message
                : "Schedule points error";
            setError({
              open: true,
              message: `Error: ${error_message}`,
              severity: "error",
            });
          }
        })
        .catch((xhr) => {
          console.log(xhr);
        })
        .finally(() => setSaving(false));
    },
  });

  return (
    <div style={{ display: "inline" }}>
      <Button
        variant={openButtonVariant}
        color={openButtonColor}
        onClick={handleClickOpen}
        fullWidth={openButtonFullWidth}
      >
        {openButtonText}
      </Button>
      <Dialog
        id="points_scheduler_dialog"
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit} id="points_scheduler_form">
          <DialogTitle id="points_scheduler_title">{dialogTitle}</DialogTitle>
          <DialogContent id="points_scheduler_content">
            <Grid container spacing={2} id="points_scheduler_container">
              {/* Add/Set */}
              <Grid item xs={12}>
                <FormControl
                  id="add_set_form_control"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  error={
                    formik.touched.add_set && Boolean(formik.errors.add_set)
                  }
                >
                  <InputLabel id="add_set_label">Add or Reset</InputLabel>
                  <Select
                    labelId="add_set_label"
                    label="Add or Reset"
                    id="add_set"
                    name="add_set"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    }}
                    value={formik.values.add_set}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="AddPoints">Add</MenuItem>
                    <MenuItem value="SetPoints">Reset</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.add_set && formik.errors.add_set}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* Division category */}
              <Grid item xs={12}>
                <FormControl
                  id="division_category_form_control"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  error={
                    formik.touched.division_category &&
                    Boolean(formik.errors.division_category)
                  }
                >
                  <InputLabel id="division_category_label">
                    Division Category
                  </InputLabel>
                  <Select
                    labelId="division_category_label"
                    label="Division Category"
                    id="division_category"
                    name="division_category"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    }}
                    value={formik.values.division_category}
                    onChange={formik.handleChange}
                  >
                    {division_categories.length == 0 ? (
                      <MenuItem disabled value={""}>
                        No options
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    <MenuItem value={0}>All products</MenuItem>
                    {division_categories.map((division_category) => (
                      <MenuItem
                        key={division_category.id}
                        value={division_category.id}
                      >
                        {division_category.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.division_category &&
                      formik.errors.division_category}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* Number of points */}
              <Grid item xs={12}>
                <TextField
                  id="number_of_points"
                  type="number"
                  inputProps={{
                    min: formik.values.add_set == "AddPoints" ? 1 : 0,
                  }}
                  size="small"
                  margin="dense"
                  label="Points"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.number_of_points}
                  fullWidth
                  error={
                    formik.touched.number_of_points &&
                    Boolean(formik.errors.number_of_points)
                  }
                  helperText={
                    formik.touched.number_of_points &&
                    formik.errors.number_of_points
                  }
                />
              </Grid>
              {/* Date */}
              {formik.values.frequency != "Anniversary" ? (
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.datePickerIconbutton}
                      disablePast
                      fullWidth
                      inputVariant="outlined"
                      size="small"
                      margin="dense"
                      placeholder="mm/dd/yyyy"
                      id="date-picker-dialog"
                      label="Date"
                      format="MM/dd/yyyy"
                      value={formik.values.date}
                      onChange={(date) => {
                        formik.handleChange({
                          target: { name: "date", value: date },
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              ) : null}
              {/* Note */}
              <Grid item xs={12}>
                <TextField
                  id="note"
                  label="Note"
                  placeholder="Note"
                  multiline
                  rows={3}
                  margin="dense"
                  onChange={formik.handleChange}
                  value={formik.values.note}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              {/* Repetition */}
              <React.Fragment>
                {/* Frequency */}
                <Grid item xs={12}>
                  <FormControl
                    id="frequency_form_control"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    error={
                      formik.touched.frequency &&
                      Boolean(formik.errors.frequency)
                    }
                  >
                    <InputLabel id="frequency_label">Frequency</InputLabel>
                    <Select
                      labelId="frequency_label"
                      label="Frequency"
                      id="frequency"
                      name="frequency"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      }}
                      value={formik.values.frequency}
                      onChange={(event) => {
                        // Handle the change
                        formik.handleChange(event);
                        if (event.target.value == "Anniversary") {
                          // Reset the date value
                          formik.setFieldValue("date", null);
                          // Reset the frequency_limit value
                          formik.setFieldValue("frequency_limit", 1);
                        }
                      }}
                    >
                      <MenuItem value={0}>No repetition</MenuItem>
                      <MenuItem value={"Weekly"}>Weekly</MenuItem>
                      <MenuItem value={"Monthly"}>Monthly</MenuItem>
                      <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
                      <MenuItem value={"Yearly"}>Yearly</MenuItem>
                      <MenuItem value={"Anniversary"}>Anniversary</MenuItem>
                    </Select>
                    <FormHelperText>
                      {formik.touched.frequency && formik.errors.frequency}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Frequency interval */}
                {formik.values.frequency &&
                formik.values.frequency != "Anniversary" ? (
                  <Grid item xs={12}>
                    <TextField
                      id="frequency_limit"
                      type="number"
                      inputProps={{ min: 1 }}
                      size="small"
                      margin="dense"
                      label="Frequency interval"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.frequency_limit}
                      fullWidth
                      error={
                        formik.touched.frequency_limit &&
                        Boolean(formik.errors.frequency_limit)
                      }
                      helperText={
                        formik.touched.frequency_limit &&
                        formik.errors.frequency_limit
                      }
                    />
                  </Grid>
                ) : null}
              </React.Fragment>
              {/* Buttons */}
              <Grid item xs={12}>
                {/* Aligned to the right */}
                <Grid container justify="flex-end">
                  {/* Cancel button */}
                  <Button
                    onClick={handleClose}
                    variant={closeButtonVariant}
                    color={closeButtonColor}
                  >
                    {closeButtonText}
                  </Button>
                  {/* Save button */}
                  <Button
                    type="submit"
                    variant={confirmButtonVariant}
                    color={confirmButtonColor}
                    style={{ marginLeft: 10 }}
                    autoFocus
                    disabled={saving}
                  >
                    {confirmButtonText}
                  </Button>
                </Grid>
              </Grid>
              {/* .......... */}
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

PointSchedulerDialog.propTypes = {
  openButtonText: PropTypes.string,
  openButtonVariant: PropTypes.string,
  openButtonColor: PropTypes.string,
  openButtonFullWidth: PropTypes.bool,
  dialogTitle: PropTypes.string,
  division_categories: PropTypes.array,
  setError: PropTypes.func,
  getSchedulePoints: PropTypes.func,
  client_id: PropTypes.number,
  dialogContentText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  closeButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonClick: PropTypes.func,
  confirmButtonVariant: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  userIds: PropTypes.array,
  clientFromDivisionCategory: PropTypes.bool,
};

export default PointSchedulerDialog;
