import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * creates a staged invoice with initial product
 * @param {int} lineItemsId
 * @returns
 */
export const createStagedInvoice = async (lineItemId) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let store_user = JSON.parse(localStorage.getItem("store_user"));
  let user =
    store_user && store_user.id ? store_user.id : localStorage.getItem("user");

  axiosOptions.data = {
    query: `
          mutation{
              createStagedInvoice(
                  input:{
                      data:{
                          create_user: ${user},
                          staged_line_items:${lineItemId}
                      }
                  }
              )
            {
              stagedInvoice{
                  id
              }
            }
          }
        `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * creates a staged invoice with initial product
 * @param {int} lineItemsId
 * @returns
 */
export const createStagedLineItem = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
        createStagedLineItem(
            input:{
                data:{
                    Quantity:${values.Quantity},
                    product:${values.ProductId},
                    division_category:${values.DivisionCategoryId}
                    LineItemJson: ${JSON.stringify(values).replace(
                      /"(\w+)"\s*:/g,
                      "$1:"
                    )}
                }
            }
        )
          {
            stagedLineItem{
                id
            }
          }
        
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUserStagedInvoices = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let store_user = JSON.parse(localStorage.getItem("store_user"));
  let user =
    store_user && store_user.id ? store_user.id : localStorage.getItem("user");
  axiosOptions.data = {
    query: `
      query{
        stagedInvoices(
          publicationState: PREVIEW
          where:{
            create_user: [${user}]
            order_invoice_null: true
          },
        )
        {
          id
          InvoiceId
          order_invoice{
            Status
          }
          staged_line_items(
            where: { division_category_null: false, product: { id_null: false } }
          ){
            id
            Quantity
            LineItemJson
            product{
              id
              Name
            }
          }
        } 
      }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * update a staged invoice with staged line items
 * @param {int} lineItemsId
 * @returns
 */
export const updateStagedInvoice = async (invoice) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
            updateStagedInvoice(
            input:{
              where:{id:${invoice.id}}
                data:{
                    staged_line_items:[${invoice.lineItems}]
                }
            }
          )
          {
            stagedInvoice{
              id
              staged_line_items{
                Quantity
                id
                LineItemJson
              }
            }
          }
        }
        `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update a staged item, only quantity can be updates, as of now if the item have new attributes a new lineItems must be created
 * @param {int} lineItemsId
 * @returns
 */
export const updateStagedLineItem = async (
  updateId,
  values,
  update_line_item_json = false
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
        updateStagedLineItem(
            input:{
              where:{id:${updateId}}
              data:{
                  Quantity:${values.Quantity},
                  ${
                    values.DivisionCategoryId
                      ? "division_category:" + values.DivisionCategoryId
                      : ""
                  }
                  ${
                    update_line_item_json
                      ? `LineItemJson: ${JSON.stringify(values).replace(
                          /"(\w+)"\s*:/g,
                          "$1:"
                        )}`
                      : ""
                  }
              }
            }
        )
        {
          stagedLineItem{
            Quantity
            id
            LineItemJson
          }
        }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
