import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * Created customer related to given user
 * @param {int} user_id
 * @returns
 */
export const createUserCustomer = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation {
      createCustomer(
        input: {
          data: {
            FirstName: "${values.first_name}"
            LastName: "${values.last_name}"
            billing_address: ${values.address_id}
            shipping_address: ${values.address_id}
            ConfirmationEmail: "${values.email}"
            Phone: ${values.phone}
            ${
              values.user_id
                ? `user: ${values.user_id}`
                : `client: ${values.client_id}`
            }
          }
        }
      ) {
        customer {
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * update a customer
 * @param {int} user_id
 * @returns
 */
export const updateUserCustomer = async (id, values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation {
      updateCustomer(
        input: {
          where:{id:${id}}
          data: {
            FirstName: "${values.first_name}"
            LastName: "${values.last_name}"
            billing_address: ${values.address_id}
            shipping_address: ${values.address_id}
            ConfirmationEmail: "${values.email}"
            Phone: ${values.phone}
            ${
              values.user_id
                ? `user: ${values.user_id}`
                : `client: ${values.client_id}`
            }
          }
        }
      ) {
        customer {
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * delete a customer
 * @param {*} id
 * @returns
 */
export const deleteCustomer = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          deleteCustomer(
            input:{
              where:{id:${id}},
            }
          )
          {
            customer{
              id
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List customers related to given user_id
 * @param {int} user_id
 * @returns
 */
export const listUserCustomers = async (user_id, client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where:{
    ${user_id ? `user:${user_id}` : ""}
    ${client_id ? `client:[${client_id}]` : ""}
  }`;
  axiosOptions.data = {
    query: `
    query{
      customers(${where}){
        id
        FirstName
        LastName
        ConfirmationEmail
        Phone
        address: billing_address{
          id
          Street
          Street2
          City
          State
          Zip
          Country
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List customers related to given user_id
 * @param {int} user_id
 * @returns
 */
export const listClientCustomers = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query{
      customers(where:{client:${client_id}}){
        id
        FirstName
        LastName
        ConfirmationEmail
        Phone
        address: billing_address{
          id
          Street
          Street2
          City
          State
          Zip
          Country
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
