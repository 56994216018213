// Libraries
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

// Custom components

// API

// Styles
const useStyles = makeStyles(() => ({
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    // marginTop: -12,
    marginLeft: 12,
  },
}));

// Main component
const CreditCardDetails = ({
  formik,
  saving,
  card_required,
  customAddresses,
  addresses,
}) => {
  const classes = useStyles();
  let current_date = new Date();
  // Years
  let years = [];
  for (let i = 0; i < 10; i++) {
    years.push(current_date.getFullYear() + i);
  }
  // Months
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  // View
  return (
    <Paper id="paper_credit_card" elevation={3}>
      {card_required ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            <Box fontWeight="fontWeightBold">Credit Card Details</Box>
          </Typography>
          <Divider />
          <Grid
            id="grid_container_credit_card_fields"
            container
            spacing={2}
            // style={{ padding: "20px" }}
            // className={classes.papers}
          >
            {/* Credit Card Number */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="credit_card_number"
                type="number"
                inputProps={{ min: 1 }}
                size="small"
                margin="none"
                label="Credit Card Number"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.credit_card_number}
                error={
                  formik.touched.credit_card_number &&
                  Boolean(formik.errors.credit_card_number)
                }
                helperText={
                  formik.touched.credit_card_number &&
                  formik.errors.credit_card_number
                }
              />
            </Grid>
            {/* Expiration Year */}
            <Grid item xs={12} md={3}>
              <FormControl
                id="expiration_year_form_control"
                // variant="outlined"
                size="small"
                margin="none"
                fullWidth
                error={
                  formik.touched.expiration_year &&
                  Boolean(formik.errors.expiration_year)
                }
              >
                <InputLabel id="year_label">Expiration Year</InputLabel>
                <Select
                  labelId="year_label"
                  label="Expiration Year"
                  id="expiration_year"
                  name="expiration_year"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={formik.values.expiration_year}
                  onChange={formik.handleChange}
                >
                  {years.length == 0 ? (
                    <MenuItem disabled value={0}>
                      No options
                    </MenuItem>
                  ) : null}
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.expiration_year &&
                    formik.errors.expiration_year}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Expiration Month */}
            <Grid item xs={12} md={3}>
              <FormControl
                id="expiration_month_form_control"
                // variant="outlined"
                size="small"
                margin="none"
                fullWidth
                error={
                  formik.touched.expiration_month &&
                  Boolean(formik.errors.expiration_month)
                }
              >
                <InputLabel id="month_label">Expiration Month</InputLabel>
                <Select
                  labelId="month_label"
                  label="Expiration Month"
                  id="expiration_month"
                  name="expiration_month"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={formik.values.expiration_month}
                  onChange={formik.handleChange}
                >
                  {months.length == 0 ? (
                    <MenuItem disabled value={0}>
                      No options
                    </MenuItem>
                  ) : null}
                  {months.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.expiration_month &&
                    formik.errors.expiration_month}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Security Code */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                id="security_code"
                type="text"
                inputProps={{ min: 1 }}
                size="small"
                margin="none"
                label="Security Code"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.security_code}
                error={
                  formik.touched.security_code &&
                  Boolean(formik.errors.security_code)
                }
                helperText={
                  formik.touched.security_code && formik.errors.security_code
                }
              />
            </Grid>
            {/* ---------- */}
          </Grid>
          <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
        </React.Fragment>
      ) : null}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={
          saving || (!customAddresses && addresses.length == 0) ? true : false
        }
      >
        Place Order
      </Button>
      {saving && <CircularProgress size={36.5} className={classes.buttonProgress} />}
    </Paper>
  );
};

CreditCardDetails.propTypes = {
  formik: PropTypes.object,
  saving: PropTypes.bool,
  card_required: PropTypes.bool,
  customAddresses: PropTypes.bool,
  addresses: PropTypes.array,
};

export default CreditCardDetails;
