// Libraries
import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

// API

// Style for color contrast in the selector
const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
  },
}));

export function ClientSelector({
  clients,
  client,
  selectedClient,
  clientData = function () {},
}) {
  const classes = useStyles();
  const history = useHistory();
  if (clients.length == 0) {
    const noClient = {
      id: 0,
      name: "No clients to display",
    };
    clients.push(noClient);
    selectedClient = noClient;
  }
  return (
    <Autocomplete
      id="autocomplete"
      autoComplete
      autoHighlight
      clearOnEscape
      fullWidth
      loading={clients.length <= 0}
      value={client}
      classes={{
        inputRoot: classes.root,
        popupIndicator: classes.root,
      }}
      disableClearable
      options={clients}
      getOptionLabel={(option) => option.name}
      size="small"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            // label="Reseller"
            // variant="outlined"
            // InputProps={{
            //   classes: {
            //     // root: classes.cssOutlinedInput,
            //     // focused: classes.cssFocused,
            //     notchedOutline: classes.root,
            //   },
            // }}
            // classes={{
            //   root: classes.root,
            //   // notchedOutline: classes.root,
            // }}
          />
        );
      }}
      // Changing client
      onChange={(event, newValue) => {
        selectedClient(newValue);
        clientData(newValue);
        //Set client_id to localstorage
        localStorage.setItem("client_id", newValue.id);
        //get user role
        const role = localStorage.getItem("role");
        //validated user role
        if (newValue.id != 0) {
          switch (role) {
            case "customer":
              history.push("/store/products");
              break;
            case "clientorderapprover":
              history.push(`/app/client/${newValue.id}/store/products`);
              break;
            case "clientusermanager":
              history.push(`/app/client/${newValue.id}/users`);
              break;
            case "clientproductmanager":
              history.push(`/app/client/${newValue.id}/products`);
              break;
            case "clientadmin":
              history.push(`/app/client/${newValue.id}`);
              break;
            default:
              break;
          }
        }
      }}
    />
  );
}

ClientSelector.propTypes = {
  clients: PropTypes.array,
  client: PropTypes.object,
  selectedClient: PropTypes.func,
  clientData: PropTypes.func,
};
