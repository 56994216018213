import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * List Products attributes
 * @param {Int} offset
 * @return {Array} Product attributes
 */
export const GetAttributeTemplates = async (offset) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  const reseller_id = localStorage.getItem("reseller_id");
  const where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
      query{
        attributeTemplates(limit:20, start:${offset} sort:"id:DESC" ${where}){
          id
          Name
          reseller{
            id
          }
          product_product_attributes{
            id
            required
            display_order
            product_attribute{
              id
              Name
              ControlType
              DisplayText
              product_attribute_values{
                id
                Name
                DisplayOrder
                CostAdjustment
                SkuExtension
              }
            }
          }
        }
      }`,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Delete attribute value
 * @param {int} id
 * @returns object
 */
export const deleteAttributeValue = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        deleteProductAttributeValue(input: {where: {id:${id}}}){
          productAttributeValue{
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete attribute
 * @param {int} id
 * @returns object
 */
export const deleteAttribute = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      deleteProductAttribute(input:{where:{id:${id}}}){
        productAttribute{
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete attribute - template
 * @param {int} id
 * @returns object
 */
export const deleteAttributeTemplate = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      deleteProductProductAttribute(input:{where:{id:${id}}}){
        productProductAttribute{
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete attribute - template
 * @param {int} id
 * @returns object
 */
export const deleteTemplate = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      deleteAttributeTemplate(input:{where:{id:${id}}}){
        attributeTemplate{
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Create new AttributesTemplate
 * @param {Object} values
 */
export const createAttributesTemplate = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  //Get attributes and values
  let attributesValues = "";
  if (values && values.attributes && values.attributes.length > 0) {
    let attributes = values.attributes;
    attributes = attributes.map((attribute) => {
      let attributeValues =
        attribute.values && attribute.values.length > 0 ? attribute.values : [];
      attributeValues = attributeValues.map((value) => {
        return `{
          Name: "${value.Name}"
          DisplayOrder: ${value.DisplayOrder}
          CostAdjustment: ${value.CostAdjustment}
          SkuExtension: "${value.SkuExtension}"
        }`;
      });
      attributeValues = attributeValues.join(", ");
      return `{
        Name: "${attribute.Name}"
        ControlType: ${attribute.ControlType}
        DisplayText: "${attribute.DisplayText}"
        display_order: ${attribute.display_order}
        required: ${attribute.required}
        values:[${attributeValues}]
      }`;
    });
    attributesValues = attributes.join(", ");
  }
  axiosOptions.data = {
    query: `
    mutation {
      createAttributesTemplate(
        input: {
          data: {
            Name: "${values.Name}"
            reseller: ${values.reseller}
            attributes: [${attributesValues}]
          }
        }
      ) {
          id
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateAttributesTemplate = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  //Get attributes and values
  let attributesValues = "";
  if (values && values.attributes && values.attributes.length > 0) {
    let attributes = values.attributes;
    attributes = attributes.map((attribute) => {
      let attributeValues =
        attribute.values && attribute.values.length > 0 ? attribute.values : [];
      attributeValues = attributeValues.map((value) => {
        if (value.id != undefined) {
          return `{
            id: ${value.id}
            Name: "${value.Name}"
            DisplayOrder: ${value.DisplayOrder}
            CostAdjustment: ${value.CostAdjustment}
            SkuExtension: "${value.SkuExtension}"
          }`;
        } else {
          return `{
            Name: "${value.Name}"
            DisplayOrder: ${value.DisplayOrder}
            CostAdjustment: ${value.CostAdjustment}
            SkuExtension: "${value.SkuExtension}"
          }`;
        }
      });
      attributeValues = attributeValues.join(", ");
      if (attribute.id != undefined) {
        return `{
          id: ${attribute.id}
          Name: "${attribute.Name}"
          ControlType: ${attribute.ControlType}
          DisplayText: "${attribute.DisplayText}"
          display_order: ${attribute.display_order}
          required: ${attribute.required}
          values:[${attributeValues}]
        }`;
      } else {
        return `{
          Name: "${attribute.Name}"
          ControlType: ${attribute.ControlType}
          DisplayText: "${attribute.DisplayText}"
          display_order: ${attribute.display_order}
          required: ${attribute.required}
          values:[${attributeValues}]
        }`;
      }
    });
    attributesValues = attributes.join(", ");
  }
  axiosOptions.data = {
    query: `
    mutation {
      updateAttributesTemplate(
        input: {
          where:{id: ${values.id}},
          data: {
            Name: "${values.Name}"
            reseller: ${values.reseller}
            attributes: [${attributesValues}]
          }
        }
      ) {
        id
        Name
        reseller {
          id
        }
 product_product_attributes{
          id
          required
          display_order
          product_attribute{
            id
            Name
            ControlType
            DisplayText
          product_attribute_values{
              id
              Name
              DisplayOrder
              CostAdjustment
              SkuExtension
            }
          }
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List attributes for select
 */
export const AttributeSelectTemplates = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  const reseller_id = localStorage.getItem("reseller_id");
  const where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
    query{
      attributeTemplates(${where}){
        id
          Name
          reseller{
            id
          }
          product_product_attributes{
            id
            required
            display_order
            product_attribute{
              id
              Name
              ControlType
              DisplayText
            }
          }
        }
  }`,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
