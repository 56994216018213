import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Hidden,
  Breadcrumbs,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Container } from "@material-ui/core";
import {
  deleteProductAttributeValue,
  getProductAttributeInfo,
  updateProductAttribute,
} from "../api/Products";
import { updateProductProductAttribute } from "../api/ProductProductAttributes";
import Skeleton from "@material-ui/lab/Skeleton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import ProductAttributeValuesModal from "../components/products/ProductAttributeValuesModal";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "DisplayOrder",
    disablePadding: false,
    label: "Display Order",
  },
  {
    id: "CostAdjustment",
    disablePadding: false,
    label: "Cost Adjustment",
  },
  {
    id: "SkuExtension",
    disablePadding: false,
    label: "SKU Extension",
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label} </b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root} color={"secondary"}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Product Attribute Values
      </Typography>

      <Tooltip title="Add Attribute">
        <IconButton
          aria-label="Add Attribute"
          onClick={props.toggleAttributeModal}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  toggleAttributeModal: PropTypes.func.isRequired,
};

const EnhancedPADTableToolbar = ({
  formik,
  classes,
  ControlType,
  setControlType,
}) => {
  const toolbarstyles = useToolbarStyles();

  const ControlTypes = ["dropdown", "number", "radio", "text"];
  useEffect(() => {
    setControlType(formik.values.ControlType);
  }, [formik]);

  return (
    <div>
      <Toolbar className={toolbarstyles.root} color={"secondary"}>
        <Typography
          className={toolbarstyles.title}
          variant="h6"
          id="tableTitlePAD"
          component="div"
        >
          Product Attribute Details
        </Typography>
      </Toolbar>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {/**Product attribute Name */}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              size="small"
              id="Name"
              margin="dense"
              label="Name"
              variant="outlined"
              value={formik.values.Name}
              onChange={formik.handleChange}
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={formik.touched.Name && formik.errors.Name}
            />
          </Grid>
          {/**Product attribute Display text */}
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              size="small"
              id="DisplayText"
              margin="dense"
              label="Display Text"
              variant="outlined"
              value={formik.values.DisplayText}
              onChange={formik.handleChange}
              error={
                formik.touched.DisplayText && Boolean(formik.errors.DisplayText)
              }
              helperText={
                formik.touched.DisplayText && formik.errors.DisplayText
              }
            />
          </Grid>
          {/**Product attribute Display order */}
          <Grid item xs={12} lg={6}>
            <TextField
              id="display_order"
              type="number"
              inputProps={{ min: 1 }}
              size="small"
              margin="dense"
              label="Display Order"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.display_order}
              fullWidth
              error={
                formik.touched.display_order &&
                Boolean(formik.errors.display_order)
              }
              helperText={
                formik.touched.display_order && formik.errors.display_order
              }
            />
          </Grid>
          {/**Product attribute Requiered */}
          <Grid item xs={12} lg={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.required}
                    onChange={formik.handleChange}
                    name="required"
                    color="primary"
                  />
                }
                label="Required"
              />
            </FormGroup>
          </Grid>
          {/**Produc attribute Control type */}
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              size="small"
              id="ControlType"
              select
              margin="dense"
              label="Control Type"
              variant="outlined"
              onChange={(event) => {
                setControlType(event.target.value);
                formik.values.ControlType = event.target.value;
              }}
              defaultValue={ControlType}
              value={ControlType}
              error={
                formik.touched.ControlType && Boolean(formik.errors.ControlType)
              }
              helperText={
                formik.touched.ControlType && formik.errors.ControlType
              }
            >
              {ControlTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item xs={1} lg={9} />
          </Hidden>
          <Grid item xs={12} lg={3}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

//EnhancedPADTableToolbar
EnhancedPADTableToolbar.propTypes = {
  formik: PropTypes.object,
  classes: PropTypes.object,
  ControlType: PropTypes.string,
  setControlType: PropTypes.func,
};

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  Name: yup.string("Atribute Name").required("Atribute Name is required"),
  DisplayText: yup.string("Atribute Display Text"),
  display_order: yup.string("Produt attribute Display Order"),
  required: yup.string("Produt attribute required"),
  ControlType: yup.string("Atrubute Control Type"),
});

/**
 * Initial data
 */
const initialPADdata = {
  id: null,
  Name: "",
  DisplayText: "",
  display_order: "",
  required: "",
  ControlType: "",
  product_product_attribute_id: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  mainContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "80px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
      marginTop: "85px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "110px",
    },
  },
  tableCell: {
    paddingLeft: "16px",
    paddingRight: "8px",
  },
  tableCellName: {
    paddingLeft: "16px",
    paddingRight: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  form: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

export default function ProductAttributeDetails() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [page, setPage] = React.useState(0);
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  let history = useHistory();
  const [ControlType, setControlType] = useState("");
  let { attribute_id } = useParams();

  const [attributeModalOpen, setAttributeModalOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  /**Initial loading */
  useEffect(() => {
    // List products
    getProductAttributeDetails();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, attributes.length - page * rowsPerPage);

  const getProductAttributeDetails = () => {
    getProductAttributeInfo(attribute_id).then((result) => {
      //load PAD(Producto attrbute details)
      let PAD = result.data.productAttribute;
      initialPADdata.id = PAD.id;
      initialPADdata.Name = PAD.Name;
      initialPADdata.DisplayText = PAD.DisplayText;
      initialPADdata.display_order =
        PAD &&
        PAD.product_product_attributes &&
        Array.isArray(PAD.product_product_attributes) &&
        PAD.product_product_attributes[0].display_order
          ? PAD.product_product_attributes[0].display_order
          : "";
      initialPADdata.required =
        PAD &&
        PAD.product_product_attributes &&
        Array.isArray(PAD.product_product_attributes) &&
        PAD.product_product_attributes[0].required
          ? PAD.product_product_attributes[0].required
          : false;
      initialPADdata.product_product_attribute_id =
        PAD &&
        PAD.product_product_attributes &&
        Array.isArray(PAD.product_product_attributes) &&
        PAD.product_product_attributes[0].id
          ? PAD.product_product_attributes[0].id
          : null;
      initialPADdata.ControlType = PAD.ControlType;
      //load product attribute values
      let attributeDetails =
        result.data.productAttribute.product_attribute_values;
      attributeDetails.forEach((element) => {
        if (element.SkuExtension == null) {
          element.SkuExtension = "";
        }
      });
      setAttributes(attributeDetails);
      setLoading(false);
    });
  };

  const toggleDeleteModal = (attr) => {
    setOpenDeleteModal(true);
    setSelectedValue(attr);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedValue({});
  };

  //close snackbar message
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };
  const handleDeleteValue = () => {
    deleteProductAttributeValue(selectedValue.id).then((res) => {
      if (res.data.deleteProductAttributeValue) {
        setError({
          message: "Product attribute value deleted.",
          severity: "success",
          open: true,
        });
        closeDeleteModal();
        getProductAttributeDetails();
      } else if (res.response.data.errors.length) {
        setError({
          message: "An error ocurred",
          severity: "error",
          open: true,
        });
      }
    });
  };

  const successAddAttribute = () => {
    setError({
      message: "Product attribute value created successfully.",
      severity: "success",
      open: true,
    });
  };

  const successUpdateAttribute = () => {
    setError({
      message: "Changes saved successfully.",
      severity: "success",
      open: true,
    });
  };

  const toggleAttributeModal = (reloadAttributes, value = false) => {
    if (value) {
      setSelectedValue(value);
    } else {
      setSelectedValue({});
    }
    if (reloadAttributes) {
      getProductAttributeDetails();
    }
    setAttributeModalOpen(!attributeModalOpen);
  };

  /**
   * Use Formik
   */
  const formik = useFormik({
    initialValues: initialPADdata,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateProductAttribute(values)
        .then((xhr) => {
          if (xhr.error) {
            setError({
              open: true,
              message: `Error: ${xhr.errors[0].message}`,
              severity: "error",
            });
          } else {
            const product_product_attribute = {
              id: initialPADdata.product_product_attribute_id,
              display_order: values.display_order,
              required: values.required,
            };
            updateProductProductAttribute(product_product_attribute).then(
              (result) => {
                if (
                  result &&
                  result.data &&
                  result.data.updateProductProductAttribute &&
                  result.data.updateProductProductAttribute
                    .productProductAttribute &&
                  result.data.updateProductProductAttribute
                    .productProductAttribute.id
                ) {
                  getProductAttributeDetails();
                  setError({
                    message: "Product attribute updated successfully.",
                    severity: "success",
                    open: true,
                  });
                }
              }
            );
          }
        })
        .catch((xhr) => {
          console.log(xhr);
        });
    },
  });

  return (
    <Container className={classes.mainContainer} maxWidth="xl">
      <Grid container spacing={3} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              color="textSecondary"
              onClick={() => {
                //go back to the product attribute owner product
                //let owner = localStorage.getItem("productAttributeOwner");
                let prevPath = localStorage.getItem("previousPath");
                history.push(`${prevPath}`);
              }}
              style={{ cursor: "pointer" }}
            >
              Product Attributes
            </Link>

            <Typography color="textSecondary">{formik.values.Name}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <EnhancedPADTableToolbar
          formik={formik}
          classes={classes}
          ControlType={ControlType}
          setControlType={setControlType}
        />
        {formik.values.ControlType !== "number" &&
        formik.values.ControlType !== "text" ? (
          <div>
            <EnhancedTableToolbar toggleAttributeModal={toggleAttributeModal} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={attributes.length}
                />
                <TableBody>
                  {loading
                    ? [1, 2, 3, 4, 5].map((index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={classes.tableCellName}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : stableSort(attributes, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow hover tabIndex={-1} key={row.id}>
                              <TableCell
                                className={classes.tableCellName}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.Name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.DisplayOrder}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                $ {row.CostAdjustment}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                {row.SkuExtension}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableCell}
                              >
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    toggleAttributeModal(false, row)
                                  }
                                />
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggleDeleteModal(row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={attributes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          ""
        )}
      </Paper>
      <ConfirmationDialog
        dialogTitle="Delete product attribute value"
        dialogContentText="Product attribute value will be deleted, continue?"
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        open={openDeleteModal}
        handleClose={closeDeleteModal}
        confirmButtonClick={handleDeleteValue}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
      <ProductAttributeValuesModal
        modalOpen={attributeModalOpen}
        toggleModal={toggleAttributeModal}
        successAddAttribute={successAddAttribute}
        successUpdateAttribute={successUpdateAttribute}
        atribute_id={attribute_id}
        selectedValue={selectedValue}
      />
    </Container>
  );
}
