import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";

// Custom components
import PointSchedulerDialog from "../PointSchedulerDialog";
import AlertDialog from "../AlertDialog";

// API
import { listSchedulePoints, deleteSchedulePoints } from "../../api/Points";
import { Skeleton } from "@material-ui/lab";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    maxHeight: "390px",
    whiteSpace: "nowrap",
    overflow: "auto",
  },
}));

function ClientSheduler(propts) {
  const classes = useStyles();
  const [schedule_points, setSchedulePoints] = useState([]);

  const getSchedulePoints = () => {
    listSchedulePoints(propts.client_id).then((result) => {
      setSchedulePoints(result.data.queueWorkers);
    });
  };

  // Get schedule_points when client_id changes
  useEffect(() => {
    let isMounted = true;
    listSchedulePoints(propts.client_id).then((result) => {
      if (isMounted) {
        setSchedulePoints(result.data.queueWorkers);
      }
      return () => {
        isMounted = false;
      };
    });
  }, [propts.client_id]);

  return (
    <div>
      <Paper className={classes.paper}>
        {propts.loading ? (
          // Skeleton
          <Grid container spacing={2}>
            {/* Title */}
            <Grid id="grid_title" xs={12} item sm={6} md={6}>
              <Skeleton height={41.6} />
            </Grid>
            {/* Schedule points button */}
            <Grid id="grid_button" item xs={12} sm={6} md={6}>
              <Hidden smUp>
                <Skeleton height={36} />
              </Hidden>
              <Hidden xsDown>
                <Grid container alignItems="center" justify="flex-end">
                  <Skeleton height={36} width={138.25} />
                </Grid>
              </Hidden>
            </Grid>
            {/* Render entries */}
            {[1,2].map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6}>
                  <Skeleton height={32} width='50%' />
                  <Skeleton height={29.6} width='25%' />
                  <Skeleton height={29.6} width='75%' />
                  <Skeleton height={29.6} width='50%' />
                  {/* Delete button */}
                  <Grid container justify={"flex-end"}>
                    <Skeleton height={36} width='25%' />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          // Container
          <Grid container spacing={2}>
            {/* Title */}
            <Grid id="grid_title" xs={12} item sm={6} md={6}>
              <Typography variant="h4">Point Manager</Typography>
            </Grid>
            {/* Schedule points button */}
            <Grid id="grid_button" item xs={12} sm={6} md={6}>
              <Hidden smUp>
                <PointSchedulerDialog
                  openButtonFullWidth
                  division_categories={propts.division_categories}
                  setError={propts.setError}
                  getSchedulePoints={getSchedulePoints}
                  client_id={Number(propts.client_id)}
                />
              </Hidden>
              <Hidden xsDown>
                <Grid container alignItems="center" justify="flex-end">
                  <PointSchedulerDialog
                    division_categories={propts.division_categories}
                    setError={propts.setError}
                    getSchedulePoints={getSchedulePoints}
                    client_id={Number(propts.client_id)}
                  />
                </Grid>
              </Hidden>
            </Grid>
            {/* Render entries */}
            {schedule_points.length > 0 ? (
              <React.Fragment>
                {schedule_points.map((points, index) => {
                  let division_category = propts.division_categories.find(
                    (dc) => dc.id == points.JsonData.DivisionCategoryId
                  );
                  return (
                    <Grid key={index} item xs={12} sm={6}>
                      <Typography variant="h5" component="h2" noWrap={true}>
                        {points.Type == "AddPoints"
                          ? "Add Points"
                          : "Reset Points"}
                        :
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Points: {points.JsonData.Points}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Division:{" "}
                        {division_category ? division_category.Name : "-"}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        noWrap={true}
                      >
                        Repeat: {points.RepeatType ? points.RepeatType : "No repetition"}
                      </Typography>
                      {/* Delete button */}
                      <Grid container justify={"flex-end"}>
                        <AlertDialog
                          dialogTitle="Delete Scheduled Points"
                          dialogContentText="Scheduled points will be removed. Do you want to continue?"
                          confirmButtonClick={() => {
                            deleteSchedulePoints(points.id).then(() => {
                              getSchedulePoints();
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </React.Fragment>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h5">No points scheduled</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    </div>
  );
}

ClientSheduler.propTypes = {
  contact_user: PropTypes.object,
  loading: PropTypes.bool,
};

export default ClientSheduler;
