import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  IconButton,
  TableBody,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const headCells = [
  {
    id: "Name",
    disablePadding: true,
    label: "Name",
  },
  {
    id: "DisplayOrder",
    disablePadding: false,
    label: "Display Order",
  },
  {
    id: "CostAdjustment",
    disablePadding: false,
    label: "Cost Adjustment",
  },
  {
    id: "SkuExtension",
    disablePadding: false,
    label: "SKU Extension",
  },
];

export default function TableValueAtrribute(props) {
  const addProductAttributteValue = (
    isExits,
    posicionAttrubute,
    positionValue = false
  ) => {
    if (isExits) {
      props.setSelectedValue({
        id: isExits.id,
        Name: isExits.Name,
        DisplayOrder: isExits.DisplayOrder,
        CostAdjustment: isExits.CostAdjustment,
        SkuExtension: isExits.SkuExtension,
        position: posicionAttrubute,
        positionValue: positionValue,
      });
    } else {
      props.setSelectedValue({
        Name: "",
        DisplayOrder: "",
        CostAdjustment: "",
        SkuExtension: "",
        position: posicionAttrubute,
      });
    }
    props.setAttributeModalOpen(true);
  };

  const toggleDeleteModal = (attrValue, posicionAttrubute, positionValue) => {
    props.setOpenDeleteModal(true);
    props.setSelectedValue({
      Name: attrValue.Name,
      DisplayOrder: attrValue.DisplayOrder,
      CostAdjustment: attrValue.CostAdjustment,
      SkuExtension: attrValue.SkuExtension,
      position: posicionAttrubute,
      positionValue: positionValue,
    });
  };

  return (
    <Grid xs={12} md={5}>
      <Grid item xs={12} md={7} style={{ marginTop: "20px" }}>
        <Typography variant="h6">Values</Typography>
      </Grid>
      <div style={{ width: "125%" }}>
        <TableContainer>
          <Table
            className={props.classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead style={{ background: "#e0e0e0" }}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={"left"}>
                    <TableSortLabel direction="asc">
                      <b>{headCell.label} </b>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>
                  <Tooltip title="Add value">
                    <IconButton
                      aria-label="Add value"
                      onClick={() =>
                        addProductAttributteValue(false, props.index)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props.attribute.values.map((row, indexValue) => (
                <TableRow key={row}>
                  <TableCell>{row.Name}</TableCell>
                  <TableCell>{row.DisplayOrder}</TableCell>
                  <TableCell>{"$" + " " + row.CostAdjustment}</TableCell>
                  <TableCell>{row.SkuExtension}</TableCell>
                  <TableCell>
                    <EditIcon
                      style={{
                        cursor: "pointer",
                        color: "darkgray",
                      }}
                      onClick={() =>
                        addProductAttributteValue(row, props.index, indexValue)
                      }
                    />
                    <DeleteIcon
                      style={{
                        cursor: "pointer",
                        color: "darkgray",
                      }}
                      onClick={() =>
                        toggleDeleteModal(row, props.index, indexValue)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
}

TableValueAtrribute.propTypes = {
  classes: PropTypes.func,
  setSelectedValue: PropTypes.func,
  setAttributeModalOpen: PropTypes.func,
  index: PropTypes.number,
  attribute: PropTypes.array,
  setOpenDeleteModal: PropTypes.func,
};
