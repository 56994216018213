import React, { useState } from "react";
//import { Link } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Breadcrumbs,
  CircularProgress,
  TextField,
  InputAdornment,
  Link,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import ProductCard from "../components/products/ProductCard";
import ProductCardSkeleton from "../components/products/ProductCardSkeleton";

import {
  limitListAssignableProducts,
  searchAssignableProducts,
} from "../api/Clients";
import { useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
//Styles needed for responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
}));

const AssignClientProducts = () => {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);
  let { client_id } = useParams();
  const observer = useRef();
  const [products, setProducts] = useState([]);
  const [loading_search, setLoadingSearch] = useState(false);
  const history = useHistory();
  //Create a ref to the las element of the list
  const lastElementRef = useCallback((node) => {
    if (lastPage) return;
    if (observer.current) {
      // disconect the observer to set the new last element
      observer.current.disconnect();
    }
    // set the new observer to the last element
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        limitListAssignableProducts(client_id, offset).then((result) => {
          let newProductList = productList.concat(result.products);
          setProductList(newProductList);
          setOffset(offset + 20);
          if (result.products.length < 20) setLastPage(true);
        });
      }
    });
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    setOffset(0);
    limitListAssignableProducts(client_id, 0).then((result) => {
      setProductList(result.products);
      setOffset(offset + 20);
      setLoading(false);
    });
  }, [client_id]);

  useEffect(() => {
    limitListAssignableProducts(client_id, offset).then((result) => {
      setProductList(result.products);
      setOffset(offset + 20);
    });
  }, []);

  return (
    <Container
      style={{ marginTop: "65px" }}
      className={classes.mainContainer}
      maxWidth="xl"
    >
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={12} md={8} style={{ paddingBottom: "20px" }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              color="textSecondary"
              onClick={() => history.push(`/app/client/${client_id}/products`)}
              style={{ cursor: "pointer" }}
            >
              Products
            </Link>

            <Typography color="textSecondary">Assign Product</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid
          container
          xs={12}
          direction="row"
          style={{ marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <Autocomplete
              autoComplete
              autoHighlight
              clearOnEscape
              freeSolo
              style={{ marginLeft: "auto" }}
              id="search"
              disableClearable
              clearOnBlur
              options={products}
              getOptionLabel={(option) => option.search_label}
              onChange={(event, value) => {
                if (event.key === "Enter" && !value.id) {
                  event.preventDefault();
                  return false;
                } else {
                  history.push(
                    `/app/client/${client_id}/products/assign/${value.id}`
                  );
                }
              }}
              loading={loading_search}
              onInputChange={(event, value) => {
                if (value.length > 2) {
                  setLoadingSearch(true);
                  searchAssignableProducts(client_id, value).then((result) => {
                    setLoadingSearch(false);
                    setProducts(result.data.products);
                  });
                }
              }}
              onClose={() => setProducts([])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search available products"
                  margin="none"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",

                    endAdornment: (
                      <InputAdornment position="end">
                        {loading_search ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {loading ? (
            [1, 2, 3, 4].map((index) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                  <ProductCardSkeleton />
                </Grid>
              );
            })
          ) : productList.length < 1 ? (
            <Grid item xs={12}>
              <Typography variant={"h5"}>
                No products available for this client
              </Typography>
            </Grid>
          ) : (
            productList.map((product, index) => {
              if (productList.length == index + 1) {
                return (
                  //  each last product
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    key={index}
                    ref={lastElementRef}
                  >
                    <ProductCard product={product} clickAction={false} />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <ProductCard product={product} clickAction={false} />
                  </Grid>
                );
              }
            })
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AssignClientProducts;
