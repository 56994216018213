import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * Create reseller
 * @param {obj} values
 * @returns
 */
export const createReseller = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        createReseller(
          input: {
            data: {
              name: "${values.name}"
              ${values.website ? `website: "${values.website}"` : ""}
              ${values.address ? `address: "${values.address}"` : ""}
              ${
                values.contact_name
                  ? `contact_name: "${values.contact_name}"`
                  : ""
              }
              ${
                values.contact_phone
                  ? `contact_phone: "${values.contact_phone}"`
                  : ""
              }
              ${
                values.contact_email
                  ? `contact_email: "${values.contact_email}"`
                  : ""
              }
              AcumaticaSetting: {
                Name: "${values.username}"
                Password: "${values.password}"
                Location: "${values.location}"
                EndpointVersion: "${values.api_version}"
              }
            }
          }
        ) {
          reseller {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Update reseller data
 * @param {int} id
 * @param {obj} values
 * @returns
 */
export const updateReseller = async (id, values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateReseller(
          input: {
            where: { id: ${id} }
            data: {
              name: "${values.name}"
              website: "${values.website}"
              address: "${values.address}"
              contact_name: "${values.contact_name}"
              contact_phone: "${values.contact_phone}"
              contact_email: "${values.contact_email}"
              AcumaticaSetting: {
                Name: "${values.username}"
                Password: "${values.password}"
                Location: "${values.location}"
                EndpointVersion: "${values.api_version}"
              }
            }
          }
        ) {
          reseller {
            id
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List resellers from user
 * @param {int} user_id
 * @returns
 */
export const listResellersFromUser = async (user_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        query{
          resellers(where:{users: [${user_id}]}){
            id
            name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List all resellers
 * @param {int} user_id
 * @returns
 */
export const listResellers = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query{
        resellers{
          id
          name
          website
          address
          contact_name
          contact_phone
          contact_email
          AcumaticaSetting
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete reseller
 * @param {int} id
 * @returns object
 */
export const deleteReseller = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        deleteReseller(
          input: {
            where:{ id: ${id} },
          }
        )
        {
          reseller {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
