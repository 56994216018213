import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, Grid, SnackbarContent, Typography } from "@material-ui/core";
//import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  snackbarbaground: {
    backgroundColor: theme.palette.primary.main,
  },
  buttons: {
    color: theme.palette.primary.contrastText,
    fontSize: "small",
  },
}));

export default function SnackbarAlert(props) {
  const classes = useStyles();
  const actionFunction = props.actionFunction;
  const action = (
    <Fragment>
      <Grid xs={8}>
        <Typography
          variant="body2"
          gutterBottom
          style={{ marginLeft: "-18px" }}
        >
          {props.message}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Button
          className={classes.buttons}
          size="small"
          aria-label="close"
          color="inherit"
          onClick={props.handleClose}
        >
          CANCEL
        </Button>
      </Grid>
      <Grid xs={2}>
        <Button
          className={classes.buttons}
          size="small"
          aria-label="ok"
          color="inherit"
          onClick={() => {
            actionFunction(props.data);
          }}
        >
          OK
        </Button>
      </Grid>
    </Fragment>
  );

  return (
    <div>
      <Snackbar open={props.open} onClose={props.handleClose}>
        <SnackbarContent
          //message={props.message}
          action={action}
          color="primary"
          className={classes.snackbarbaground}
        />
      </Snackbar>
    </div>
  );
}
SnackbarAlert.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  actionFunction: PropTypes.func,
};
