import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  Hidden,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SnackbarMessage from "../SnackbarMessage";
import Carousel from "react-material-ui-carousel";
//import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import {
  createStagedLineItem,
  createStagedInvoice,
  getUserStagedInvoices,
  updateStagedInvoice,
  updateStagedLineItem,
} from "../../api/Store";

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  action_buttons: {
    marginTop: 10,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
    },
    marginTop: "15px",
  },
  save: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      float: "right",
    },
    marginTop: "15px",
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
}));

const ProductDialog = (props) => {
  const classes = useStyles();
  //initial value for product
  let initial_line_item = {
    id: props.product.staged_line_item_id
      ? props.product.staged_line_item_id
      : null,
    ProductId: props.product.id,
    ProductName: props.product.Name,
    ProductPrice: props.product.Price,
    BaseSku: props.product.Sku,
    Quantity: 1,
    Sku: props.product.Sku,
    LineTotal: 0,
  };
  if (
    props.product.product_product_attributes &&
    props.product.product_product_attributes.length
  ) {
    props.product.product_product_attributes.forEach(
      //remove attributes that dont belong to the current client
      (attribute, index, product_attributes) => {
        if (
          !attribute.client ||
          !attribute.client.id ||
          (attribute.client.id && attribute.client.id == props.client_id)
        ) {
          if (attribute.product_attribute)
            initial_line_item[attribute.product_attribute.Name] = "";
          else product_attributes.splice(index, 1);
        } else product_attributes.splice(index, 1);
      }
    );
  }

  const [lineItem, setLineItem] = useState(initial_line_item);
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [saving, setSaving] = useState(false);

  //formik to handle changes in attributes and quantity
  const formik = useFormik({
    initialValues: lineItem,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSaving(true);
      let attributes = [];
      let allRequiredAttributes = true; // this variable checks if there is an attribute that is required without value
      /* The next loop just apply attribute validations
      Shows an error message (fail case) or add new item to attributes (success case) */
      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          if (
            key !== "DivisionCategoryId" &&
            key !== "DivisionCategory" &&
            key !== "ProductId" &&
            key !== "ProductName" &&
            (key !== "ProductPrice") & (key !== "BaseSku") &&
            (key !== "Sku") & (key !== "LineTotal")
          ) {
            props.product.product_product_attributes.forEach((attribute) => {
              if (
                (attribute.product_attribute.ControlType == "dropdown" ||
                  attribute.product_attribute.ControlType == "radio") &&
                attribute.product_attribute.product_attribute_values.length &&
                key == attribute.product_attribute.Name &&
                attribute.required &&
                values[key] == ""
              ) {
                allRequiredAttributes = false;
                setSaving(false);
                setError({
                  open: true,
                  message:
                    "Error: Please select your product attributes and try again.",
                  severity: "error",
                });
              } else if (
                (attribute.product_attribute.ControlType == "number" ||
                  attribute.product_attribute.ControlType == "text") &&
                key == attribute.product_attribute.Name &&
                attribute.required &&
                values[key] == ""
              ) {
                allRequiredAttributes = false;
                setSaving(false);
                setError({
                  open: true,
                  message:
                    "Error: Please select your product attributes and try again.",
                  severity: "error",
                });
                //return;
              } else {
                if (attribute.product_attribute.Name == key) {
                  let addAttribute = {
                    ProductAttributeId: attribute.id,
                    Name: key,
                    ProductAttributeValueId: values[key],
                  };
                  //cost adjustment
                  attribute.product_attribute.product_attribute_values.forEach(
                    (attributeValue) => {
                      if (attributeValue.id == values[key]) {
                        addAttribute.CostAdjustment =
                          attributeValue.CostAdjustment;
                        addAttribute.value = attributeValue.Name;
                      }
                    }
                  );
                  attributes.push(addAttribute);
                  //delete values[key];
                }
              }
            });
          }
        }
      }
      //if all required attributes are set
      if (allRequiredAttributes) {
        // Remove NO attribute elements from values
        for (const key in values) {
          if (
            key !== "Quantity" &&
            key !== "DivisionCategoryId" &&
            key !== "DivisionCategory" &&
            key !== "ProductId" &&
            key !== "ProductName" &&
            (key !== "ProductPrice") & (key !== "BaseSku") &&
            (key !== "Sku") & (key !== "LineTotal")
          )
            delete values[key];
        }
        values.Attributes = attributes;
        //check the current staged invoice
        getUserStagedInvoices().then((res) => {
          let currentStagedInvoice = false;
          // Find the first staged_invoice without order_invoice related (that means an active shopping cart)
          res.data.stagedInvoices.forEach((invoice) => {
            if (!invoice.order_invoice) {
              currentStagedInvoice = invoice;
            }
          });
          if (!currentStagedInvoice) {
            //If there is no staged invoice, create the new staged item
            createStagedLineItem(values)
              .then((res) => {
                if (
                  res &&
                  res.data &&
                  res.data.createStagedLineItem &&
                  res.data.createStagedLineItem.stagedLineItem &&
                  res.data.createStagedLineItem.stagedLineItem.id
                ) {
                  let item = res.data.createStagedLineItem.stagedLineItem;
                  //then create the new staged invoice with the new item
                  createStagedInvoice(item.id)
                    .then(() => {
                      props.getUserCart();
                      props.handleClose({
                        sucess: "Add_sucess",
                        ProductName: values.ProductName,
                      });
                    })
                    .catch(() => {
                      // This is error request case
                      setError({
                        open: true,
                        message: "Error requesting createStagedInvoice",
                        severity: "error",
                      });
                    })
                    .then(() => {
                      // This is always executed on error or success
                      setSaving(false);
                    });
                } else {
                  // This is 200 OK case, but with error inside response
                  // Catch error
                  let error_message =
                    "errors" in res &&
                    Array.isArray(res.errors) &&
                    res.errors.length > 0 &&
                    "message" in res.errors[0]
                      ? res.errors[0].message
                      : `An error occurred, please try again`;
                  setError({
                    open: true,
                    message: error_message,
                    severity: "error",
                  });
                }
              })
              .catch(() => {
                // This is error request case
                setError({
                  open: true,
                  message: "Error requesting createStagedLineItem",
                  severity: "error",
                });
              })
              .then(() => {
                // This is always executed on error or success
                setSaving(false);
              });
          } else {
            //if a staged invoice exists
            //firs check if there is a similar product in the invoice.

            //list items ids
            let lineItems = [];
            let isNew = true;
            var updateId = props.updating ? lineItem.id : 0;
            var currentItemQty = 0;
            // If you are not updating this item
            if (!props.updating) {
              //variable to check if the product was already found
              let shouldSkip = false;
              currentStagedInvoice.staged_line_items.forEach((line) => {
                if (!shouldSkip) {
                  //Create a var for the current product quantity in case we need to add another quantity
                  currentItemQty = line.Quantity;
                  //add the current staged line item anyway. In this step, no items will be removed
                  lineItems.push(line.id);
                  if (values.ProductId == line.product.id) {
                    //if its the same product, check each product attribute
                    isNew = false;
                    line.LineItemJson.Attributes.forEach((lineAttr) => {
                      values.Attributes.forEach((valAttr) => {
                        if (
                          lineAttr.ProductAttributeId ==
                            valAttr.ProductAttributeId &&
                          lineAttr.ProductAttributeValueId !=
                            valAttr.ProductAttributeValueId
                        ) {
                          //if attributes have different values, create a new item
                          isNew = true;
                          updateId = 0;
                        }
                      });
                    });
                    if (isNew === false) {
                      updateId = line.id;
                      shouldSkip = true;
                    }
                  } else {
                    //if its a different product just add it
                    isNew = true;
                  }
                }
              });
            }
            if (isNew && !props.updating) {
              //if one or more attributes are different, create a new stagedLineItem
              createStagedLineItem(values)
                .then((res) => {
                  if (
                    res &&
                    res.data &&
                    res.data.createStagedLineItem &&
                    res.data.createStagedLineItem.stagedLineItem &&
                    res.data.createStagedLineItem.stagedLineItem.id
                  ) {
                    //then add the new item id to the staged invoice
                    let newItem = res.data.createStagedLineItem.stagedLineItem;
                    lineItems.push(newItem.id);
                    currentStagedInvoice.lineItems = lineItems;
                    updateStagedInvoice(currentStagedInvoice)
                      .then(() => {
                        props.getUserCart();
                        setSaving(false);
                        props.handleClose({
                          sucess: "Add_sucess",
                          ProductName: values.ProductName,
                        });
                      })
                      .catch(() => {
                        setSaving(false);
                        // This is error request case
                        setError({
                          open: true,
                          message: "Error requesting updateStagedInvoice",
                          severity: "error",
                        });
                      });
                  } else {
                    // This is 200 OK case, but with error inside response
                    // Catch error
                    setSaving(false);
                    let error_message =
                      "errors" in res &&
                      Array.isArray(res.errors) &&
                      res.errors.length > 0 &&
                      "message" in res.errors[0]
                        ? res.errors[0].message
                        : `An error occurred, please try again`;
                    setError({
                      open: true,
                      message: error_message,
                      severity: "error",
                    });
                  }
                })
                .catch(() => {
                  setSaving(false);
                  // This is error request case
                  setError({
                    open: true,
                    message: "Error requesting createStagedLineItem 2",
                    severity: "error",
                  });
                });
            } else {
              //if attributes are the same, update stagedLineItem quantity only
              values.Quantity = values.Quantity + currentItemQty;
              values.Attributes.Quantity = values.Quantity;
              updateStagedLineItem(updateId, values, props.updating)
                .then((res) => {
                  if (
                    res &&
                    res.data &&
                    res.data.updateStagedLineItem &&
                    res.data.updateStagedLineItem.stagedLineItem &&
                    res.data.updateStagedLineItem.stagedLineItem.id
                  ) {
                    // Reload cart badge and reload userStagedInvoice from ShippingCart (param: true)
                    props.getUserCart(true);
                    // setSaving(false);
                    props.handleClose({
                      sucess: "Add_sucess",
                      ProductName: values.ProductName,
                    });
                  } else {
                    // This is 200 OK case, but with error inside response
                    // Catch error
                    let error_message =
                      "errors" in res &&
                      Array.isArray(res.errors) &&
                      res.errors.length > 0 &&
                      "message" in res.errors[0]
                        ? res.errors[0].message
                        : `An error occurred, please try again`;
                    setError({
                      open: true,
                      message: error_message,
                      severity: "error",
                    });
                  }
                })
                .catch(() => {
                  // This is error request case
                  setError({
                    open: true,
                    message: "Error requesting updateStagedLineItem",
                    severity: "error",
                  });
                })
                .then(() => {
                  // This is always executed on error or success
                  setSaving(false);
                });
            }
          }
        });
      }
    },
  });
  //useEffect to change initial values if product changes
  useEffect(() => {
    let itemWithAttributes = {
      id: props.product.staged_line_item_id
        ? props.product.staged_line_item_id
        : null,
      ProductId: props.product.id,
      ProductName: props.product.Name,
      ProductPrice: props.product.Price,
      BaseSku: props.product.Sku,
      Quantity: props.product.Quantity ? props.product.Quantity : 1,
      Sku: props.product.Sku,
      LineTotal: 0,
    };
    // Division category only for Add to bag
    if (props.currentDivision) {
      itemWithAttributes.DivisionCategoryId =
        props.currentDivision.division_category.id;
      itemWithAttributes.DivisionCategory = props.currentDivision.Name;
    }
    if (
      props.product.product_product_attributes &&
      props.product.product_product_attributes.length
    ) {
      props.product.product_product_attributes.forEach((attribute) => {
        let attr_value = props.product.sli_attributes
          ? props.product.sli_attributes.find(
              (sli_attr) => sli_attr.ProductAttributeId == attribute.id
            )
          : "";
        if (
          attribute &&
          attribute.product_attribute &&
          attribute.product_attribute.Name
        ) {
          itemWithAttributes[attribute.product_attribute.Name] =
            typeof attr_value == "object"
              ? attr_value.ProductAttributeValueId
              : "";
        }
      });
    }
    setLineItem(itemWithAttributes);
    formik.resetForm({
      values: itemWithAttributes,
    });
  }, [props.product]);

  const handleCloseMessage = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  // Render view
  return (
    <Dialog
      id="product_modal"
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
    >
      <DialogContent id="user_dialog_content" style={{ overflowY: "scroll" }}>
        <Grid container spacing={2}>
          {/* images*/}
          <Grid item xs={12} md={6}>
            <Carousel
              autoPlay={false}
              navButtonsAlwaysVisible={
                props.product.Image.length > 1 ? true : false
              }
              navButtonsAlwaysInvisible={
                props.product.Image.length > 1 ? false : true
              }
              animation={"slide"}
              className={classes.fullwidth}
            >
              {props.product.Image.length < 1 ? (
                <Grid
                  item
                  xs={12}
                  className={classes.paper}
                  style={{ minHeight: "300px" }}
                >
                  <img
                    src={"/img/avid/avid-io_250x266.png"}
                    style={{ maxWidth: "100%", maxHeight: "300px" }}
                  />
                </Grid>
              ) : (
                props.product.Image.map((pic, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      className={classes.paper}
                      style={{ height: "300px" }}
                      key={index}
                    >
                      <img
                        src={
                          pic.url ? pic.url : "/img/avid/avid-io_250x266.png"
                        }
                        style={{ maxWidth: "100%", maxHeight: "280px" }}
                      />
                    </Grid>
                  );
                })
              )}
            </Carousel>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h4">{props.product.Name}</Typography>
            <br />
            <Typography variant="h5">${props.product.Price}</Typography>
            <br />
            <Typography variant="caption">
              Division:{" "}
              {props.product.divisions
                .filter((dc) => dc.division != null)
                .map((dc, index, array) => {
                  return (
                    dc.division.Name + (index == array.length - 1 ? "" : ", ")
                  );
                })}
            </Typography>
            <br />
            <br />
            <Typography variant="caption">SKU: {props.product.Sku}</Typography>
            <br />
            <br />
            <Typography variant="body1">
              <span style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}>
                {props.product.Description}
              </span>
            </Typography>
            <br />
            <span style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}>
              <Typography variant="body2">{props.product.Summary}</Typography>
            </span>
            <br />
            {props &&
            props.product &&
            props.product.product_product_attributes &&
            Array.isArray(props.product.product_product_attributes) &&
            props.product.product_product_attributes.length > 0
              ? props.product.product_product_attributes.map((attribute, i) => {
                  // Get control type
                  const ControlType =
                    attribute &&
                    attribute.product_attribute &&
                    attribute.product_attribute.ControlType
                      ? attribute.product_attribute.ControlType
                      : null;
                  return ControlType &&
                    ControlType == "dropdown" &&
                    attribute.product_attribute.product_attribute_values
                      .length ? (
                    <FormControl
                      variant="outlined"
                      key={i}
                      fullWidth
                      style={{ marginBottom: "15px" }}
                      required={attribute.required}
                      error={
                        attribute.required &&
                        formik.touched[attribute.product_attribute.Name] &&
                        !formik.values[attribute.product_attribute.Name]
                      }
                      helperText={
                        formik.touched[attribute.product_attribute.Name] &&
                        attribute.required
                          ? `${attribute.product_attribute.Name} required`
                          : ""
                      }
                    >
                      <InputLabel>
                        {attribute.product_attribute.DisplayText
                          ? attribute.product_attribute.DisplayText
                          : attribute.product_attribute.Name}
                      </InputLabel>
                      <Select
                        label={
                          attribute.product_attribute.DisplayText
                            ? attribute.product_attribute.DisplayText
                            : attribute.product_attribute.Name
                        }
                        name={attribute.product_attribute.Name}
                        value={formik.values[attribute.product_attribute.Name]}
                        onChange={formik.handleChange}
                      >
                        {attribute.product_attribute.product_attribute_values.map(
                          (value, j) => {
                            return (
                              <MenuItem key={j} value={value.id}>
                                {value.Name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  ) : ControlType && ControlType == "text" ? (
                    <TextField
                      id={attribute.product_attribute.Name}
                      value={formik.values[attribute.product_attribute.Name]}
                      onChange={formik.handleChange}
                      style={{ marginBottom: "15px" }}
                      label={
                        attribute.product_attribute.DisplayText
                          ? attribute.product_attribute.DisplayText
                          : attribute.product_attribute.Name
                      }
                      variant="outlined"
                      fullWidth
                      required={attribute.required}
                      error={
                        attribute.required &&
                        formik.touched[attribute.product_attribute.Name] &&
                        !formik.values[attribute.product_attribute.Name]
                      }
                      helperText={
                        formik.touched[attribute.product_attribute.Name] &&
                        attribute.required
                          ? `${attribute.product_attribute.Name} required`
                          : ""
                      }
                    />
                  ) : ControlType && ControlType == "number" ? (
                    <TextField
                      id={attribute.product_attribute.Name}
                      value={formik.values[attribute.product_attribute.Name]}
                      onChange={formik.handleChange}
                      style={{ marginBottom: "15px" }}
                      label={
                        attribute.product_attribute.DisplayText
                          ? attribute.product_attribute.DisplayText
                          : attribute.product_attribute.Name
                      }
                      variant="outlined"
                      fullWidth
                      required={attribute.required}
                      error={
                        attribute.required &&
                        formik.touched[attribute.product_attribute.Name] &&
                        !formik.values[attribute.product_attribute.Name]
                      }
                      helperText={
                        formik.touched[attribute.product_attribute.Name] &&
                        attribute.required
                          ? `${attribute.product_attribute.Name} required`
                          : ""
                      }
                      type="number"
                      inputProps={{ min: 1 }}
                    />
                  ) : ControlType &&
                    ControlType == "radio" &&
                    attribute.product_attribute.product_attribute_values
                      .length ? (
                    <FormControl
                      component="fieldset"
                      style={{ marginBottom: "15px" }}
                      error={
                        attribute.required &&
                        formik.touched[attribute.product_attribute.Name] &&
                        !formik.values[attribute.product_attribute.Name]
                      }
                    >
                      <FormLabel component="legend">
                        {attribute.product_attribute.Name}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="position"
                        defaultValue="top"
                        name={attribute.product_attribute.Name}
                        value={formik.values[attribute.product_attribute.Name]}
                        onChange={formik.handleChange}
                        required={attribute.required}
                      >
                        {attribute.product_attribute.product_attribute_values.map(
                          (value, j) => {
                            return (
                              <FormControlLabel
                                key={j}
                                value={value.id}
                                control={<Radio color="primary" />}
                                label={value.Name}
                                labelPlacement="top"
                              />
                            );
                          }
                        )}
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched[attribute.product_attribute.Name] &&
                        attribute.required
                          ? `${attribute.product_attribute.Name} required`
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    ""
                  );
                })
              : ""}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          id="buttons_container"
          className={classes.action_buttons}
        >
          <Hidden xsDown>
            <Grid item sm={1} md={4} lg={4}></Grid>
          </Hidden>

          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Button variant="contained" fullWidth onClick={props.handleClose}>
              Close
            </Button>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <TextField
              id="Quantity"
              label="Quantity"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              variant="outlined"
              size="small"
              value={formik.values.Quantity}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={saving}
            >
              {props.updating ? "Update" : "Add To Bag"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseMessage}
      />
    </Dialog>
  );
};

ProductDialog.propTypes = {
  open: PropTypes.bool,
  product: PropTypes.object,
  handleClose: PropTypes.func,
  currentDivision: PropTypes.object,
  getUserCart: PropTypes.func,
  updating: PropTypes.bool,
  client_id: PropTypes.number,
};

export default ProductDialog;
