//Libraries
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button, Container, Grid, Hidden, Typography } from "@material-ui/core";
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import MUIDataTable from "mui-datatables";

//API
import { listAllProductsFromResellerV2 } from "../api/Products";

//Custom components
import SnackbarMessage from "../components/SnackbarMessage";
//Styles needed for responsiveness
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
    [theme.breakpoints.down("xl")]: {
      marginTop: "85px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "75px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
  },
  ListItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const ProductListTable = forwardRef((props, ref) => {
  const classes = useStyles();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });
  const [productData, setProductdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // We need use useImperativeHandle in order to expose ref to getProducts for data refreshing
  useImperativeHandle(ref, () => ({
    refreshData() {
      // List users
      getAllProducts();
    },
  }));

  /**Initial loading */
  useEffect(() => {
    // Get current page
    const currentPage = localStorage.getItem("currentProductPage");
    // Set current page
    if (currentPage) setPage(parseInt(currentPage));
    // Get current rows per page
    const currentRows = localStorage.getItem("currentProductRows");
    // Set current rows per page
    if (currentRows) setRowsPerPage(parseInt(currentRows));
    // TODO: With Back's settings, this may no longer be necessary, but you should first test to see if it solves the problem before removing this.
    // const resellerProducts = localStorage.getItem("resellerProducts");
    // const products = localStorage.getItem("Products");
    // const reseller = localStorage.getItem("reseller_id");
    // // Check if reseller has changed
    // if (resellerProducts && resellerProducts == reseller && products) {
    //   setProductdata(JSON.parse(products));
    //   setLoading(false);
    // }
    // // List products
    // else {
    getAllProducts();
    // }
  }, []);

  //Custom styles for text column
  const customStyle = ({ ...column }) => {
    return (
      <div
        className={classes.ListItem}
        style={{
          maxWidth:
            column && column.name == "picture"
              ? "75px"
              : column.name == "sku"
              ? "75px"
              : column.name == "product_category"
              ? "150px"
              : "100px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "bold",
          padding: "10px",
          height: "10px",
        }}
      >
        {column.label}
      </div>
    );
  };

  //Custom style by columns
  const customStyleText = (value = "-") => {
    return (
      <div
        style={{
          maxWidth: "300px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {value}
      </div>
    );
  };

  //Custom style for image logo product
  const customStyleImage = (value = "") => {
    // Get image data
    let data =
      value && typeof value == "string" && value.length > 0
        ? JSON.parse(value)
        : [];
    // Get image url
    const imageUrl =
      data.length > 0 && data[0].url
        ? data[0].url
        : "/img/avid/avid-io_250x266.png";
    // Get product id
    const productId =
      data.length > 0 && data[0].product_id ? data[0].product_id : "";
    return (
      <div style={{ maxWidth: "75px" }}>
        <center>
          <img
            key={productId}
            src={imageUrl}
            style={{
              maxWidth: "75%",
              height: "auto",
              maxHeight: "75px",
              padding: "0px",
            }}
          />
        </center>
      </div>
    );
  };

  //Custom style by columns
  const customStyleProCat = (value = "-") => {
    return (
      <div
        style={{
          maxWidth: "300px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {value}
      </div>
    );
  };

  //Mui Data table custom Stykes
  const theme = () =>
    createMuiTheme({
      overrides: {
        //Table head custom styles
        MUIDataTableHeadCell: {
          fixedHeader: {
            paddingTop: "0px",
            paddingBottom: "10px",
          },
          root: {
            "&:first-child": {
              width: "10%",
              backgroundColor: "#F2F2F2",
              borderRight: "1px solid #E5E3E3",
            },
            "&:nth-child(2)": {
              width: "15%",
              backgroundColor: "#F2F2F2",
              borderRight: "1px solid #E5E3E3",
            },
            "&:nth-child(3)": {
              width: "10%",
              backgroundColor: "#F2F2F2",
              borderRight: "1px solid #E5E3E3",
            },
            "&:nth-child(4)": {
              width: "15%",
              backgroundColor: "#F2F2F2",
              borderRight: "1px solid #E5E3E3",
            },
            "&:nth-child(5)": {
              width: "10%",
              backgroundColor: "#F2F2F2",
            },
          },
        },
        //Search form custom style
        MUIDataTableSearch: {
          main: {
            visibility: true,
          },
        },
        //Chip filters custom styles
        MUIDataTableFilterList: {
          root: {
            marginBottom: "10px",
          },
        },
        //Filter container custom styles
        MuiGrid: {
          container: {
            display: "block",
            width: "210px",
            paddingLeft: "opx",
          },
          item: {
            boxSizing: "content-box",
          },
        },
        //Filte list options custom styles
        MUIDataTableFilter: {
          checkboxIcon: {
            padding: "0px",
          },
          checkboxFormControlLabel: {
            padding: "0px",
          },
        },
      },
    });

  //Columns for the table
  let initial_columns = [
    //Picture column
    {
      name: "Picture",
      label: "Picture",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleImage(value),
      },
    },
    //name column
    {
      name: "Name",
      label: "Name",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    //Sku column
    {
      name: "Sku",
      label: "SKU",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    //Division categories column
    {
      name: "Product_category",
      label: "Product Category",
      options: {
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleProCat(value),
      },
    },
    //Price column
    {
      name: "Price",
      label: "Price",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
  ];

  //Table options
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    //searchOpen: true,
    //searchAlwaysOpen: true,
    searchPlaceholder: "Search available products",
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `${column.label}`,
      },
    },
    downloadOptions: {
      filename: "Product List",
      separator: ",",
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    responsive: "standard",
    onRowClick: (rowData) => {
      const imageInfo = rowData[0].props.children.props.children;
      const productId = imageInfo.key;
      //Redirect to product view
      if (productId) history.push(`/app/product/${productId}`);
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    page: page,
    onChangePage: (currentPage) => {
      localStorage.setItem("currentProductPage", currentPage);
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      localStorage.setItem("currentProductRows", numberOfRows);
      setRowsPerPage(numberOfRows);
      setPage(0);
    },
  };

  /**
   * Close snackbar
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**
   * Get all products from current reseller
   */
  const getAllProducts = () => {
    setLoading(true);
    listAllProductsFromResellerV2()
      .then((result) => {
        if (result.data.getProductsTable) {
          const products = result.data.getProductsTable;
          const reseller = localStorage.getItem("reseller_id");
          localStorage.setItem("Products", JSON.stringify(products));
          localStorage.setItem("resellerProducts", reseller);
          setProductdata(products);
        } else {
          let error_message = result.errors
            ? result.errors[0].message
            : "An error occurred";
          // Display error
          setError({
            open: true,
            message: `${error_message}. Please try again later.`,
            severity: "error",
          });
        }
      })
      .catch(() => {
        // Display error
        setError({
          open: true,
          message: `An error occurred while exporting products`,
          severity: "error",
        });
      })
      .finally(() => setLoading(false)); // Finally, change loading flag
  };
  return (
    <Container className={classes.mainContainer} maxWidth="xl">
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
        </Grid>
        {/**Skeleton loading */}
        {loading ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Hidden smDown>
                <Skeleton
                  variant="retangular"
                  height={39}
                  style={{ float: "right" }}
                  width={200}
                />
              </Hidden>
              <Hidden mdUp>
                <Skeleton
                  variant="retangular"
                  height={39}
                  style={{ float: "right" }}
                  width={"100%"}
                />
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <Hidden smDown>
                <Skeleton
                  variant="retangular"
                  height={39}
                  style={{ float: "right" }}
                  width={150}
                />
              </Hidden>
              <Hidden mdUp>
                <Skeleton
                  variant="retangular"
                  height={39}
                  style={{ float: "right" }}
                  width={"100%"}
                />
              </Hidden>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px" }}
                      width={120}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ paddingRight: "25px", marginBottom: "20px" }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px", float: "right" }}
                      width={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px", float: "right" }}
                      width={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px", float: "right" }}
                      width={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px", float: "right" }}
                      width={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      style={{ margin: "3px", float: "right" }}
                      width={40}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((row, i) => {
                    return (
                      <Grid key={i} container>
                        <Grid item xs={1}>
                          <Skeleton
                            variant="rectangular"
                            height={30}
                            style={{ margin: "3px", marginBottom: "15px" }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton
                            variant="rectangular"
                            height={30}
                            style={{ margin: "3px" }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton
                            variant="rectangular"
                            height={30}
                            style={{ margin: "3px" }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Skeleton
                            variant="rectangular"
                            height={30}
                            style={{ margin: "3px" }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton
                            variant="rectangular"
                            height={30}
                            style={{ margin: "3px" }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid
              container
              spacing={2}
              item
              sm={12}
              md={6}
              lg={5}
              style={{ margin: "0px" }}
            >
              <Hidden lgDown>
                <Grid item xl={4} />
              </Hidden>
              {/**Add attributes templates button */}
              <Grid item xs={12} sm={12} md={7} lg={7} xl={5}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => history.push("/app/attributes/templates")}
                  style={{ float: "right" }}
                  fullWidth
                >
                  ATTRIBUTES TEMPLATES
                </Button>
              </Grid>
              {/**Add products button */}
              <Grid item xs={12} sm={12} md={5} lg={5} xl={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/app/add-product")}
                  fullWidth
                >
                  ADD PRODUCT
                </Button>
              </Grid>
            </Grid>
            {/**Table container */}
            <Grid container>
              {/**Table */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.ListItem}
                style={{ marginTop: "15px" }}
              >
                <MuiThemeProvider theme={theme}>
                  <MUIDataTable
                    data={productData}
                    columns={initial_columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
});

ProductListTable.propTypes = {};

export default ProductListTable;
