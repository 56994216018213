import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { Grid, Button, Container, Typography } from "@material-ui/core";
import ProductCard from "../components/products/ProductCard";
import NavigationTabs from "../components/NavigationTabs";
import ProductCardSkeleton from "../components/products/ProductCardSkeleton";

import { limitListClientProducts } from "../api/Clients";
import { useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
//Styles needed for responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
}));

const ClientProducts = () => {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);
  let { client_id } = useParams();
  const observer = useRef();
  const history = useHistory();
  //Create a ref to the las element of the list
  const lastElementRef = useCallback((node) => {
    if (lastPage) return;
    if (observer.current) {
      // disconect the observer to set the new last element
      observer.current.disconnect();
    }
    // set the new observer to the last element
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        limitListClientProducts(client_id, offset).then((result) => {
          let newProductList = productList.concat(result.products);
          setProductList(newProductList);
          setOffset(offset + 20);
          if (result.products.length < 20) setLastPage(true);
        });
      }
    });
    if (node) observer.current.observe(node);
  });

  useEffect(() => {
    setOffset(0);
    limitListClientProducts(client_id, 0).then((result) => {
      if (result) {
        setProductList(result.products);
        setOffset(offset + 20);
        setLoading(false);
      }
    });
  }, [client_id]);

  useEffect(() => {
    limitListClientProducts(client_id, offset).then((result) => {
      if (result) {
        setProductList(result.products);
        setOffset(offset + 20);
      }
    });
  }, []);
  return (
    <Container
      style={{ marginTop: "65px" }}
      className={classes.mainContainer}
      maxWidth="xl"
    >
      {client_id == 0 ? (
        <Grid
          container
          style={{ padding: "0 20px 20px 20px", marginTop: "20px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              This user doesn&apos;t have any client, please contact an
              administrator.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} md={10} style={{ paddingBottom: "20px" }}>
            <NavigationTabs client_id={Number(client_id)} value={"products"} />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid
            container
            xs={12}
            direction="row"
            justify="flex-start"
            style={{ marginBottom: "20px" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(`/app/client/${client_id}/products/assign`)
                }
              >
                Assign
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {loading ? (
              [1, 2, 3, 4].map((index) => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <ProductCardSkeleton />
                  </Grid>
                );
              })
            ) : productList.length < 1 ? (
              <Grid item xs={12}>
                <Typography variant={"h5"}>
                  No products for this client
                </Typography>
              </Grid>
            ) : (
              productList.map((product, index) => {
                if (productList.length == index + 1) {
                  return (
                    //  each last product
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      key={index}
                      ref={lastElementRef}
                    >
                      <ProductCard
                        product={product}
                        preventNavigation={true}
                        clickAction={false}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                      <ProductCard
                        product={product}
                        preventNavigation={true}
                        clickAction={false}
                      />
                    </Grid>
                  );
                }
              })
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ClientProducts;
