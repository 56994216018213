import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Divider,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { updateClient, deleteClient } from "../../api/Clients";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import AlertDialog from "../AlertDialog";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    float: "right",
    marginTop: "15px",
  },
  deleteButtonGrid: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  saveButton: {
    marginTop: "15px",

    float: "right",
  },
}));

/**
 * Formik validation schema
 */
const validationSchema = yup.object({
  name: yup.string("Client Name").required("Client Name is required"),
  subdomain: yup.string("Client Subdomain"),
  primary_color: yup
    .string("Primary Color")
    .required("Primary Color is required"),
  secondary_color: yup
    .string("Secondary Color")
    .required("Primary Color is required"),
});

/**
 * Main component
 * @param {object} props
 * @returns <div>
 */
function ClientContainer(props) {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    subdomain: "",
    primary_color: "",
    secondary_color: "",
    ordering_mode: "Default",
  });
  let history = useHistory();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setInitialValues({
      id: props.client.id,
      name: props.client.name,
      subdomain: props.client.subdomain,
      primary_color: props.client.primary_color
        ? props.client.primary_color
        : "#1976d2",
      secondary_color: props.client.secondary_color
        ? props.client.secondary_color
        : "#d5d5d5",
      ordering_mode: props.client.ordering_mode
        ? props.client.ordering_mode
        : "Default",
    });
    if (props.client.client_image) {
      setImage(props.client.client_image);
    } else {
      setImage("");
    }
  }, [props.client.id]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSaving(true);
      // alert(JSON.stringify(values, null, 2));
      if (image.files) {
        let formData = new FormData();
        formData.append("files", image.files);
        axios
          .post(`${process.env.REACT_APP_REST_URL}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            values.image = res.data[0].id;
            updateClient(values).then((result) => {
              if (result.data.updateClient) {
                props.setMessage({
                  open: true,
                  message: "Changes saved successfully",
                  severity: "success",
                });
                setSaving(false);
                props.getClients();
              }
            });
          });
      } else {
        updateClient(values).then((result) => {
          if (result.data.updateClient) {
            props.setMessage({
              open: true,
              message: "Changes saved successfully",
              severity: "success",
            });
            setSaving(false);
            props.getClients();
          }
        });
      }
    },
  });

  const handleDeleteClient = () => {
    deleteClient(props.client.id).then((result) => {
      if (result.data) {
        props.getClients();
        props.setMessage({
          open: true,
          message: "Deleted successfully",
          severity: "success",
        });
        setTimeout(() => {
          history.replace("/app/products");
        }, 3000);
      }
    });
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {props.loading ? (
          // Skeleton
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <center>
                <Skeleton
                  variant="rect"
                  height={100}
                  style={{ marginBottom: "5px" }}
                />
              </center>
              <Divider style={{ width: "100%" }} />
              <center>
                <Skeleton />
              </center>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Skeleton height={40} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton height={32} />
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Skeleton height={42} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton height={42} />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton height={42} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              className={classes.deleteButtonGrid}
              style={{ paddingTop: "23px" }}
            >
              <Skeleton height={36} width={89} />
            </Grid>
            <Grid item xs={6} sm={8}>
              <Skeleton height={36} width={89} className={classes.saveButton} />
            </Grid>
          </Grid>
        ) : (
          // View
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <center>
                <img
                  src={
                    !image.url
                      ? "../../../img/avid/avid-io_500x532.png"
                      : image.url
                  }
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "125px",
                  }}
                />
              </center>
              <Divider style={{ width: "100%" }} />
              <center>
                <Button component="label" color="primary">
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    hidden
                    onChange={(event) => {
                      setImage({
                        files: event.target.files[0],
                        url: URL.createObjectURL(event.target.files[0]),
                      });
                    }}
                  />
                  Upload Image
                </Button>
              </center>
            </Grid>
            <Grid item xs={12} md={8}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      disabled
                      id="subdomain"
                      name="subdomain"
                      label="Unique ID"
                      variant="outlined"
                      value={formik.values.subdomain}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.subdomain &&
                        Boolean(formik.errors.subdomain)
                      }
                      helperText={
                        formik.touched.subdomain && formik.errors.subdomain
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="primary_color"
                      name="primary_color"
                      label="Primary Color"
                      variant="outlined"
                      type="color"
                      value={formik.values.primary_color}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.primary_color &&
                        Boolean(formik.errors.primary_color)
                      }
                      helperText={
                        formik.touched.primary_color &&
                        formik.errors.primary_color
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="secondary_color"
                      name="secondary_color"
                      label="Secondary Color"
                      variant="outlined"
                      type="color"
                      value={formik.values.secondary_color}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.secondary_color &&
                        Boolean(formik.errors.secondary_color)
                      }
                      helperText={
                        formik.touched.secondary_color &&
                        formik.errors.secondary_color
                      }
                    />
                  </Grid>
                  <Grid item xs={12} id="grid_ordering_mode">
                    <Typography variant="h6">Default ordering mode</Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row={true}
                        aria-label="ordering_mode"
                        name="ordering_mode"
                        id="ordering_mode"
                        value={formik.values.ordering_mode}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="Default"
                          control={<Radio />}
                          label="Invoice"
                        />
                        <FormControlLabel
                          value="Approval"
                          control={<Radio />}
                          label="Approval"
                        />
                        <FormControlLabel
                          value="Points"
                          control={<Radio />}
                          label="Payment and Points"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              className={classes.deleteButtonGrid}
              style={{ paddingTop: "23px" }}
            >
              <AlertDialog
                openButtonVariant="contained"
                dialogTitle="Delete Client"
                dialogContentText="All client data will be removed. Do you wish to continue?"
                confirmButtonClick={() => handleDeleteClient()}
              />
            </Grid>
            <Grid item xs={6} sm={8}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={formik.handleSubmit}
                disabled={saving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </div>
  );
}

ClientContainer.propTypes = {
  client: PropTypes.object,
  setMessage: PropTypes.func,
  getClients: PropTypes.func,
  loading: PropTypes.bool,
};

export default ClientContainer;
