import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import UserReducer from "./UserReducer";

const initialState = {
  token: null,
  user: null,
  person: null,
  error: null,
  roles: null,
};

const UserState = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

UserState.propTypes = {
  children: PropTypes.object,
};

export const Context = createContext(initialState);
export default UserState;
