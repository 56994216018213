import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  Popover,
  Typography,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useTabStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: "24px 24px 36px 24px",
    fontFamily: "Roboto",
  },
  header: {
    flex: "0 0 auto",
    marginBottom: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    display: "inline-block",
    marginLeft: "7px",
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: 500,
  },
  reset: {
    alignSelf: "left",
  },
  resetLink: {
    marginLeft: "16px",
    fontSize: "12px",
    cursor: "pointer",
  },
  filtersSelected: {
    alignSelf: "right",
  },
  /* checkbox */
  checkboxListTitle: {
    marginLeft: "7px",
    marginBottom: "8px",
    fontSize: "14px",
    color: theme.palette.text.secondary,
    textAlign: "left",
    fontWeight: 500,
  },
  checkboxFormControl: {
    margin: "0px",
  },
  checkboxFormControlLabel: {
    fontSize: "15px",
    color: theme.palette.text.primary,
  },
  contenedorFlex: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
  },
}));

export default function CustomFilter(props) {
  const classes = useTabStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { filters, onChangeFilters } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosee = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip title="Filter by product categories" disableFocusListener>
        <IconButton onClick={handleClick}>
          <FilterIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="filter-by-product-categories-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosee}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <IconButton
          aria-label="Close"
          onClick={handleClosee}
          style={{
            position: "absolute",
            right: "4px",
            top: "4px",
            zIndex: "1000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.reset}>
              <Typography variant="body2" className={classes.title}>
                FILTERS
              </Typography>
              <Button
                color="primary"
                tabIndex={0}
                className={classes.resetLink}
                onClick={() => onChangeFilters(null)}
              >
                RESET
              </Button>
            </div>
            <div className={classes.filtersSelected}></div>
          </div>
          <Grid>
            <FormGroup>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  className={classes.checkboxListTitle}
                >
                  Product Category
                </Typography>
              </Grid>
              <Grid container className={classes.contenedorFlex}>
                {filters.map((filterValue, filterIndex) => {
                  return (
                    <Grid item key={filterIndex}>
                      <FormControlLabel
                        key={filterIndex}
                        classes={{
                          root: classes.checkboxFormControl,
                          label: classes.checkboxFormControlLabel,
                        }}
                        control={
                          <Checkbox
                            data-description="table-filter"
                            color="primary"
                            onChange={() => onChangeFilters(filterValue)}
                            checked={filterValue.checked}
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            value={
                              filterValue != null ? filterValue.toString() : ""
                            }
                          />
                        }
                        label={filterValue.Name}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}

CustomFilter.propTypes = {
  filters: PropTypes.array,
  onChangeFilters: PropTypes.func,
  selectedFilters: PropTypes.array,
};
