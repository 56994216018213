import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const AttributeTemplateSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
        <Typography color="textSecondary" gutterBottom noWrap={true}>
          <Skeleton animation="wave" />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AttributeTemplateSkeleton;
