// Import Libraries
import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import { Button } from "@material-ui/core";

export default function SnackbarNotification(props) {
  const {
    message = "",
    open = false,
    handleClose = () => {},
    actionFunction = () => {},
  } = props;
  return (
    <div>
      <Snackbar
        key={new Date().getTime()}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        TransitionProps={{ onExited: handleClose }}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={actionFunction}>
              UNDO
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
}

SnackbarNotification.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  actionFunction: PropTypes.func,
};
