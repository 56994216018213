// Libraries
import * as React from "react";
import PropTypes from "prop-types";

//MUI
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";

export default function LoginForm({
  classes,
  formik,
  loading,
  setView,
  usePass,
  setusePass,
}) {
  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      {!usePass ? (
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      ) : (
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Email or username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      )}

      {usePass == true ? (
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      ) : (
        <center>
          <Grid item xs className={classes.usePass}>
            <Link
              href="#"
              variant="body2"
              onClick={() => {
                setusePass(true);
              }}
            >
              Use Password
            </Link>
          </Grid>
        </center>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={loading}
        className={classes.submit}
      >
        Sign In
      </Button>
      {/**Loading code here*/}
      <center>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </center>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2" onClick={() => setView("reset")}>
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object,
  loading: PropTypes.bool,
  setView: PropTypes.func,
  usePass: PropTypes.bool,
  setusePass: PropTypes.func,
};
