// Libraries
import React, { useState } from "react";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { PropTypes } from "prop-types";
import { useFormik } from "formik";

// Custom components
import SnackbarMessage from "../SnackbarMessage";

// API
import { updateAcumaticaSettings } from "../../api/Clients";
import { Fragment } from "react";

// Styles
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
}));

/**
 * Main component
 * @param {object} {} Component props
 * @returns
 */
function AcumaticaSettings({
  client_id,
  loading,
  enabled_acumatica,
  acumatica_settings,
  reloadClient,
  use_billing_department,
  acumatica_setting,
}) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [fields_required, setFieldsRequired] = useState(enabled_acumatica);

  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });

  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };

  // Initial form data
  const initial_values = {
    enabled_acumatica: enabled_acumatica,
    location: acumatica_settings.Location,
    customer_id: acumatica_settings.CustomerId,
    company: acumatica_settings.Company,
    api_version: acumatica_settings.EndpointVersion,
    username: acumatica_settings.Name,
    password: acumatica_settings.Password,
    department: use_billing_department,
    acumatica_setting: acumatica_setting,
  };

  // Formik validation schema
  const validationSchema = yup.object(
    fields_required
      ? {
          enabled_acumatica: yup.bool(),
          department: yup.bool(),
          customer_id: yup.string().required("Customer ID is required"),
          acumatica_setting: yup.string().required("Settings is required"),
          company: yup.string().required("Company is required"),
          location: yup.string().when("acumatica_setting", {
            is: (value) => value === "Customerspecificsettings",
            then: yup.string().required("Location is required"),
            otherwise: yup.string(),
          }),
          api_version: yup.string().when("acumatica_setting", {
            is: (value) => value === "Customerspecificsettings",
            then: yup.string().required("API Version is required"),
            otherwise: yup.string(),
          }),
          username: yup.string().when("acumatica_setting", {
            is: (value) => value === "Customerspecificsettings",
            then: yup.string().required("Name is required"),
            otherwise: yup.string(),
          }),
          password: yup.string().when("acumatica_setting", {
            is: (value) => value === "Customerspecificsettings",
            then: yup.string().required("Password is required"),
            otherwise: yup.string(),
          }),
        }
      : {
          enabled_acumatica: yup.bool(),
          location: yup.string(),
          customer_id: yup.string(),
          company: yup.string(),
          api_version: yup.string(),
          username: yup.string(),
          password: yup.string(),
          department: yup.bool(),
          acumatica_setting: yup.string(),
        }
  );

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSaving(true);
      values.id = client_id;
      // For testing purposes
      // alert(JSON.stringify(values, null, 2));
      updateAcumaticaSettings(values)
        .then((xhr) => {
          if (xhr.data && xhr.data.updateClient.client) {
            setError({
              open: true,
              message: "Acumatica settings updated successfully",
              severity: "success",
            });
            formik.resetForm();
            reloadClient();
          } else {
            let error_message = xhr.errors
              ? xhr.errors[0].message
              : "Acumatica settings update failed";
            setError({
              open: true,
              message: `Error: ${error_message}`,
              severity: "error",
            });
          }
        })
        .catch((xhr) => {
          let error_message = xhr.errors
            ? xhr.errors[0].message
            : "Acumatica settings update failed";
          setError({
            open: true,
            message: `Error: ${error_message}`,
            severity: "error",
          });
        })
        // This then() is like a "finally" block from a "try catch" :3
        .then(function () {
          setSaving(false);
        });
    },
  });

  return (
    <div>
      <Paper className={classes.paper}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton height={41.6} width="50%" />
            </Grid>
            <Grid item xs={8}>
              <Skeleton height={40} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={36} width="50%" />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <Grid key={index} item xs={3}>
                      <Skeleton
                        variant="circle"
                        height={32}
                        style={{ margin: "2px" }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        ) : (
          <form onSubmit={formik.handleSubmit} id="acumatica_settings_form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Acumatica</Typography>
              </Grid>
              {/* Checkbox */}
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.enabled_acumatica}
                        name="enabled_acumatica"
                        color="primary"
                        onChange={(event) => {
                          formik.handleChange(event);
                          setFieldsRequired(event.target.checked);
                        }}
                      />
                    }
                    label="Enable Acumatica"
                  />
                </FormGroup>
              </Grid>
              {/*Checkbok Acumatica Billing Department  */}
              <Grid item xs={12} sm={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!formik.values.enabled_acumatica}
                        checked={formik.values.department}
                        name="department"
                        color="primary"
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                      />
                    }
                    label="Enable Acumatica by billing department"
                  />
                </FormGroup>
              </Grid>
              {/* Customer ID */}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="customer_id"
                  disabled={
                    !formik.values.enabled_acumatica ||
                    (formik.values.enabled_acumatica &&
                      formik.values.department)
                  }
                  size="small"
                  margin="dense"
                  label="Customer ID"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.customer_id}
                  fullWidth
                  error={
                    formik.touched.customer_id &&
                    formik.values.enabled_acumatica &&
                    Boolean(formik.errors.customer_id)
                  }
                  helperText={
                    formik.touched.customer_id &&
                    formik.values.enabled_acumatica &&
                    formik.errors.customer_id
                  }
                />
              </Grid>
              {/* Acumatica Setting */}
              <Grid item xs={12} sm={6}>
                <FormControl
                  id="acumatica_setting"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  error={
                    formik.touched.acumatica_setting &&
                    Boolean(formik.errors.acumatica_setting)
                  }
                  disabled={!formik.values.enabled_acumatica}
                >
                  <InputLabel id="acumatica_setting_label">Settings</InputLabel>
                  <Select
                    labelId="acumatica_setting_label"
                    label="Settings"
                    id="acumatica_setting"
                    name="acumatica_setting"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    }}
                    value={formik.values.acumatica_setting}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Resellersettings"}>
                      Reseller Settings
                    </MenuItem>
                    <MenuItem value={"Customerspecificsettings"}>
                      Customer Specific Settings
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.acumatica_setting &&
                      formik.errors.acumatica_setting}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* Company */}
              <Grid
                item
                xs={12}
                sm={
                  formik.values.acumatica_setting == "Resellersettings" ? 12 : 6
                }
              >
                <TextField
                  id="company"
                  disabled={!formik.values.enabled_acumatica}
                  size="small"
                  margin="dense"
                  label="Company (i.e sub-tenant)"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  fullWidth
                  error={
                    formik.touched.company &&
                    formik.values.enabled_acumatica &&
                    Boolean(formik.errors.company)
                  }
                  helperText={
                    formik.touched.company &&
                    formik.values.enabled_acumatica &&
                    formik.errors.company
                  }
                />
              </Grid>
              {formik.values.acumatica_setting ==
                "Customerspecificsettings" && (
                <Fragment>
                  {/* Location */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="location"
                      disabled={!formik.values.enabled_acumatica}
                      size="small"
                      margin="dense"
                      label="Location (url)"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.location}
                      fullWidth
                      error={
                        formik.touched.location &&
                        formik.values.enabled_acumatica &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location &&
                        formik.values.enabled_acumatica &&
                        formik.errors.location
                      }
                    />
                  </Grid>
                  {/* API Version */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="api_version"
                      disabled={!formik.values.enabled_acumatica}
                      size="small"
                      margin="dense"
                      label="API Version"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.api_version}
                      fullWidth
                      error={
                        formik.touched.api_version &&
                        formik.values.enabled_acumatica &&
                        Boolean(formik.errors.api_version)
                      }
                      helperText={
                        formik.touched.api_version &&
                        formik.values.enabled_acumatica &&
                        formik.errors.api_version
                      }
                    />
                  </Grid>
                  {/* username */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="username"
                      disabled={!formik.values.enabled_acumatica}
                      size="small"
                      margin="dense"
                      label="Name (username)"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      fullWidth
                      error={
                        formik.touched.username &&
                        formik.values.enabled_acumatica &&
                        Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username &&
                        formik.values.enabled_acumatica &&
                        formik.errors.username
                      }
                    />
                  </Grid>
                  {/* password */}
                  <Grid
                    item
                    xs={12}
                    sm={
                      formik.values.acumatica_setting ==
                      "Customerspecificsettings"
                        ? 12
                        : 6
                    }
                  >
                    <TextField
                      id="password"
                      type="password"
                      disabled={!formik.values.enabled_acumatica}
                      size="small"
                      margin="dense"
                      label="Password"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      fullWidth
                      error={
                        formik.touched.password &&
                        formik.values.enabled_acumatica &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password &&
                        formik.values.enabled_acumatica &&
                        formik.errors.password
                      }
                    />
                  </Grid>
                </Fragment>
              )}
              {/* Save button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={saving}
                  style={{ float: "right" }}
                >
                  Save
                </Button>
              </Grid>
              {/* ---------- */}
            </Grid>
          </form>
        )}
      </Paper>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </div>
  );
}

AcumaticaSettings.propTypes = {
  client_id: PropTypes.number,
  loading: PropTypes.bool,
  enabled_acumatica: PropTypes.bool,
  acumatica_settings: PropTypes.object,
  reloadClient: PropTypes.func,
  use_billing_department: PropTypes.bool,
  acumatica_setting: PropTypes.string,
};

export default AcumaticaSettings;
