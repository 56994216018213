import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// API
import { listClients } from "../../api/Clients";
import { updateUserStores } from "../../api/Users";

const useStyles = makeStyles((theme) => ({
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));
function AddStoreModal({
  open,
  handleClose,
  userId,
  userStores,
  setRefreshStores,
}) {
  const [stores, setStores] = useState("");
  const [storeSelected, setStoreSelected] = useState("");
  const styles = useStyles();
  const userStoresIds = userStores.map((store) => store.id);

  /*
   * Initial Loading
   */
  useEffect(() => {
    getAvailableStores();
  }, []);

  /**
   * Description: Function to get all available stores of the user
   */
  const getAvailableStores = () => {
    listClients().then((response) => {
      const data = response.data.clients;
      const availableStores = data.filter(
        (store) => !userStoresIds.includes(store.id)
      );
      setStores(availableStores);
    });
  };

  /**
   * Description: Function to assign a new store to the user
   */
  const assingStore = () => {
    const clients = [...userStoresIds];
    clients.push(storeSelected);
    const request = {
      clients,
      id: userId,
    };
    updateUserStores(request).then((response) => {
      if (response.errors) {
        console.log(response.errors);
        return;
      }
      setRefreshStores();
      handleClose();
    });
  };

  return (
    <Dialog
      id="create_client"
      open={open}
      aria-labelledby="create_client_title"
      fullWidth
    >
      <form id="create_client_form">
        <DialogTitle id="create_client_title">Assing Store</DialogTitle>
        <DialogContent
          id="create_client_content"
          style={{ overflowY: "hidden" }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl
                id="division_category_form_control"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="division_category_label">
                  Choose Store
                </InputLabel>
                <Select
                  labelId="division_category_label"
                  label="Division Category"
                  id="selectedDivision"
                  name="selectedDivision"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={storeSelected}
                  onChange={(e) => {
                    setStoreSelected(e.target.value);
                  }}
                >
                  {stores.length ? (
                    stores.map((store, index) => {
                      return (
                        <MenuItem key={index} value={store.id}>
                          {store.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled value={0}>
                      No options
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            id="buttons_container"
            className={styles.ButtonsContainers}
          >
            <Grid item xs={6} md={8}></Grid>
            <Grid item xs={3} md={2}>
              <Button onClick={handleClose} variant="contained" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={2}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={assingStore}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
}

AddStoreModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  userId: PropTypes.string,
  userStores: PropTypes.array,
  setRefreshStores: PropTypes.func,
};

export default AddStoreModal;
