import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackbarMessage(props) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(props.open);

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={props.handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={props.handleClose}
          severity={props.severity}
        >
          {props.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
SnackbarMessage.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
