import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
const { forwardRef, useRef, useImperativeHandle } = React;
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Hidden,
  Container,
  Tab,
  Tabs,
  CircularProgress,
  Button,
  Link,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert, AlertTitle } from "@material-ui/lab";
import ProductCard from "../components/products/ProductCard";
import ProductCardSkeleton from "../components/products/ProductCardSkeleton";
import NavigationTabs from "../components/NavigationTabs";
import {
  searchProductsByProductDivision,
  limitListProductsByProductDivision,
  getProductCategories,
  getProductsFilteredCategories,
} from "../api/Products";
import { useEffect, useCallback } from "react";
import {
  listUserDivisioCategories,
  listRestrictedClientUsers,
  searchClientUsers,
  checkTestingUser,
} from "../api/Users";
import { getUserStagedInvoices } from "../api/Store";
import { deleteStagedLineItem } from "../api/StagedLineItems";
import { makeStyles } from "@material-ui/core/styles";
import ProductDialog from "../components/store/ProductDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import StoreAlertMesage from "../components/store/StoreAlertMessage";
import CustomFilter from "../components/CustomFilter";

const useTabStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

// We need use forwardRef in order to expose ref to getProducts for data refreshing
const StoreProducts = forwardRef((props, ref) => {
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [divisionCategories, setDivisionCategories] = useState([]);
  const [currentDivision, setCurrentDivision] = useState({
    id: "",
    Name: "",
    division_category: {},
  });
  const [selectedProduct, setSelectedProduct] = useState({
    Description: "",
    Image: [],
    Name: "",
    Price: null,
    Published: true,
    Sku: "",
    Summary: "",
    division_categories: [],
    divisions: [],
  });
  const [openProductModal, setOpenProductModal] = useState(false);
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [loading_search, setLoadingSearch] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [searchProductValue, setSearchProductValue] = useState("");
  const [optionValue, setOptionValue] = useState("");
  const classes = useTabStyles();
  //check if url contains client id
  let { client_id } = useParams();
  const history = useHistory();
  const [searchTerms, setSearchTerms] = useState("");
  const [searching, setSearching] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  //check if value changes
  const clientfromlocal = localStorage.getItem("client_id");
  // We need use useImperativeHandle in order to expose ref to getProducts for data refreshing
  useImperativeHandle(ref, () => ({
    refreshData() {
      // List products
      getProducts();
    },
  }));

  /**Infinite scroll */
  const observer = useRef();
  //Create a ref to the las element of the list
  const lastElementRef = useCallback((node) => {
    if (lastPage || productCategories.some((item) => item.checked === true))
      return;
    if (observer.current) {
      // disconect the observer to set the new last element
      observer.current.disconnect();
    }
    // set the new observer to the last element
    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        !productCategories.some((item) => item.checked === true)
      ) {
        limitListProductsByProductDivision(
          currentDivision.id,
          client_id,
          offset
        ).then((result) => {
          let newProductList = productList.concat(result.data.products);
          setProductList(newProductList);
          setOffset(offset + 20);
          if (result.data.products.length < 20) setLastPage(true);
        });
      }
    });
    if (node) observer.current.observe(node);
  });

  const getProducts = () => {
    setOffset(0);
    limitListProductsByProductDivision(currentDivision.id, client_id, 0).then(
      (result) => {
        setProductList(result.data.products);
        setOffset(20);
        setLoadingProducts(false);
      }
    );
  };

  /**
   * Puporse: Get Product categories
   */
  const GetProductCategories = () => {
    const clientId = client_id ? client_id : localStorage.getItem("client_id");
    getProductCategories(clientId).then((result) => {
      if (
        result &&
        result.data &&
        result.data.productCategories &&
        Array.isArray(result.data.productCategories) &&
        result.data.productCategories.length > 0
      ) {
        const categories = result.data.productCategories;
        let newCategories = [];
        for (let category of categories) {
          const object = {
            id: category.id,
            Name: category.Name,
            checked: false,
          };
          newCategories.push(object);
        }
        setProductCategories(newCategories);
      }
    });
  };

  const getUsers = () => {
    listRestrictedClientUsers(client_id).then((res) => {
      let iterateUsers = res.data.users;
      iterateUsers.forEach((user) => {
        user.search_label = user.Details
          ? user.Details.FirstName + " " + user.Details.LastName
          : user.username;
      });
      setUsers(iterateUsers);
    });
  };

  /**
   * Get the division categories
   */
  const listDivisionCategories = () => {
    setLoading(true);
    let store_user = JSON.parse(localStorage.getItem("store_user"));
    let user_id =
      store_user && store_user.id
        ? store_user.id
        : localStorage.getItem("user");
    listUserDivisioCategories(user_id, client_id).then((res) => {
      let responseDivisions = res.data.user.division_categories;
      let divisonsToSet = [];
      //only list the division categories for this client
      if (responseDivisions && responseDivisions.length > 0) {
        for (let index = 0; index < responseDivisions.length; index++) {
          if (responseDivisions[index].product_division_categories.length > 0) {
            divisonsToSet.push(responseDivisions[index]);
          }
        }
        if (divisonsToSet.length) {
          divisonsToSet.forEach((divi) => {
            //remove the points from othe users
            divi.points = _.remove(divi.points, function (n) {
              return (
                n.user_id != null && n.user_id.id && n.user_id.id == user_id
              );
            });
          });
          setDivisionCategories(divisonsToSet);

          //create the array for the division to set active.
          let firstDivision = {
            id: divisonsToSet[0].id,
            Name: divisonsToSet[0].Name,
            division_category:
              divisonsToSet[0].product_division_categories[0].division,
          };
          setCurrentDivision(firstDivision);
          const role = localStorage.getItem("role");
          if (role == "customer") {
            GetProductCategories();
          }
        }
      } else {
        setDivisionCategories([]);
        setCurrentDivision({
          id: "",
          Name: "",
          division_category: {},
        });
      }
      setLoading(false);
    });
    //}
  };

  /**
   * Set current DC
   * @param {object} division
   */
  const changeDivisionCategory = (division) => {
    let firstDivision = {
      id: division.id,
      Name: division.Name,
      division_category: division.product_division_categories[0].division,
    };
    setCurrentDivision(firstDivision);
    setLoadingProducts(true);
    setOffset(0);
    setLastPage(false);
  };

  /**
   * Initial loading
   */
  useEffect(async () => {
    // Get user division categories
    if (clientfromlocal !== null) {
      setLoadingProducts(true);

      listDivisionCategories();
      if (client_id) {
        getUsers();
      }
      //check if the selected user is not the logged user
      let store_user = JSON.parse(localStorage.getItem("store_user"));
      if (store_user && store_user.id) {
        if (store_user.client_id == client_id) {
          setUser(store_user);
        } else {
          //Is user test
          const isTestUser = await checkTestingUser(store_user.id);
          if (isTestUser) {
            //check the current staged invoce
            getUserStagedInvoices().then((results) => {
              let currentStagedInvoice = false;
              if (results.data && results.data.stagedInvoices) {
                results.data.stagedInvoices.forEach((invoice) => {
                  if (!invoice.order_invoice) {
                    currentStagedInvoice = invoice;
                  }
                  //removing products
                  if (
                    currentStagedInvoice &&
                    currentStagedInvoice.staged_line_items.length > 0
                  ) {
                    const staged_line_items =
                      currentStagedInvoice.staged_line_items;
                    staged_line_items.forEach((item) => {
                      deleteStagedLineItem(item.id).then((results) => {
                        if (results) {
                          props.getUserCart();
                        }
                      });
                    });
                  }
                });
              }
            });
          }
          localStorage.removeItem("store_user");
        }
      }
    }
  }, [clientfromlocal]);

  /**
   * Reaload products when DC is changed
   */
  useEffect(() => {
    if (currentDivision.id != "") {
      if (productCategories.some((item) => item.checked === true)) {
        let productCategoriesIds = "";
        for (let category of productCategories) {
          if (category.checked) {
            productCategoriesIds +=
              productCategoriesIds.length > 0
                ? `, ${category.id}`
                : category.id;
          }
        }
        getProductsFilteredCategories(
          currentDivision.id,
          client_id,
          productCategoriesIds
        ).then((result) => {
          const products =
            result && result.data && result.data.products
              ? result.data.products
              : [];
          setProductList([...products]);
          setLoadingProducts(false);
          setLastPage(true);
        });
      } else {
        getProducts();
      }
    }
  }, [currentDivision]);

  const triggerProductModal = (product) => {
    if (product.id) {
      setSelectedProduct(product);
    } else {
      if (product && product.sucess == "Add_sucess") {
        //Show SnackbarMessage
        setError({
          open: true,
          message: "Added " + product.ProductName + " to your shopping bag.",
          severity: "success",
        });
      }
      setSelectedProduct({
        Description: "",
        Image: [],
        Name: "",
        Price: null,
        Published: true,
        Sku: "",
        Summary: "",
        division_categories: [],
        divisions: [],
      });
    }
    setOpenProductModal(!openProductModal);
  };

  const handleCloseMessage = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  const selectUser = (value) => {
    setUser(value);
    if (value == null) {
      localStorage.removeItem("store_user");
      listDivisionCategories();

      props.getUserCart();
    } else {
      value.client_id = client_id;
      value.name = value.Details
        ? value.Details.FirstName
          ? value.Details.FirstName
          : value.username
        : value.username;
      localStorage.setItem("store_user", JSON.stringify(value));
      listDivisionCategories();
      props.getUserCart();
    }
  };

  /**
   * Search
   */
  useEffect(() => {
    let timer1 = setTimeout(() => {
      let terms = searchTerms ? searchTerms.split(" ") : [];
      let searchArray = [];
      terms.forEach((word) => {
        let searchObj = { Details_contains: word };
        searchArray.push(searchObj);
      });
      // Get filtered users
      searchClientUsers(client_id, searchArray, true)
        .then((result) => {
          //filtering users
          let filter_users = [];
          if (result.data && result.data.users) {
            const users = result.data.users;
            for (let i = 0; i < users.length; i++) {
              if (users[i].confirmed) {
                //add users to list search
                filter_users.push(users[i]);
              }
            }
          }
          setUsers(filter_users);
          setSearching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  }, [searchTerms]);

  // Load product categories
  useEffect(() => {
    const role = localStorage.getItem("role");
    // Get Produc Categories
    if (role !== "customer") {
      GetProductCategories();
    }
  }, []);

  /**
   * Puporse Filter by category
   * @param {Object} category
   */
  const onChangeFilters = (category) => {
    if (category && category.id) {
      const filters = productCategories;
      const exist = filters.findIndex((item) => item.id == category.id);
      filters[exist].checked = !category.checked;
      setProductCategories([...filters]);
    } else {
      const filters = productCategories.map((item) => {
        return {
          id: item.id,
          Name: item.Name,
          checked: false,
        };
      });
      setProductCategories([...filters]);
    }
  };

  /**
   * Processing products when filters are updated
   */
  useEffect(() => {
    if (productCategories.some((item) => item.checked === true)) {
      let productCategoriesIds = "";
      for (let category of productCategories) {
        if (category.checked) {
          productCategoriesIds +=
            productCategoriesIds.length > 0 ? `, ${category.id}` : category.id;
        }
      }
      getProductsFilteredCategories(
        currentDivision.id,
        client_id,
        productCategoriesIds
      ).then((result) => {
        const products =
          result && result.data && result.data.products
            ? result.data.products
            : [];
        setProductList([...products]);
      });
    } else {
      if (currentDivision && currentDivision.id) {
        setLoadingProducts(true);
        setLastPage(false);
        getProducts();
      }
    }
  }, [productCategories]);

  // Render view
  return (
    <Container
      style={{ marginTop: "65px" }}
      maxWidth={client_id ? "xl" : "lg"}
      className={client_id ? classes.mainContainer : ""}
    >
      {client_id ? (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} md={10} style={{ paddingBottom: "20px" }}>
            <NavigationTabs value={"store"} client_id={Number(client_id)} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Grid container style={{ padding: "20px" }} spacing={2}>
        {/**Client alert message */}
        <Grid item xs={12}>
          <StoreAlertMesage clientId={client_id} />
        </Grid>
        {client_id ? "" : <Grid item xs={3}></Grid>}
        {client_id ? (
          <Grid item xs={12} md={3}>
            <Autocomplete
              autoComplete
              autoHighlight
              freeSolo
              style={{ marginLeft: "auto" }}
              id="searchEmployee"
              clearOnBlur
              inputValue={searchTerms}
              value={user}
              loading={searching}
              loadingText={"Searching..."}
              options={searching ? [] : users}
              getOptionLabel={(option) =>
                option.search_label ? option.search_label : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Search employees"}
                  placeholder="Search employees"
                  margin="none"
                  variant="outlined"
                  onChange={(e) => {
                    setSearching(true);
                    setSearchTerms(e.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "-30px" }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(event, value /*reason*/) => {
                selectUser(value);
                setSearchTerms("");
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={3}></Grid>
        )}
        {user && user.id ? (
          <Grid item md={6}>
            <Alert
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    selectUser(null);
                  }}
                >
                  Reset
                </Button>
              }
            >
              <AlertTitle>{`${user.search_label}'s Store`}</AlertTitle>
              {`Orders will be placed on ${user.search_label}'s account`}
              <br />
              <br />
              <Link
                href="#"
                onClick={() => {
                  history.push(
                    `/app/client/${client_id}/store/products/user/${user.id}`
                  );
                }}
              >
                {`See ${user.name}'s order history`}
              </Link>
            </Alert>
          </Grid>
        ) : client_id ? (
          <Hidden mdDown>
            <Grid item md={6}></Grid>
          </Hidden>
        ) : (
          <Hidden smDown>
            <Grid item md={3}></Grid>
          </Hidden>
        )}
        {divisionCategories.length ? (
          <Grid item xs={12} md={3}>
            <Autocomplete
              autoComplete
              autoHighlight
              clearOnEscape
              //reeSolo
              style={{ marginLeft: "auto" }}
              id="search"
              //disableClearable
              clearOnBlur
              options={products}
              getOptionLabel={(option) =>
                option.search_label ? option.search_label : ""
              }
              //options={products.map((option) => option.Name)}
              value={optionValue}
              inputValue={searchProductValue}
              onChange={(event, value) => {
                setSearchProductValue("");
                setOptionValue("");
                value ? triggerProductModal(value) : null;
              }}
              loading={loading_search}
              onInputChange={(event, value) => {
                setSearchProductValue(value);
                if (value.length > 2) {
                  setLoadingSearch(true);
                  searchProductsByProductDivision(
                    currentDivision.id,
                    value,
                    client_id
                  ).then((result) => {
                    setLoadingSearch(false);
                    setProducts(result.data.products);
                  });
                }
              }}
              onClose={() => {
                setSearchProductValue("");
                setOptionValue("");
                setProducts([]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Search products"}
                  placeholder={`Search products in ${currentDivision.Name} division`}
                  margin="none"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "-56px" }}
                      >
                        {loading_search ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        ) : (
          ""
        )}
        {divisionCategories.length ? (
          <Grid item xs={12}>
            <Tabs
              value={currentDivision.id != "" ? currentDivision.id : "1"}
              // onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
              classes={{ root: classes.root, scroller: classes.scroller }}
            >
              {divisionCategories.map((division) => {
                return (
                  <Tab
                    value={division.id}
                    key={division.id}
                    onClick={() => changeDivisionCategory(division)}
                    label={
                      <Fragment>
                        <Typography variant="button">
                          {division.Name}
                        </Typography>
                        <Typography variant="caption">
                          Points:{" "}
                          {division.points.length &&
                          division.points[division.points.length - 1]
                            .Balance !== null
                            ? division.points[division.points.length - 1]
                                .Balance
                            : ""}
                        </Typography>
                      </Fragment>
                    }
                  />
                );
              })}
            </Tabs>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={11}>
          <div className={classes.chips}>
            {productCategories.map((item, index) => {
              if (item.checked) {
                return (
                  <Chip
                    key={index}
                    label={item.Name}
                    color="primary"
                    onDelete={() => onChangeFilters(item)}
                  />
                );
              }
            })}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div style={{ float: "right" }}>
            <CustomFilter
              filters={productCategories}
              onChangeFilters={(category) => onChangeFilters(category)}
            />
          </div>
        </Grid>
        {divisionCategories.length ? (
          <Grid
            container
            spacing={3}
            direction="flex"
            alignItems="center"
            justify="center"
            style={{ marginTop: "20px" }}
          >
            {loadingProducts ? (
              [1, 2, 3, 4].map((index) => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <ProductCardSkeleton />
                  </Grid>
                );
              })
            ) : productList.length ? (
              productList.map((product, index) => {
                if (productList.length == index + 1) {
                  return (
                    //  each last product
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      key={index}
                      ref={lastElementRef}
                    >
                      <ProductCard
                        product={product}
                        preventNavigation={true}
                        clickAction={() => triggerProductModal(product)}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                      <ProductCard
                        product={product}
                        preventNavigation={true}
                        clickAction={() => triggerProductModal(product)}
                      />
                    </Grid>
                  );
                }
              })
            ) : (
              <Typography variant={"h5"}>
                No Products in this division category
              </Typography>
            )}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      {!divisionCategories.length && !loading ? (
        <Grid container style={{ padding: "0 20px 20px 20px" }}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              This user doesn&apos;t have any division categories, please
              contact an administrator.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <ProductDialog
        open={openProductModal}
        product={selectedProduct}
        handleClose={triggerProductModal}
        currentDivision={currentDivision}
        getUserCart={props.getUserCart}
        client_id={client_id ? client_id : clientfromlocal}
      />
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseMessage}
      />
    </Container>
  );
});

StoreProducts.propTypes = {
  getUserCart: PropTypes.func,
};

StoreProducts.displayName = "ProductList";

export default StoreProducts;
