// Libraries
import * as React from "react";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

//Routing
import { useHistory } from "react-router";

//MUI
import {
  Grid,
  Typography,
  Container,
  Box,
  TextField,
  Link,
  CssBaseline,
  Button,
  makeStyles,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// API
import { resetPassword } from "../api/Users";

//Components
import SnackbarMessage from "../components/SnackbarMessage";

/**
 * Styles
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SetNewPassword() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  //the code contained in  url
  const code = queryParams.get("code");
  // Manage errors
  const [error, setError] = React.useState({
    message: "",
    severity: "error",
    open: false,
  });

  //  Formik validation schema
  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    passwordConfirmation: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      resetPassword(values, code).then((res) => {
        if (res.data.resetPassword && res.data.resetPassword.user) {
          setSuccess(true);
          // Close snackbar
          setError({
            open: true,
            message: "Password changed, you can now log in.",
            severity: "success",
          });
          formik.resetForm();
        } else {
          setSuccess(false);
          let message =
            res.errors[0].extensions.exception.data.message[0].messages[0]
              .message;
          setError({
            open: true,
            message: message,
            severity: "error",
          });
        }
      }).catch(() => {
        setError({
          open: true,
          message: "Error while requesting resetPassword",
          severity: "error",
        });
      }).then(() => {
        setLoading(false);
      });
    },
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    if (success) {
      history.push("/");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Avid.io
        </Typography>
        <Typography variant="h6">Reset password</Typography>
        {code ? (
          <Box
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            className={classes.form}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              // autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              // autoComplete="current-password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
            <Grid container direction="column" alignItems="flex-end">
              <Button
                type="submit"
                fullWidth
                color="primary"
                disabled={loading}
                endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                variant="contained"
                className={classes.submit}
              >
                Reset Password
              </Button>
              <Grid item xs={12} container justify="center">
                <Link href="#" onClick={() => history.push("/")}>
                  Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Typography>Incorrect code provided.</Typography>
            <Grid item xs={12} container justify="center">
              <Link href="#" onClick={() => history.push("/")}>
                Login
              </Link>
            </Grid>
          </>
        )}
      </div>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
    </Container>
  );
}
