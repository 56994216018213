import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

export const searchProducts = async (search_term) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let where = `where: {
    _or: [
      { Name_contains: "${search_term}" },
      { Sku_contains: "${search_term}" }
    ],
    reseller:${reseller_id}
  }`;
  axiosOptions.data = {
    query: `
        query{
            products(sort:"Name", ${where}){
            id,
            Name,
            Sku,
            Price,
            Published,
            Image{
                url
                id
              }
            }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data && response.data.data.products) {
      // create search label
      response.data.data.products.forEach((product) => {
        let search_label = product.Name + " - " + product.Sku;
        product.search_label = search_label;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const limitListProducts = async (offset) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
        query{
            products(limit:20, start:${offset}, sort:"Name", ${where}){
            id,
            Name,
            Sku,
            Price,
            Published,
            Image{
                url
                id
              }
            }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createProduct = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Validate summary
  const summary = values.Summary.replace(/(?:\r\n|\r|\n|")/g, (match) => {
    if (match === '"') {
      return '\\"';
    } else {
      return "\\n";
    }
  });
  axiosOptions.data = {
    query: `
        mutation{
          createProduct(
            input:{
              data:{
                ${values.client_id ? "clients:" + values.client_id : ""}
                reseller: ${reseller_id},
                Name:"${values.Name}",
                Description:"${values.Description}",
                Summary:"${summary}",
                Price:${Number(values.Price)},
                Sku:"${values.Sku}",
                Published:${values.Published} 
                Image: [${values.imagesArrray}]
                ${
                  values.productCategory
                    ? "product_category:" + values.productCategory
                    : ""
                }
              }
            }
          )
          {
            product{
              id
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getProductById = async (id, client_id = null) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let where = client_id ? `where:{client: ${client_id}}` : "";
  axiosOptions.data = {
    query: `
      query{
        product(id:${id}){
          id,
          Name,
          Sku,
          Summary,
          Description,
          Price,
          Published,
          active,
          Image{
            url
            id
          }
          categories:product_division_categories(sort: "division_category.Name", ${where}){
            id
            division_category{
              id
              Name
            }
            display_order
          }
          attributes:product_product_attributes(sort: "product_attribute.Name", ${where}){
            id
            product_attribute{
              id
              Name
              ControlType
            }
            display_order
            required
          }
          clients {
            id
          }
          product_category{
            id
            Name
          }
        },
        productAttributes(where:{reseller: ${reseller_id}}){Name, id},
        divisionCategories(where:{clients: [${client_id}]}){Name, id},
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProduct = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateProduct(
            input:{
              where:{id:${values.id}},
              data:{
                Name:"${values.Name}",
                Description:"${values.Description}",
                Summary:"${values.Summary.replace(/(?:\r\n|\r|\n)/g, "\\n")}",
                Price:${Number(values.Price)},
                Sku:"${values.Sku}",
                Published:${values.Published},
                Image: [${values.imagesArrray}]
                product_category: ${
                  values.productCategory ? values.productCategory : null
                }
              }
            }
          )
          {
            product{
              id,
              Name,
              Description,
              Summary,
              Price,
              Sku,
              Published
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Removes product and its relations
 * @param {Int} id 'ID' Field id on the table products
 * @returns {Boolean} Status True or False
 */
export const deleteProduct = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation {
          deletedProduct(input:{productId: ${id}}){
            status
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const limitListProductsByDivision = async (
  division_category,
  offset
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let where = `where:{division_categories: [${division_category}]}`;
  axiosOptions.data = {
    query: `
        query{
            products(limit:20, start:${offset}, sort:"Name", ${where}){
              id
              Name
              Sku
              Price
              Published
              Image{
                  url
                  id
                }
              division_categories{
                Name
              }
              Description
              Summary
              product_attributes{
                id
                Name
                DisplayText
                product_attribute_values{
                  Name
                  id
                  CostAdjustment
                }
                ControlType
              }
            }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const searchProductsByDivision = async (
  division_category,
  search_term
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let where = `where: {
    _or: [
      { Name_contains: "${search_term}" },
      { Sku_contains: "${search_term}" }
    ],
    division_categories: [${division_category}]
  }`;
  axiosOptions.data = {
    query: `
        query{
            products(sort:"Name", ${where}){
              id
              Name
              Sku
              Price
              Published
              Image{
                  url
                  id
                }
              division_categories{
                Name
              }
              Description
              Summary
              product_attributes{
                id
                Name
                DisplayText
                product_attribute_values{
                  Name
                  id
                  CostAdjustment
                }
                ControlType
              }
            }
            
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data && response.data.data.products) {
      // create search label
      response.data.data.products.forEach((product) => {
        let search_label = product.Name + " - " + product.Sku;
        product.search_label = search_label;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const listProductAttributes = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
      query{
        productAttributes(${where}){
          id
          DisplayText
          Name
          ControlType
        }
      }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteProductAttribute = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          deleteProductAttribute(
            input:{
              where:{id:${id}},
            }
          )
          {
            productAttribute{
              id,
              Name
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createProductAttribute = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
    mutation{
      createProductAttribute(
          input:{
              data:{
                Name:"${values.Name}",
                DisplayText:"${values.DisplayText}"
                ControlType:${values.ControlType}
                reseller: ${reseller_id}
                
          }
        }
      )
        {
          productAttribute{
              id
          }
        }
      }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductAttribute = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateProductAttribute(
          input:{
            where:{id:${values.id}},
            data:{
              Name:"${values.Name}",
              DisplayText:"${values.DisplayText}"
              ControlType:${values.ControlType}
            }
          }
        )
        {
          productAttribute{
              id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getProductAttributeInfo = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  //let reseller_id = localStorage.getItem("reseller_id");
  //let where = `where:{reseller: [${reseller_id}]}`;
  axiosOptions.data = {
    query: `
    query {
      productAttribute(id: ${id}) {
        id
        DisplayText
        Name
        ControlType
        product_attribute_values {
          id
          Name
          DisplayOrder
          CostAdjustment
          SkuExtension
        }
        product_product_attributes{
          id
          display_order
          required
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createProductAttributeValue = async (attributeId, values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        createProductAttributeValue(
          input: {
            data: {
              product_attribute: ${attributeId}
              Name: "${values.Name}"
              DisplayOrder: ${values.DisplayOrder}
              CostAdjustment: ${values.CostAdjustment}
              SkuExtension: "${values.SkuExtension}"
            }
          }
        ) {
          productAttributeValue {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductAttributeValue = async (id, values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateProductAttributeValue(
          input:{
            where:{id:${id}},
            data:{
              Name: "${values.Name}"
              DisplayOrder: ${values.DisplayOrder}
              CostAdjustment: ${values.CostAdjustment}
              SkuExtension: "${values.SkuExtension}"
            }
          }
        )
        {
          productAttributeValue{
              id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteProductAttributeValue = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          deleteProductAttributeValue(
            input:{
              where:{id:${id}},
            }
          )
          {
            productAttributeValue{
              id,
              Name
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductClients = async (id, clients) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        updateProduct(input: { where: { id: ${id} }, data: { clients: [${clients}] } }) {
          product {
            id
            Name
            Description
            Summary
            Price
            Sku
            Published
            clients {
              id
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {*} division_category
 * @param {*} offset
 * @returns
 */
export const limitListProductsByProductDivision = async (
  division_category,
  client,
  offset
) => {
  let client_id = client ? client : localStorage.getItem("client_id");
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let where = ` where: { clients:[${client_id}], product_division_categories:{division_category:${division_category}, client:${client_id}}, active:${1} }`;
  /// Removed client id from where clause and addede client id to response, this to filter attributes from the selected client the ones that have null client
  axiosOptions.data = {
    query: `
      query{
        products(limit:20, start:${offset}, sort:"product_division_categories.display_order, Name", ${where}){
          id
          Name
          Sku
          Price
          Published
          Image{
            url
            id
          }
          divisions:product_division_categories{
            id
            display_order
            division:division_category{
              id
              Name
            }
          }
          division_categories{
            Name
          }
          Description
          Summary
          product_product_attributes(
            sort: "display_order"
            ) {
            id
            required
            client{id}
            product_attribute {
              id
              Name
              DisplayText
              product_attribute_values {
                Name
                id
                CostAdjustment
              }
              ControlType
            }
          }
          product_category{
            id
            Name
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);

    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {*} division_category
 * @param {*} search_term
 * @returns
 */
export const searchProductsByProductDivision = async (
  division_category,

  search_term,
  client
) => {
  let client_id = client ? client : localStorage.getItem("client_id");
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let where = `where: {
    _or: [
      { Name_contains: "${search_term}" active: ${1}},
      { Sku_contains: "${search_term}" active: ${1}}
    ],
    clients:[${client_id}], 
    product_division_categories:{division_category:${division_category}, client:${client_id}} 
  }`;
  axiosOptions.data = {
    query: `
        query{
            products(sort:"Name", ${where}){
              id
              Name
              Sku
              Price
              Published
              Image{
                  url
                  id
                }
              divisions:product_division_categories{
                id
                division:division_category{
                  id
                  Name
                }
              }
              division_categories{
                Name
              }
              Description
              Summary
              product_product_attributes(
                sort: "display_order"
                ) {
                id
                required
                client{id}
                product_attribute {
                  id
                  Name
                  DisplayText
                  product_attribute_values {
                    Name
                    id
                    CostAdjustment
                  }
                  ControlType
                }
              }
            }
            
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data && response.data.data.products) {
      // create search label
      response.data.data.products.forEach((product) => {
        let search_label = product.Name + " - " + product.Sku;
        product.search_label = search_label;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get all products from single reseller
 * @returns object
 */
export const listAllProductsFromReseller = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let where = `
    where:{
      reseller: [${reseller_id}]
      _limit: -1
    }
  `;
  axiosOptions.data = {
    query: `
      query{
        products(sort:"Name", ${where}){
          id,
          Name
          Description
          Summary
          Price
          Sku
          Published
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*
 * Get all products from single reseller
 * @returns object
 */
export const listAllProductsFromResellerV2 = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // query
  axiosOptions.data = {
    query: `
     query {
      getProductsTable(reseller_id: ${reseller_id}) {
        id
        Picture
        Name
        Sku
        Product_category
        Price
      }
    }
   `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get all product categories from reseller
 * @return {Object} Product categories
 */
export const getProductCategories = async (clientId = null) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Validates if filtering by client
  const clientIdFilter = clientId ? `products: {clients: [${clientId}]}` : "";
  let where = `
   where:{
     reseller: [${reseller_id}]
     _limit: -1
     ${clientIdFilter}
   }
 `;
  axiosOptions.data = {
    query: `
    query{
      productCategories(${where}){
        id
        Name
      }
    }
 `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create new product category
 * @param {String} name prodcut category name
 * @returns {Object} new product category
 */
export const createProductCategory = async (name) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
          mutation{
            createProductCategory(input:{
              data:{
                Name: "${name}"
                reseller: ${reseller_id}
              }
            }){
              productCategory{
                id
                Name
              }
            }
          }
        `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update Product published
 * @param {*} values
 * @returns
 */
export const updateProductPublished = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        mutation{
          updateProduct(
            input:{
              where:{id:${values.id}},
              data:{
                Published:${values.Published},
              }
            }
          )
          {
            product{
              id,
              Published
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addTemplateAttributes = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    mutation{
      updateProduct(
        input:{
          where:{id:${values.id}},
          data:{
            templateId:${values.templateId},
          }
        }
      )
      {
        product{
          id,
          Name,
          Description,
          Summary,
          Price,
          Sku,
        }
      }
    }
  `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get products filtered by categories
 * @return {Object} Product categories
 */
export const getProductsFilteredCategories = async (
  division_category,
  client,
  productCategoryIds
) => {
  let client_id = client ? client : localStorage.getItem("client_id");
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let where = ` where: { 
    clients:[${client_id}], 
    product_division_categories:{division_category:${division_category}, 
    client:${client_id}},
    product_category: [${productCategoryIds}]  
  }`;
  /// Removed client id from where clause and addede client id to response, this to filter attributes from the selected client the ones that have null client
  axiosOptions.data = {
    query: `
      query{
        products(limit:-1, sort:"product_division_categories.display_order, Name", ${where}){
          id
          Name
          Sku
          Price
          Published
          Image{
            url
            id
          }
          divisions:product_division_categories{
            id
            display_order
            division:division_category{
              id
              Name
            }
          }
          division_categories{
            Name
          }
          Description
          Summary
          product_product_attributes(
            sort: "display_order"
            ) {
            id
            required
            client{id}
            product_attribute {
              id
              Name
              DisplayText
              product_attribute_values {
                Name
                id
                CostAdjustment
              }
              ControlType
            }
          }
          product_category{
            id
            Name
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
