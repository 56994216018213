import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";


function AlertDialog({
  // Open button props
  openButtonProps,
  openButton = "button",
  openButtonIcon = <DeleteIcon/>,
  openButtonText = "Delete",
  openButtonVariant = "contained",
  openButtonColor = "secondary",
  openButtonStartIcon = null,
  openButtonSize = "medium",
  openButtonClassName = null,
  // Dialog props
  dialogTitle = "Title",
  dialogContentText = "Are you sure you want to continue?",
  // Close button props
  closeButtonText = "No",
  closeButtonVariant = "contained",
  closeButtonColor = "default",
  // Confirm button props
  confirmButtonText = "Yes",
  confirmButtonClick = () => {},
  confirmButtonVariant = "contained",
  confirmButtonColor = "secondary",
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {openButton === "button" ? (
        <Button
          {...openButtonProps}
          variant={openButtonVariant}
          color={openButtonColor}
          startIcon={openButtonStartIcon}
          size={openButtonSize}
          className={openButtonClassName}
          onClick={handleClickOpen}
        >
          {openButtonText}
        </Button>
      ) : null}
      {openButton === "icon" ? (
        <IconButton
        edge="end"
        aria-label="delete_item"
        aria-haspopup="true"
        color={openButtonColor}
        onClick={handleClickOpen}
      >
        {openButtonIcon}
      </IconButton>
      ) : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant={closeButtonVariant}
            color={closeButtonColor}
          >
            {closeButtonText}
          </Button>
          <Button
            onClick={() => {
              confirmButtonClick();
              handleClose();
            }}
            variant={confirmButtonVariant}
            color={confirmButtonColor}
            autoFocus
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

AlertDialog.propTypes = {
  openButtonProps: PropTypes.object,
  openButton: PropTypes.string,
  openButtonIcon: PropTypes.node,
  openButtonText: PropTypes.string,
  openButtonVariant: PropTypes.string,
  openButtonColor: PropTypes.string,
  openButtonStartIcon: PropTypes.node,
  openButtonSize: PropTypes.string,
  openButtonClassName : PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  closeButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonClick: PropTypes.func,
  confirmButtonVariant: PropTypes.string,
  confirmButtonColor: PropTypes.string,
};

export default AlertDialog;
