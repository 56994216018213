// Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
// Custom components
import ConfirmationDialog from "../ConfirmationDialog";
// API
import { states } from "../../api/States";
import { countries } from "../../api/Countries";
import { provinces } from "../../api/CanadianProvinces";
import {
  createAddress,
  updateAddress,
  deleteAddress,
} from "../../api/Addresses";
import {
  createUserCustomer,
  updateUserCustomer,
  deleteCustomer,
} from "../../api/Customers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));

// Main component
const AddressesModal = (props) => {
  // Flag to know if canada is selected or no
  const [canadaSelected, setCanadaSelected] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const classes = useStyles();
  // Initial form data
  const [initial_values, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    second_address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "US",
  });

  let user_id = props.user_id;
  let client_id = props.client_id;

  useEffect(() => {
    if (props.selectedAddress.id) {
      // Set the value of canadaSelected according to the selected address country
      setCanadaSelected(props.selectedAddress.address.Country == 'CA');
      let selectedAddress = {
        first_name: props.selectedAddress.FirstName,
        last_name: props.selectedAddress.LastName,
        email: props.selectedAddress.ConfirmationEmail,
        phone: props.selectedAddress.Phone,
        address: props.selectedAddress.address.Street,
        second_address: props.selectedAddress.address.Street2,
        city: props.selectedAddress.address.City,
        state: props.selectedAddress.address.State,
        postal_code: props.selectedAddress.address.Zip,
        country: props.selectedAddress.address.Country,
      };
      setInitialValues(selectedAddress);
      formik.resetForm({
        values: selectedAddress,
      });
    } else {
      // Creating address, then set the value of canadaSelected to default value
      setCanadaSelected(false);
      setInitialValues({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        second_address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "US",
      });
      formik.resetForm({
        values: initial_values,
      });
    }
  }, [props.open]);

  // Use this regex for US
  const usRegEx = /^[0-9A-Z]{5}$/;
  // Use this regex when Canada is selected (taken from Acumatica > Configuration > Countries/States > Canada)
  const canadaRegEx = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
  // Formik validation schema
  const validation_schema = yup.object({
    first_name: yup.string("First Name").required("First Name is required"),
    last_name: yup.string("Last Name").required("Last Name is required"),
    email: yup
      .string("Confirmation Email")
      .email("Invalid email address")
      .required("Confirmation Email is required"),
    phone: yup
      .string("Phone")
      .min(10, "Phone must be 10 digits")
      .max(10, "Phone must be 10 digits")
      .required("Phone is required"),
    address: yup.string("Address").required("Address is required"),
    second_address: yup.string("Address 2").optional(),
    city: yup.string("City").required("City is required"),
    state: yup.string("State").required(`${canadaSelected ? 'Province' : 'State'} is required`),
    postal_code: yup
      .string("Postal Code")
      .test("maxLenght", `Postal Code must be ${canadaSelected ? '6 characters' : '5 digits'}`, (value) => {
        if (value) {
          const postalCodeMaxLenght = canadaSelected ? 6 : 5;
          return value.replaceAll(' ', '').length === postalCodeMaxLenght;
        }
      })
      .test("onlyNumbers", "Only numbers", (value) => {
        if (value) {
          return canadaSelected ? true : !isNaN(value);
        }
      })
      .matches(canadaSelected ? canadaRegEx : usRegEx, 'Invalid Postal Code')
      .required("Postal Code is required"),
    country: yup.string("Country").optional(),
  });

  // Manage form data
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validation_schema,
    onSubmit: (values) => {
      setSaving(true);
      // Create address
      let address_values = {
        street: values.address,
        second_street: values.second_address,
        city: values.city,
        state: values.state,
        zip: values.postal_code,
        country: values.country,
      };
      if (props.selectedAddress.id) {
        //Edit customer address
        updateAddress(props.selectedAddress.address.id, address_values)
          .then((xhr) => {
            if (xhr.data && xhr.data.updateAddress) {
              let address_id = xhr.data.updateAddress.address.id;

              let customer_values = {
                first_name: values.first_name,
                last_name: values.last_name,
                address_id: address_id,
                email: values.email,
                phone: values.phone,
              };
              if (client_id) {
                customer_values.client_id = client_id;
              } else {
                customer_values.user_id = user_id;
              }
              updateUserCustomer(props.selectedAddress.id, customer_values)
                .then((xhr) => {
                  if (xhr.data && xhr.data.updateCustomer) {
                    setSaving(false);
                    props.handleClose();
                    props.listUserCustomers();
                    props.setError({
                      open: true,
                      message: `Address updated successfully`,
                      severity: "success",
                    });
                  } else {
                    setSaving(false);
                    props.setError({
                      open: true,
                      message: `Error updating customer`,
                      severity: "error",
                    });
                  }
                })
                .catch(() => {
                  props.setError({
                    open: true,
                    message: `Error updating customer`,
                    severity: "error",
                  });
                });
            } else {
              let error_message = xhr.errors
                ? xhr.errors[0].message
                : "Error updating address";
              props.setError({
                open: true,
                message: `Error: ${error_message}`,
                severity: "error",
              });
            }
          })
          .catch(() => {
            props.setError({
              open: true,
              message: `Error updating address`,
              severity: "error",
            });
          });
      } else {
        createAddress(address_values)
          .then((xhr) => {
            if (xhr.data && xhr.data.createAddress) {
              let address_id = xhr.data.createAddress.address.id;
              // Create customer

              let customer_values = {
                first_name: values.first_name,
                last_name: values.last_name,
                address_id: address_id,
                email: values.email,
                phone: values.phone,
              };
              if (client_id) {
                customer_values.client_id = client_id;
              } else {
                customer_values.user_id = user_id;
              }
              createUserCustomer(customer_values)
                .then((xhr) => {
                  if (xhr.data && xhr.data.createCustomer) {
                    setSaving(false);
                    props.handleClose();
                    props.listUserCustomers();
                    props.setError({
                      open: true,
                      message: `Address created successfully.`,
                      severity: "success",
                    });
                  } else {
                    setSaving(false);
                    props.setError({
                      open: true,
                      message: `Error creating customer`,
                      severity: "error",
                    });
                  }
                })
                .catch(() => {
                  props.setError({
                    open: true,
                    message: `Error creating customer`,
                    severity: "error",
                  });
                });
            } else {
              let error_message = xhr.errors
                ? xhr.errors[0].message
                : "Error creating address";
              props.setError({
                open: true,
                message: `Error: ${error_message}`,
                severity: "error",
              });
            }
          })
          .catch(() => {
            props.setError({
              open: true,
              message: `Error creating address`,
              severity: "error",
            });
          });
      }
    },
  });

  //delete address
  const deleteSelectedAddress = () => {
    let address_id = props.selectedAddress.address.id;
    let customer_id = props.selectedAddress.id;
    //first delete the address
    deleteCustomer(customer_id).then((res) => {
      if (res.data.deleteCustomer) {
        deleteAddress(address_id).then((res) => {
          if (res.data.deleteAddress) {
            setSaving(false);
            props.handleClose();
            props.listUserCustomers();
            setOpenConfirmation(false);
            setInitialValues({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              address: "",
              second_address: "",
              city: "",
              state: "",
              postal_code: "",
              country: "US",
            });
            formik.resetForm({
              values: initial_values,
            });
            props.setError({
              open: true,
              message: `Address deleted successfully`,
              severity: "success",
            });
          } else {
            setSaving(false);
            props.setError({
              open: true,
              message: `Error deleting address`,
              severity: "error",
            });
          }
        });
      } else {
        setSaving(false);
        props.setError({
          open: true,
          message: `Error deleting customer`,
          severity: "error",
        });
      }
    });
    //then delete the costumer
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="addresses-modal"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {props.selectedAddress.id ? "Edit Address" : "Create Address"}
      </DialogTitle>
      <DialogContent style={{ overflowY: "scroll" }}>
        <Grid container spacing={2}>
          {/* First name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              autoFocus
              id="first_name"
              margin="none"
              label="First Name *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.first_name}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          {/* Last name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="last_name"
              margin="none"
              label="Last Name *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.last_name}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="email"
              type="email"
              margin="none"
              label="Confirmation Email *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          {/* Phone */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="phone"
              type="tel"
              margin="none"
              label="Phone *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.phone}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          {/* Address */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="address"
              margin="none"
              label="Address *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.address}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          {/* Address 2 */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="second_address"
              margin="none"
              label="Address 2 (Optional)"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.second_address}
            />
          </Grid>
          {/* City */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="city"
              margin="none"
              label="City *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          {/* State */}
          <Grid item xs={12} md={4}>
            <FormControl
              id="state_form_control"
              variant="outlined"
              size="small"
              margin="none"
              fullWidth
              error={formik.touched.state && Boolean(formik.errors.state)}
            >
              <InputLabel id="state_label">{ formik.values.country === 'CA' ? 'Province *' : 'State *' }</InputLabel>
              <Select
                labelId="state_label"
                label={ formik.values.country === 'CA' ? 'Province *' : 'State *' }
                id="state"
                name="state"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                {states.length == 0 ? (
                  <MenuItem disabled value={0}>
                    No options
                  </MenuItem>
                ) : null}
                {(formik.values.country === 'CA' ? provinces : states).map((state) => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.state && formik.errors.state}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* Postal Code */}
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="postal_code"
              type="text"
              inputProps={{ min: 1 }}
              size="small"
              margin="none"
              label="Postal Code *"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.postal_code}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }
              helperText={
                formik.touched.postal_code && formik.errors.postal_code
              }
            />
          </Grid>
          {/* Country */}
          <Grid item xs={12} md={4}>
            <FormControl
              id="country_form_control"
              variant="outlined"
              size="small"
              margin="none"
              fullWidth
              error={formik.touched.country && Boolean(formik.errors.country)}
            >
              <InputLabel id="country_label">Country *</InputLabel>
              <Select
                labelId="country_label"
                label="Country"
                id="country"
                name="country"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                value={formik.values.country}
                onChange={(event) => {
                  // Handle the country change
                  formik.handleChange(event);
                  // Reset the state value
                  formik.setFieldValue('state', '')
                  // Reset the postal_code value
                  formik.setFieldValue('postal_code', '')
                  // Set the value of canadaSelected
                  setCanadaSelected(event.target.value == 'CA');
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country.abbreviation} value={country.abbreviation}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {/**Buttons container*/}
        <Grid
          container
          spacing={2}
          style={{ marginTop: "10px" }}
          className={classes.ButtonsContainers}
        >
          <Grid item xs={12} sm={6}>
            {props.selectedAddress.id ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenConfirmation(true);
                }}
              >
                Delete Address
              </Button>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Button
              onClick={props.handleClose}
              variant="contained"
              fullWidth
              className={classes.Button}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={saving}
              className={classes.Button}
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <ConfirmationDialog
        open={openConfirmation}
        dialogTitle="Delete Address"
        dialogContentText="Are you sure you want to delete this address from this user?"
        handleClose={() => {
          setOpenConfirmation(false);
        }}
        confirmButtonClick={deleteSelectedAddress}
      />
    </Dialog>
  );
};

AddressesModal.propTypes = {
  user_id: PropTypes.string,
  client_id: PropTypes.string,
  listUserCustomers: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedAddress: PropTypes.object,
  setError: PropTypes.func,
};

export default AddressesModal;
