import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Button, Divider } from "@material-ui/core";
import {
  postDivisionCategory,
  getDivisionCategories,
  deleteDivisionCategory,
  editDivisionCategory,
} from "../../api/Clients";
import SnackbarMessage from "../SnackbarMessage";
import ConfirmationDialog from "../ConfirmationDialog";
import { Skeleton } from "@material-ui/lab";
import DivisionCategoryModal from "./DivisionCategoryModal";

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
}));

function DivisionCategories(client_id) {
  const classes = useStyles();
  const [error, setError] = useState({
    message: "",
    severity: "",
    open: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleAddCategory = (category) => {
    setSaving(true);
    if (selectedCategory.id) {
      editDivisionCategory(category, selectedCategory.id).then((res) => {
        if (res.data && res.data.updateDivisionCategory) {
          setError({
            message: "Division category updated.",
            severity: "success",
            open: true,
          });
          getDivisionCategories(client_id).then((res) => {
            client_id.setDivisionCategories(res);
          });
        } else {
          setError({
            message: "An error ocurred",
            severity: "error",
            open: true,
          });
        }
        setSaving(false);
        closeDCModal();
      });
    } else {
      postDivisionCategory(category, client_id).then((res) => {
        if (res.data && res.data.createDivisionCategory) {
          setError({
            message: "Division category added.",
            severity: "success",
            open: true,
          });
          getDivisionCategories(client_id).then((res) => {
            client_id.setDivisionCategories(res);
          });
        } else {
          setError({
            message: "An error ocurred",
            severity: "error",
            open: true,
          });
        }
        setSaving(false);
        closeDCModal();
      });
    }
  };

  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "",
    });
  };

  useEffect(() => {
    getDivisionCategories(client_id).then((res) => {
      client_id.setDivisionCategories(res);
    });
  }, [client_id.client_id]);

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const toggleDeleteModal = (category) => {
    setOpenDeleteModal(true);
    setSelectedCategory(category);
  };

  const closeDCModal = () => {
    setOpenModal(false);
    setSelectedCategory({});
  };

  const toggleModal = (category) => {
    setOpenModal(true);
    setSelectedCategory(category);
  };

  const handleDeleteCategory = () => {
    deleteDivisionCategory(selectedCategory.id).then((res) => {
      if (res.data.deleteDivisionCategory) {
        setError({
          message: "Division category deleted.",
          severity: "success",
          open: true,
        });
        closeDeleteModal();
        closeDCModal();
        getDivisionCategories(client_id).then((res) => {
          client_id.setDivisionCategories(res);
        });
      } else if (res.response.data.errors.length) {
        setError({
          message: "An error ocurred",
          severity: "error",
          open: true,
        });
      }
    });
  };

  return (
    <div>
      <Paper className={classes.paper}>
        {client_id.loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton height={41.6} width="50%" />
            </Grid>
            <Grid item xs={8}>
              <Skeleton height={40} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton height={36} width="50%" />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <Grid key={index} container style={{ padding: "15px" }}>
                      <Grid xs={11}>
                        <Skeleton height={24} />
                      </Grid>
                      <Grid xs={1}>
                        <Skeleton height={24} />
                      </Grid>
                      <Grid xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h4">Division Categories</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "1px", float: "right" }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Add
              </Button>
            </Grid>
            <Grid
              container
              className={classes.addressesContainer}
              style={{ maxHeight: "295px", overflowY: "scroll" }}
            >
              <Grid container style={{ padding: "15px" }}>
                <Grid xs={8}>
                  <Typography variant="caption" noWrap>
                    Name
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography variant="caption" noWrap>
                    Ordering Mode
                  </Typography>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              {client_id.division_categories.map((category) => {
                return (
                  <Grid key={category.id} container style={{ padding: "15px" }}>
                    <Grid xs={8}>
                      <Typography variant="body1" noWrap>
                        {category.Name}
                      </Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography variant="body1" noWrap>
                        {category.ordering_mode == "Default"
                          ? "Invoice"
                          : category.ordering_mode
                          ? category.ordering_mode
                          : "Client Default"}
                      </Typography>
                    </Grid>
                    <Grid xs={1}>
                      <Button
                        color="primary"
                        onClick={() => toggleModal(category)}
                      >
                        Edit &gt;
                      </Button>
                    </Grid>
                    <Grid xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        )}
      </Paper>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
      <ConfirmationDialog
        dialogTitle="Delete division category"
        dialogContentText="Category will be deleted, continue?"
        confirmButtonText="Delete"
        closeButtonText="Cancel"
        open={openDeleteModal}
        handleClose={closeDeleteModal}
        confirmButtonClick={handleDeleteCategory}
      />
      <DivisionCategoryModal
        open={openModal}
        handleClose={closeDCModal}
        save={handleAddCategory}
        saving={saving}
        selectedCategory={selectedCategory}
        toggleDeleteModal={toggleDeleteModal}
      />
    </div>
  );
}

// ClientContainer.propTypes = {
//   client: PropTypes.object,
// };

export default DivisionCategories;
