import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Divider,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { listUserCustomers, listClientCustomers } from "../../api/Customers";
import PropTypes from "prop-types";
import AddressesModal from "./AddressesModal";
import SnackbarMessage from "../SnackbarMessage";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    padding: "20px",
  },
  addressesContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "15px",
  },
}));

export default function UserAddresses({
  user_id,
  isAdmin,
  disableLabes,
  client_id,
  global,
  custom_addresses,
  changeCustomAddresses,
}) {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [openAddressesModal, setOpenAddressesModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [loading, setLoading] = useState(true);
  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  // List customers at the first load
  useEffect(() => {
    listCustomers();
  }, [client_id]);

  const listCustomers = () => {
    setLoading(true);
    // List costumers, global or user
    if (global) {
      listClientCustomers(client_id)
        .then((result) => {
          setCustomers(
            result.data && result.data.customers ? result.data.customers : []
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      listUserCustomers(user_id)
        .then((result) => {
          setCustomers(
            result.data && result.data.customers ? result.data.customers : []
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const closeAddressesModal = () => {
    setOpenAddressesModal(false);
    setSelectedAddress({});
  };
  return (
    <div>
      <Paper
        variant="outlined"
        className={classes.paper}
        style={{ height: "auto", maxHeight: 330, minHeight: 330 }}
      >
        {/**User points title*/}
        <Grid container>
          <Grid item sm={9} xs={12}>
            <Typography
              variant={global ? "h4" : "h6"}
              gutterBottom
              style={{ paddingLeft: "10px" }}
            >
              {global ? "Global Addresses" : "User Addresses"}
            </Typography>
          </Grid>
          {global ? (
            <Grid item sm={9} xs={12}>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={custom_addresses}
                        name="enabled_acumatica"
                        color="primary"
                        onChange={(event) => {
                          changeCustomAddresses(event);
                        }}
                      />
                    }
                    label="Allow users to add their own address"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid item sm={3} xs={12}>
            {isAdmin || !disableLabes ? (
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={() => {
                  setOpenAddressesModal(true);
                }}
              >
                Add
              </Button>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.addressesContainer}
          style={{ maxHeight: "295px", overflowY: "scroll" }}
        >
          {loading
            ? [1, 2, 3, 4].map((item, index) => (
                <Grid key={index} container style={{ padding: "15px" }}>
                  <Grid xs={11}>
                    <Skeleton height={24} />
                  </Grid>
                  <Grid xs={1}>
                    <Skeleton height={24} />
                  </Grid>
                  <Grid xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))
            : customers.map((customer) => (
                <Grid key={customer.id} container style={{ padding: "15px" }}>
                  <Grid xs={11}>
                    <Typography variant="body1" noWrap>
                      {customer.FirstName} {customer.LastName} (
                      {customer.address.Street + " "}
                      {customer.address.City + ", "}
                      {customer.address.State + " "}
                      {customer.address.Zip + " "}
                      {customer.address.Country + " "})
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedAddress(customer);
                        setOpenAddressesModal(true);
                      }}
                      disabled={disableLabes}
                    >
                      Edit &gt;
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
        </Grid>
      </Paper>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
      <AddressesModal
        client_id={client_id}
        user_id={user_id}
        listUserCustomers={listCustomers}
        open={openAddressesModal}
        handleClose={closeAddressesModal}
        selectedAddress={selectedAddress}
        setError={setError}
      />
    </div>
  );
}

UserAddresses.propTypes = {
  user_id: PropTypes.string,
  isAdmin: PropTypes.bool,
  disableLabes: PropTypes.bool,
  client_id: PropTypes.string,
  global: PropTypes.bool,
  custom_addresses: PropTypes.bool,
  changeCustomAddresses: PropTypes.func,
};
