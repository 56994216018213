import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * Create product_division_category_id
 * @param {int} id
 * @returns object
 */
 export const createProductDivisionCategory = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        createProductDivisionCategory(
          input: {
            data: {
              product: ${values.product}
              client: ${values.client}
              division_category: ${values.division_category}
              display_order: ${values.display_order}
            }
          }
        ) {
          productDivisionCategory {
            id
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Delete product_division_category_id
 * @param {int} id
 * @returns object
 */
export const deleteProductDivisionCategory = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation {
        deleteProductDivisionCategory(input: { where: { id: ${id} } }) {
          productDivisionCategory {
            id
          }
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * List all product_division_categories by product_id
 * @param {int} id 
 * @returns object
 */
export const getProductDivisionCategoriesByProduct = async (id, client_id = null) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where:{product: ${id}${(client_id ? ", client: "+client_id : "")}}`;
  axiosOptions.data = {
    query: `
      query {
        productDivisionCategories(sort: "division_category.Name", ${where}) {
          id
          division_category {
            id
            Name
          }
          display_order
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};